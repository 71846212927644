export const WomanCalories = 
[
    { weight: 0, calories: 49 },
    { weight: 5, calories: 98 },
    { weight: 10, calories: 147 },
    { weight: 15, calories: 196 },
    { weight: 20, calories: 245 },
    { weight: 25, calories: 294 },
    { weight: 30, calories: 343 },
    { weight: 35, calories: 392 },
    { weight: 40, calories: 441 },
    { weight: 45, calories: 490 },
    { weight: 50, calories: 539 },
    { weight: 55, calories: 588 },
    { weight: 60, calories: 637 },
    { weight: 65, calories: 686 },
    { weight: 70, calories: 735 },
    { weight: 75, calories: 784 },
    { weight: 80, calories: 833 },
    { weight: 85, calories: 882 },
    { weight: 90, calories: 931 },
    { weight: 95, calories: 980 },
    { weight: 100, calories: 1029 },
    { weight: 105, calories: 1078 },
    { weight: 110, calories: 1127 },
    { weight: 115, calories: 1176 },
    { weight: 120, calories: 1225 },
    { weight: 125, calories: 1274 },
    { weight: 130, calories: 1323 },
    { weight: 135, calories: 1372 },
    { weight: 140, calories: 1421 },
    { weight: 145, calories: 1470 },
    { weight: 150, calories: 1519 },
    { weight: 155, calories: 1568 },
    { weight: 160, calories: 1617 },
    { weight: 165, calories: 1666 },
    { weight: 170, calories: 1715 },
    { weight: 175, calories: 1764 },
    { weight: 180, calories: 1813 },
    { weight: 185, calories: 1862 },
    { weight: 190, calories: 1911 },
    { weight: 195, calories: 1960 },
    { weight: 200, calories: 2009 },
    { weight: 205, calories: 2058 },
    { weight: 210, calories: 2107 },
    { weight: 215, calories: 2156 },
    { weight: 220, calories: 2205 },
    { weight: 225, calories: 2254 },
    { weight: 230, calories: 2303 },
    { weight: 235, calories: 2352 },
    { weight: 240, calories: 2401 },
    { weight: 245, calories: 2450 },
    { weight: 250, calories: 2499 },
    { weight: 255, calories: 2548 },
    { weight: 260, calories: 2597 },
    { weight: 265, calories: 2646 },
    { weight: 270, calories: 2695 },
    { weight: 275, calories: 2744 },
    { weight: 280, calories: 2793 },
    { weight: 285, calories: 2842 },
    { weight: 290, calories: 2891 },
    { weight: 295, calories: 2940 },
    { weight: 300, calories: 2989 },
    { weight: 305, calories: 3038 },
    { weight: 310, calories: 3087 },
    { weight: 315, calories: 3136 },
    { weight: 320, calories: 3185 },
    { weight: 325, calories: 3234 },
    { weight: 330, calories: 3283 },
    { weight: 335, calories: 3332 },
    { weight: 340, calories: 3381 },
    { weight: 345, calories: 3430 },
    { weight: 350, calories: 3479 },
    { weight: 355, calories: 3528 },
    { weight: 360, calories: 3577 },
    { weight: 365, calories: 3626 },
    { weight: 370, calories: 3675 },
    { weight: 375, calories: 3724 },
    { weight: 380, calories: 3773 },
    { weight: 385, calories: 3822 },
    { weight: 390, calories: 3871 },
    { weight: 395, calories: 3920 },
    { weight: 400, calories: 3969 }
  ]


// [
//     {
//         weight: 125,
//         calories: 1227
//     },
//     {
//         weight: 130,
//         calories: 1276
//     },
//     {
//         weight: 135,
//         calories: 1325
//     },
//     {
//         weight: 140,
//         calories: 1375
//     },
//     {
//         weight: 145,
//         calories: 1424
//     },
//     {
//         weight: 150,
//         calories: 1473
//     },
//     {
//         weight: 155,
//         calories: 1522
//     },
//     {
//         weight: 160,
//         calories: 1571
//     },
//     {
//         weight: 165,
//         calories: 1620
//     },
//     {
//         weight: 170,
//         calories: 1669
//     },
//     {
//         weight: 175,
//         calories: 1718
//     },
//     {
//         weight: 180,
//         calories: 1767
//     },
//     {
//         weight: 185,
//         calories: 1816
//     },
//     {
//         weight: 190,
//         calories: 1865
//     },
//     {
//         weight: 195,
//         calories: 1915
//     },
//     {
//         weight: 200,
//         calories: 1964
//     },
//     {
//         weight: 205,
//         calories: 2013
//     },
//     {
//         weight: 210,
//         calories: 2062
//     },
//     {
//         weight: 215,
//         calories: 2111
//     },
//     {
//         weight: 220,
//         calories: 2160
//     },
//     {
//         weight: 225,
//         calories: 2209
//     },
//     {
//         weight: 230,
//         calories: 2258
//     },
//     {
//         weight: 235,
//         calories: 2307
//     },
//     {
//         weight: 240,
//         calories: 2356
//     },
//     {
//         weight: 245,
//         calories: 2405
//     },
//     {
//         weight: 250,
//         calories: 2455
//     }
// ]