import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import quizSlice from "./quizSlice/quizSlice";
import { FUNNEL_TYPE } from "../utils";

const RootReducer = combineReducers({
  quizNew: quizSlice,
});

const encryptor = encryptTransform({
  secretKey: `ketoslim-storage`,
  onError: (error) => {
    // Handle the error.
    console.log(error);
  },
});

const persistConfig = {
  key: "ketoslim",
  storage,
  whitelist: ["quizNew"],
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);
// const tr = true;
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  devTools: FUNNEL_TYPE === "quiz",
});

export default store;

export const Persistor = persistStore(store);
