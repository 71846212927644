import { useDispatch, useSelector } from "react-redux";
import AppContainer from "../../../componant/private_components/AppContainer";

import './animation.css'
import DeclineWarning from "./DeclineWarning";
import PaymentForm from "./PaymentForm";
import { useEffect, useState } from "react";
import { cardFailed, planDatas, selectUserData } from "../../../redux/quizSlice/quizSlice";
import axios from "axios";
import { API_PATH } from "../../../utils";
import { useNavigate } from "react-router";
import accessRoute from "../../../route-control/path/public-path";

const ProcessingPayment = () => {
  const state = useSelector(selectUserData);
  const planData = state?.planData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [loading])

  useEffect(() => {
    if (!state.email) {
      return navigate(accessRoute.Email.path);
    }

    if (!planData?.session_id || !state.cardData) {
      return navigate(accessRoute.ResultCheckout.path); //plan-ready
    }
    else if (planData?.txnId) {
      return navigate(accessRoute.ThankYou.path); //Thank you
    }

    console.log(state.cardData);
    if (state.cardData.failed > 2) {
      setLoading(false);
      setError({ blocked: true });
      return;
    }
    handlePaymentSubmit(state.cardData);
  }, []);

  const handlePaymentSubmit = async (values) => {
    setLoading(true);
    setError(false);
    const paymentUrl = `${API_PATH}/payment/nmi`;
    const formData = new FormData();
    for (let key in values) {
      let value = String(values[key]);
      formData.append(key, value);
    }
    formData.append("session_id", planData.session_id);

    try {
      const { data } = await axios.post(paymentUrl, formData);
      if (data.status !== 1) throw Error("Payment failed");
      else {
        dispatch(
          planDatas({
            ...planData,
            txnId: data.txnId,
            orderID: data.orderID,
          })
        );
        // navigate to ketoboost
        navigate(accessRoute.KetoBoost.path);
      }
    } catch (e) {
      console.log(e);
      setError(true);
      dispatch(cardFailed());
    } finally {
      setLoading(false)
    }
  }


  return (
    <AppContainer className={'bg-white pb-32'} showFooter={false}>
      {/* <div className="wrapper">
        <LazyLoadImage
          src="https://dev.myketoslim.com/images/keto-logo.png"
          alt="Keto Slim"
          className="w-[145px] my-4 mx-auto"
        />
      </div> */}

      {loading ?
        <div className="wrapper py-10">
          <div className="text-center text-black">
            <h2 className="font-bold text-xl">Processing Payment</h2>
            <div className="dot-flashing mx-auto my-16"></div>
            <p className="text-sm">Charge will show as <span className="font-bold text-sm">MYKETOSLIM.COM</span></p>
          </div>
        </div> :
        <div className="">
          {error &&
            <div className="wrapper py-5">
              <h2 className="font-bold sm:text-xl text-center text-black mb-5">Your Card Has Been Declined</h2>
              <DeclineWarning error={error} />
            </div>
          }

          <div className="bg-teal-500/10 py-5">
            <div className="wrapper">
              <PaymentForm handlePaymentSubmit={handlePaymentSubmit} setError={setError} formValues={state.cardData} hideNameField={true} isInitialValid={true} />
            </div>
          </div>
        </div>
      }
    </AppContainer>
  );
}

export default ProcessingPayment;