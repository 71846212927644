import { FUNNEL_TYPE } from "../../../utils";

const accessRoute = {
  Homepage: {
    path: "/",
  },
  Login: {
    path: "/login",
  },
  Email: {
    path: `/${FUNNEL_TYPE}/step-email`,
  },
  About_Us: {
    path: `/about-us`,
  },
  Privacy_policy: {
    path: `/privacy-policy`,
  },
  contact_us: {
    path: `/contact-us`,
  },
  terms_condition: {
    path: `/terms&condition`,
  },
  StartStep: {
    path: `/${FUNNEL_TYPE}`,
  },
  StartStepMen: {
    path: `/quiz-men`,
  },
  StartStepWomen: {
    path: `/quiz-women`,
  },
  StartStep40: {
    path: `/quiz-40`,
  },
  YouFit: {
    path: `/${FUNNEL_TYPE}/step-you-are-fit`,
  },
  // YouRock: {
  //   path: `/${FUNNEL_TYPE}/step-you-rock`,
  // },
  TargetReach: {
    path: `/${FUNNEL_TYPE}/step-reach-target`,
  },
  WeightLossProgram29: {
    path: `/${FUNNEL_TYPE}/step-weight-loss-program`,
  },
  TypicleInfo: {
    path: `/${FUNNEL_TYPE}/step-typical-day-info`,
  },
  Result: {
    path: `/${FUNNEL_TYPE}/result`,
  },
  Checkout: {
    path: `/${FUNNEL_TYPE}/checkout`,
  },
  ResultNew: {
    path: `/${FUNNEL_TYPE}/result-new`,
  },
  ResultNew2: {
    path: `/${FUNNEL_TYPE}/result-new-2`,
  },
  Checkout2: {
    path: `/${FUNNEL_TYPE}/checkout2`,
  },
  CheckoutNew: {
    path: `/${FUNNEL_TYPE}/checkout-new`,
  },
  ResultCheckout: {
    path: `/${FUNNEL_TYPE}/plan-ready`,
  },
  ProcessingPayment: {
    path: `/${FUNNEL_TYPE}/processing`,
  },
  KetoBoost: {
    path: `/${FUNNEL_TYPE}/ketoboost`,
  },
  Premium: {
    path: `/${FUNNEL_TYPE}/premium`,
  },
  Coaching: {
    path: `/${FUNNEL_TYPE}/coaching`,
  },
  Shipping: {
    path: `/${FUNNEL_TYPE}/shipping`,
  },
  ThankYou: {
    path: `/${FUNNEL_TYPE}/thankyou`,
  },
  CampaignResult: {
    path: `/campaign/result`,
  },
  CampaignPlanReady: {
    path: `/campaign/plan-ready`,
  },
  CampaignCheckout: {
    path: `/campaign/checkout`,
  },
  CampaignKetoBoost: {
    path: `/campaign/ketoboost`,
  },
  CampaignShipping: {
    path: `/campaign/shipping`,
  },
  CampaignThankYou: {
    path: `/campaign/thankyou`,
  },
  Chargebee: {
    path: `/${FUNNEL_TYPE}/chargebee`,
  },
  DataProcessing34: {
    path: `/${FUNNEL_TYPE}/step-processing-data`,
  },
  StepMessages: {
    path: `/${FUNNEL_TYPE}/step-messages`,
  },
  Workout: {
    path: `/${FUNNEL_TYPE}/workout`,
  },

  // quiz5
  Step1Familiarity: {
    path: `/${FUNNEL_TYPE}/step1-familiarity`,
  },
  Step2Info: {
    path: `/${FUNNEL_TYPE}/step2-info`,
  },
  Step3Time: {
    path: `/${FUNNEL_TYPE}/step3-time`,
  },
  Step4Meat: {
    path: `/${FUNNEL_TYPE}/step4-meat`,
  },
  Step5Products: {
    path: `/${FUNNEL_TYPE}/step5-products`,
  },
  Step6Activity: {
    path: `/${FUNNEL_TYPE}/step6-activity`,
  },
  Step7Willingness: {
    path: `/${FUNNEL_TYPE}/step7-willingness`,
  },
  Step8Medical: {
    path: `/${FUNNEL_TYPE}/step8-medical`,
  },
  Step9Measurements: {
    path: `/${FUNNEL_TYPE}/step9-measurements`,
  },

  // quiz10
  StartAgeOver: `start-age-over`,

  ObGoalOver: `ob-goal-over`,

  ActualBodyType: `actual-body-type`,

  IntroKetoslim: "intro-ketoslim",

  TargetBodyType: `target-body-type`,

  TargetZonesOver: `target-zones-over`,

  MealsPerDay: `meals-per-day`,

  CarbFoodFrequency: `how-often-carb-dense-foods`,

  CurrentHabits: `current-habits`,

  DietaryPattern: `dietary-pattern`,

  SleepTimeInfo: `sleep-time-info`,

  ImproveSleepList: `improve-sleep`,

  DailyWaterInfo: `water-intake-info`,

  LifeStyle: `life-style`,

  ChildrenInfo: `children-info`,

  StayingCommittedInfo: "staying-committed-info",

  SleepCardInfo: `sleep-is-crucial`,

  BodyType: "your-body-type",

  LastTimeFineBody: "last-time-content-with-body-weight",

  CommitmentMadeTasty: "commitment-made-tasty",

  EventComing: "event-coming",

  TransformYourGoals: "transform-your-goals",

  CurrentHealthInfo: "rate-your-current-health",

  TypicalDay: "your-typical-day",

  FruitVegetableIntake: "your-fruit-and-vegetable-intake",

  PlantFoodsInfo: "plant-foods-info",

  HungerLevels: "your-hunger-levels",

  NoStarvation: "no-starvation-required",

  YourEnergy: "your-energy",

  EnergySlump: "energy-slump",

  RateHabits: "hard-to-stick-to-habits",

  EmotionComfort: "food-provides-emotional-comfort",

  OldHabits: "fall-back-to-old-habits",

  MultiTaskingWhileEating: "multitasking-while-eating",

  ClearMyPlate: "clear-my-plate",

  AdjustingPlanLoading: "adjusting-plan-based-on-your-answers",

  ExpectedResultGraph: "expected-date-to-reach-target-weight",
  ExpectedResultGraphStep2: "better-expected-date-to-reach-target-weight",

  NearlyThereInfo: "nearly-there-to-your-ideal-body",

  Height: "height",
  CurrentWeight: "current-weight",
  WeightGoal: "weight-goal",

  EventDate: "event-date",

  SubmitEmail: "submit-email",

  SimpleJourneyFeel: "ketoslim-journey-feeling",
  WantWithKetoSlim: "with-ketoslim-I-want",
  HappyWeight: "happy-weight",
  ThinkOfReachingHappyWeight: "think-of-reaching-happy-weight",
  DriveOfLosingWeight: "biggest-drive-of-losing-weight",
  ChangeYourSkin: "change-your-skin",
  BackIssues: "back-issues",
  StomachDiscomfort: "stomach-discomfort",
  KetoInfo: "keto-info",
  WeightLossPrograms: "weight-loss-programs",
  WeightLossMethods: "weight-loss-methods",
  KetoIsNumberOne: "keto-is-america-number-1-diet",
  HealthConditions: "health-conditions",
  AnyEatingDisorder: "diagnosed-with-an-eating-disorder",
  AnyMedications: "taking-any-medications",
  PregnantOrLaboring: "pregnant-or-breastfeeding",
  HormonalChanges: "hormonal-changes",
  YouAreFit: "ketoslim-was-made-for-you",
  AlcoholConsumption: "alcohol-comsumption",
  EatingHabits: "eating-habits",
  SnackCravingsTime: "snack-cravings-time",
  WhySnackCravings: "why-snack-cravings",

  LoadingStorySequence: "loading-story-sequence",
  JoinCommunity: "join-community",

  WeightLossMotivation: "weight-loss-motivation",
  MotivationToReactTarget: "your-motivation-to-react-your-target-weight",
  YourUncertainity: "what-is-making-you-uncertain",
  PeopleAbondonInfo: "why-people-abandon-their-weight-loss-journey",
  YourFeelingOfHittingTarget: "how-do-you-feel-about-hitting-your-goal",
  WeightLossUnlocked: "weight-loss-boost-unlocked",
  YouRock: "your-updated-goal",
  KitchenAppliances: "kitchen-appliances",
  Vegetable: "vegetables-to-include",
  Products: "products-to-include",
  Meat: "fish-and-meat-to-include",
  HowQuicklyWeightLoss: "how-quickly-lose-the-weight",
  GreatJob: "great-job",
  YoyoEffect: "yo-yo-effect",
  ResultsLoadingScreen: "loading-your-results",
  LoadingMessages: "setting-up",
  PlanReady: "plan-ready",
};

export default accessRoute;
