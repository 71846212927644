import { InformationCircleIcon } from "@heroicons/react/24/solid";

const DeclineWarning = ({ error }) => {

  return (
    <div className="text-red-500 border-[1px] border-red-500 p-3 bg-red-400/10 my-2 sm:max-w-[400px] mx-auto">
      {error?.blocked ?
        <div className="text-center text-xs">
          We’re sorry, this card has been declined 3 times and cannot be tried again. Please use another card. Your bank may send a text message or email to see if you recognize the charge, you must approve the charge first and then the transaction will be allowed to go through.
        </div> :
        <>
          <h3 className="text-xs font-semibold">
            <InformationCircleIcon className="w-5 text-red-500 inline-block transform translate-y-[-2px] mr-1" />
            Sorry, your credit card has been declined.
          </h3>

          <p className="my-4 text-xs">
            You may receive a text message or email from your bank, please approve this charge and try again.
          </p>

          <p className="my-4 text-xs">
            This charge will appear as MYKETOSLIM.COM
          </p>

          <p className="mt-4 text-xs">
            If you still get a decline, you may need to try a different credit card.
          </p>
        </>}
    </div>
  );
}

export default DeclineWarning;