import { FUNNEL_TYPE } from "../../../utils";
const accessRoute = {
  Product: {
    path: `/${FUNNEL_TYPE}/step-product`,
  },
  Vegetable: {
    path: `/${FUNNEL_TYPE}/step-vegetable`,
  },
  Meat: {
    path: `/${FUNNEL_TYPE}/step-meat`,
  },
  Goal2: {
    path: `/${FUNNEL_TYPE}/weight-loss-goal`,
  },
  Gender3: {
    path: `/${FUNNEL_TYPE}/step-gender`,
  },
  Identify4: {
    path: `/${FUNNEL_TYPE}/step-identify`,
  },
  AgeStep5: {
    path: `/${FUNNEL_TYPE}/step-your-age`,
  },
  Measurement6: {
    path: `/${FUNNEL_TYPE}/step-measurement`,
  },
  Reach7: {
    path: `/${FUNNEL_TYPE}/step-reach-weight`,
  },
  BodyType8: {
    path: `/${FUNNEL_TYPE}/step-body-type`,
  },
  IdealType9: {
    path: `/${FUNNEL_TYPE}/step-ideal-time`,
  },
  Event10: {
    path: `/${FUNNEL_TYPE}/step-event`,
  },
  EventDate11: {
    path: `/${FUNNEL_TYPE}/step-event-date`,
  },
  TypicalDay12: {
    path: `/${FUNNEL_TYPE}/step-typical-day`,
  },
  BackIssue13: {
    path: `/${FUNNEL_TYPE}/step-back-issue`,
  },
  Stomach14: {
    path: `/${FUNNEL_TYPE}/step-stomach-discomfort`,
  },
  TenTodo15: {
    path: `/${FUNNEL_TYPE}/step-tend-to-do`,
  },
  Emotional16: {
    path: `/${FUNNEL_TYPE}/step-emotional-comfort`,
  },
  OldHabit17: {
    path: `/${FUNNEL_TYPE}/step-old-habit`,
  },
  Multitasking18: {
    path: `/${FUNNEL_TYPE}/step-multitasking`,
  },
  ClearPlate19: {
    path: `/${FUNNEL_TYPE}/step-clear-plate`,
  },
  WorkOut20: {
    path: `/${FUNNEL_TYPE}/step-workout`,
  },
  Alcohol21: {
    path: `/${FUNNEL_TYPE}/step-consume-alchohol`,
  },
  Feeling22: {
    path: `/${FUNNEL_TYPE}/step-how-feeling`,
  },
  SleepInfo23: {
    path: `/${FUNNEL_TYPE}/step-sleep-info`,
  },
  Water24: {
    path: `/${FUNNEL_TYPE}/step-water-info`,
  },

  MealPrepare25: {
    path: `/${FUNNEL_TYPE}/step-meal-prepare`,
  },
  KitchenInfo26: {
    path: `/${FUNNEL_TYPE}/step-kitchen-info`,
  },
};

export default accessRoute;
