import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import "./graph.css";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/quizSlice/quizSlice";
import clsx from "clsx";

const Graph = ({ targetMonthShort }) => {
  const container = useRef();
  const state = useSelector(selectUserData);
  const isFemale = state?.gender === "female";

  const weightUnit = {
    LbFt: "lb",
    KgCm: "kg",
  };

  const currentUnit = state?.measurements.unitType;

  useGSAP(
    () => {
      const path1 = document.getElementById("path1");
      const path2 = document.getElementById("path2");
      const tl = gsap.timeline();

      tl.from(path1, {
        duration: 1,
        strokeDasharray: 100,
        strokeDashoffset: 100,
        delay: 1,
        ease: "sine.out",
      });
      tl.from(path2, {
        duration: 2,
        strokeDasharray: 100,
        strokeDashoffset: 100,
      });

      gsap.fromTo(
        "#dot2, #graph-label",
        { scale: 0, opacity: 0 },
        { scale: 1, opacity: 1, duration: 0.5, delay: 2 }
      );
    },
    { scope: container }
  );

  return (
    <div ref={container} className="weight-predict-chart__chart">
      <div className="weight-predict-chart__grid-item weight-predict-chart__grid-item-1"></div>
      <div className="weight-predict-chart__grid-item weight-predict-chart__grid-item-2"></div>
      <div className="weight-predict-chart__grid-item weight-predict-chart__grid-item-3"></div>
      <div
        className={clsx(
          isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]",
          "font-medium weight-predict-chart__top-value weight-predict-chart__text-value"
        )}
      >
        {" "}
        {state?.measurements?.weight} {weightUnit[currentUnit]}{" "}
      </div>
      <div
        className={clsx(
          isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]",
          "font-medium weight-predict-chart__bottom-left-value weight-predict-chart__text-value"
        )}
      >
        {" "}
        NOW{" "}
      </div>
      <div
        className={clsx(
          isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]",
          "font-medium weight-predict-chart__bottom-right-value weight-predict-chart__text-value"
        )}
      >
        {" "}
        {targetMonthShort}
      </div>
      <div className="weight-predict-chart__svg-wrapper">
        <svg
          preserveAspectRatio="none"
          className="weight-predict-chart__svg-chart"
        >
          <defs>
            <linearGradient id="left-gradient" x1="0%" x2="0" y1="0%" y2="100%">
              <stop offset="0%" stopColor="rgba(255, 100, 88, 0.3)"></stop>
              <stop offset="100%" stopColor="rgba(123, 201, 71, 0)"></stop>
            </linearGradient>
          </defs>
          <defs>
            <linearGradient
              id="right-gradient"
              x1="0%"
              x2="0"
              y1="0%"
              y2="100%"
              style={{ opacity: 0 }}
            >
              <stop offset="0%" stopColor="rgba(123, 201, 71, 0.2)"></stop>
              <stop offset="100%" stopColor="rgba(123, 201, 71, 0)"></stop>
            </linearGradient>
          </defs>
          <path
            id="shaddow1"
            d="M11,128C38.16,128,65.32,128,92.48,128C119.64,128,146.8,128,173.96,128C197.24,128,220.52,128,243.8,128L243.8,87.04C220.52,85.44,197.24,83.84,173.96,77.44C146.8,69.973,119.64,44.373,92.48,35.84C65.32,27.307,38.16,25.173,11,23.04Z"
            fill="url(#left-gradient)"
            style={{ opacity: 1 }}
          ></path>
          <path
            id="shaddow2"
            d="M243.8,128L399,128L399,87.04L243.8,87.04Z"
            fill="url(#right-gradient)"
            style={{ opacity: 1 }}
          ></path>
          <path
            id="path1"
            d="M11,23.04C38.16,25.173,65.32,27.307,92.48,35.84C119.64,44.373,146.8,69.973,173.96,77.44C197.24,83.84,220.52,85.44,243.8,87.04"
            stroke="#ff6458"
            strokeWidth="3"
            fill="none"
            strokeDasharray="245.06008911132812 245.06008911132812"
            strokeDashoffset="0"
          ></path>
          <path
            id="path2"
            d="M243.8,87.04L399,87.04"
            stroke="#56A97A"
            strokeWidth="3"
            fill="none"
            strokeDasharray="155.1999969482422 155.1999969482422"
            strokeDashoffset="0"
          ></path>
          <circle cx="11" cy="23.040000000000028" r="4" fill="#ff6458"></circle>
          <circle
            id="dot2"
            cx="243.79999999999998"
            cy="87.04"
            r="7"
            fill="#56A97A"
            stroke="#fff"
            strokeWidth="2"
            // style="transform-origin: 243.8px 87.04px; transform: scale(1, 1);"
          ></circle>
        </svg>
      </div>
      <div
        className="weight-predict-chart__maintain-weight right-1 text-xs sm:text-sm sm:right-2"
        style={{ color: "rgb(86, 169, 122)" }}
      >
        {" "}
        Maintain weight{" "}
      </div>

      <div
        id="graph-label"
        databounds="tooltip"
        className="weight-predict-chart__goal-label"
        // style="top: 29.8525px; left: 202px; opacity: 1; transform: scale(1) translateY(0px); transition-delay: 2301ms;"
        style={{ top: "25px", left: "230px", opacity: 0 }}
      >
        <div className="weight-predict-chart__goal-label-chevron"></div>
        <div className="weight-predict-chart__goal-label-text">
          {" "}
          Goal <br /> {state?.measurements?.targetWeight}{" "}
          {weightUnit[currentUnit]}{" "}
        </div>
      </div>
    </div>
  );
};

export default Graph;
