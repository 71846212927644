import { lazy } from "react";
import accessRoutes from "../path/private-path";
const Goal2 = lazy(() => import("../../pages/quiz/Goal2"));
const Gender3 = lazy(() => import("../../pages/quiz/Gender3"));
const Identify4 = lazy(() => import("../../pages/quiz/Identify4"));
const AgeStep5 = lazy(() => import("../../pages/quiz/AgeStep5"));
const Measurement6 = lazy(() => import("../../pages/quiz/Measurement6"));
const Reach7 = lazy(() => import("../../pages/quiz/Reach7"));
const BodyType8 = lazy(() => import("../../pages/quiz/BodyType8"));
const IdealTime9 = lazy(() => import("../../pages/quiz/IdealTime9"));
// const LongTermResult10 = lazy(() =>
//   import("../../pages/quiz/LongTermResult10")
// );
const EventComing11 = lazy(() => import("../../pages/quiz/EventComing11"));
const EventDate12 = lazy(() => import("../../pages/quiz/EventDate12"));
const TypicalDay13 = lazy(() => import("../../pages/quiz/TypicalDay13"));
const BackIssue14 = lazy(() => import("../../pages/quiz/BackIssue14"));
const Stomach15 = lazy(() => import("../../pages/quiz/Stomach15"));
const TendToDo16 = lazy(() => import("../../pages/quiz/TendToDo16"));
const EmotionalComform17 = lazy(() =>
  import("../../pages/quiz/EmotionalComform17")
);
const OldHabit18 = lazy(() => import("../../pages/quiz/OldHabit18"));
const MultiTasking19 = lazy(() => import("../../pages/quiz/MultiTasking19"));
const ClearPlate20 = lazy(() => import("../../pages/quiz/ClearPlate20"));

const Workout22 = lazy(() => import("../../pages/quiz/Workout22"));
const ConsumeAlchohol23 = lazy(() =>
  import("../../pages/quiz/ConsumeAlchohol23")
);
const FeelDay24 = lazy(() => import("../../pages/quiz/FeelDay24"));
const Sleep25 = lazy(() => import("../../pages/quiz/Sleep25"));
const Water26 = lazy(() => import("../../pages/quiz/Water26"));

const MealPrepare30 = lazy(() => import("../../pages/quiz/MealPrepare30"));
const KitchenAppliance31 = lazy(() =>
  import("../../pages/quiz/KitchenAppliance31")
);
const Meat = lazy(() => import("../../pages/quiz/Meat"));
const Product = lazy(() => import("../../pages/quiz/Product"));
const Vegetable = lazy(() => import("../../pages/quiz/Vegetable"));

const AccessControls = {
  ...accessRoutes,
};

export default function PrivateRoute() {
  return [
    {
      path: AccessControls.Vegetable.path,
      name: "Vegetable",
      element: <Vegetable />,
    },
    {
      path: AccessControls.Product.path,
      name: "Product",
      element: <Product />,
    },
    {
      path: AccessControls.Meat.path,
      name: "Meat",
      element: <Meat />,
    },
    {
      path: AccessControls.Goal2.path,
      name: "Goal2",
      element: <Goal2 />,
    },
    {
      path: AccessControls.Gender3.path,
      name: "Gender3",
      element: <Gender3 />,
    },
    {
      path: AccessControls.Identify4.path,
      name: "Identify4",
      element: <Identify4 />,
    },
    {
      path: AccessControls.AgeStep5.path,
      name: "Meat",
      element: <AgeStep5 />,
    },

    {
      path: AccessControls.Measurement6.path,
      name: "Meat",
      element: <Measurement6 />,
    },
    {
      path: AccessControls.Reach7.path,
      name: "Reach7",
      element: <Reach7 />,
    },
    {
      path: AccessControls.BodyType8.path,
      name: "BodyType8",
      element: <BodyType8 />,
    },
    {
      path: AccessControls.IdealType9.path,
      name: "Meat",
      element: <IdealTime9 />,
    },
    {
      path: AccessControls.Event10.path,
      name: "Reach7",
      element: <EventComing11 />,
    },
    {
      path: AccessControls.EventDate11.path,
      name: "BodyType8",
      element: <EventDate12 />,
    },

    {
      path: AccessControls.TypicalDay12.path,
      name: "TypicalDay12",
      element: <TypicalDay13 />,
    },
    {
      path: AccessControls.BackIssue13.path,
      name: "Meat",
      element: <BackIssue14 />,
    },
    {
      path: AccessControls.Stomach14.path,
      name: "Reach7",
      element: <Stomach15 />,
    },
    {
      path: AccessControls.TenTodo15.path,
      name: "BodyType8",
      element: <TendToDo16 />,
    },
    {
      path: AccessControls.Emotional16.path,
      name: "Reach7",
      element: <EmotionalComform17 />,
    },
    {
      path: AccessControls.OldHabit17.path,
      name: "BodyType8",
      element: <OldHabit18 />,
    },

    {
      path: AccessControls.Multitasking18.path,
      name: "BodyType8",
      element: <MultiTasking19 />,
    },
    {
      path: AccessControls.ClearPlate19.path,
      name: "Meat",
      element: <ClearPlate20 />,
    },
    {
      path: AccessControls.WorkOut20.path,
      name: "Reach7",
      element: <Workout22 />,
    },
    {
      path: AccessControls.Alcohol21.path,
      name: "BodyType8",
      element: <ConsumeAlchohol23 />,
    },
    {
      path: AccessControls.Feeling22.path,
      name: "BodyType8",
      element: <FeelDay24 />,
    },
    {
      path: AccessControls.SleepInfo23.path,
      name: "SleepInfo23",
      element: <Sleep25 />,
    },
    {
      path: AccessControls.Water24.path,
      name: "Reach7",
      element: <Water26 />,
    },
    {
      path: AccessControls.MealPrepare25.path,
      name: "BodyType8",
      element: <MealPrepare30 />,
    },
    {
      path: AccessControls.KitchenInfo26.path,
      name: "BodyType8",
      element: <KitchenAppliance31 />,
    },
  ];
}
