import React from "react";
import Kgcm from "../Forms/Inputs/Kgcm";
import Lbft from "../Forms/Inputs/Lbft";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, unit } from "../../redux/quizSlice/quizSlice";
import Header from "./Header";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { AccessControl } from "../../route-control/public-route";

export const Step9 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(selectUserData);

  const goBack = () => {
    setTimeout(() => {
      navigate(AccessControl.Step8Medical.path);
    }, 250);
  };
  return (
    <div className="py-6 px-4 max-w-sm mx-auto">
      <Header goBack={goBack} step={9} />
      <h2 className="text-start font-semibold text-gray-800 dark:text-white font-Montserrat mt-8 smx:mt-5 text-3xl desktop1600:text-2xl smx:text-[18px] smx:leading-normal">
        Measurements
      </h2>

      <div className="w-full flex justify-center mt-5 smx:mt-3">
        <div
          role="tablist"
          className="flex flex-row bg-gray-100 p-1 overflow-hidden rounded-lg desktop:w-1/2 mobile:w-full"
        >
          <button
            type="button"
            role="tab"
            aria-selected={state?.measurements?.unitType === "LbFt"}
            onClick={() => {
              dispatch(unit("LbFt"));
            }}
            className={clsx(
              "w-1/2 h-full",
              state?.measurements?.unitType === "LbFt"
                ? "bg-teal-100 shadow-md"
                : "bg-gray-100",
              "rounded-lg p-1 font-Montserrat text-lg smx:text-[18px] font-semibold text-gray-600 transition-all"
            )}
          >
            Imperial
          </button>

          <button
            type="button"
            role="tab"
            aria-selected={state?.measurements?.unitType === "KgCm"}
            onClick={() => {
              dispatch(unit("KgCm"));
            }}
            className={clsx(
              "w-1/2 h-full",
              state?.measurements?.unitType === "KgCm"
                ? "bg-teal-100 shadow-md"
                : "bg-gray-100",
              "rounded-lg p-1 font-Montserrat text-lg smx:text-[18px] font-semibold text-gray-600 transition-all"
            )}
          >
            Metric
          </button>
        </div>
      </div>

      {state?.measurements?.unitType === "LbFt" ? <Lbft /> : <Kgcm />}
    </div>
  );
};

export default Step9;
