import React from "react";
import { useLocation } from "react-router-dom";
import { FUNNEL_TYPE } from "../../utils";

const Bars = ({ step }) => {
  const barsWidth = 100 / 28;
  const bars = barsWidth * step;
  return (
    <div
      className="bg-red-500 h-full transition-all"
      style={{ width: `${bars}%` }}
    />
  );
};

const ProgressBar = () => {
  const location = useLocation();
  const funnelType = FUNNEL_TYPE;
  const progessBar = () => {
    if (location.pathname === `/${funnelType}/weight-loss-goal`) {
      return <Bars step={1} />;
    } else if (location.pathname === `/${funnelType}/step-gender`) {
      return <Bars step={2} />;
    } else if (location.pathname === `/${funnelType}/step-identify`) {
      return <Bars step={3} />;
    } else if (location.pathname === `/${funnelType}/step-your-age`) {
      return <Bars step={4} />;
    } else if (location.pathname === `/${funnelType}/step-measurement`) {
      return <Bars step={5} />;
    } else if (location.pathname === `/${funnelType}/step-reach-weight`) {
      return <Bars step={6} />;
    } else if (location.pathname === `/${funnelType}/step-body-type`) {
      return <Bars step={7} />;
    } else if (location.pathname === `/${funnelType}/step-ideal-time`) {
      return <Bars step={8} />;
    } else if (location.pathname === `/${funnelType}/step-event`) {
      return <Bars step={9} />;
    } else if (location.pathname === `/${funnelType}/step-event-date`) {
      return <Bars step={10} />;
    } else if (location.pathname === `/${funnelType}/step-typical-day`) {
      return <Bars step={11} />;
    } else if (location.pathname === `/${funnelType}/step-back-issue`) {
      return <Bars step={12} />;
    } else if (location.pathname === `/${funnelType}/step-stomach-discomfort`) {
      return <Bars step={13} />;
    } else if (location.pathname === `/${funnelType}/step-tend-to-do`) {
      return <Bars step={14} />;
    } else if (location.pathname === `/${funnelType}/step-emotional-comfort`) {
      return <Bars step={15} />;
    } else if (location.pathname === `/${funnelType}/step-old-habit`) {
      return <Bars step={16} />;
    } else if (location.pathname === `/${funnelType}/step-multitasking`) {
      return <Bars step={17} />;
    } else if (location.pathname === `/${funnelType}/step-clear-plate`) {
      return <Bars step={18} />;
    } else if (location.pathname === `/${funnelType}/step-workout`) {
      return <Bars step={19} />;
    } else if (location.pathname === `/${funnelType}/step-consume-alchohol`) {
      return <Bars step={20} />;
    } else if (location.pathname === `/${funnelType}/step-how-feeling`) {
      return <Bars step={21} />;
    } else if (location.pathname === `/${funnelType}/step-sleep-info`) {
      return <Bars step={22} />;
    } else if (location.pathname === `/${funnelType}/step-water-info`) {
      return <Bars step={23} />;
    } else if (location.pathname === `/${funnelType}/step-meal-prepare`) {
      return <Bars step={24} />;
    } else if (location.pathname === `/${funnelType}/step-kitchen-info`) {
      return <Bars step={25} />;
    } else if (location.pathname === `/${funnelType}/step-vegetable`) {
      return <Bars step={26} />;
    } else if (location.pathname === `/${funnelType}/step-product`) {
      return <Bars step={27} />;
    } else if (location.pathname === `/${funnelType}/step-meat`) {
      return <Bars step={28} />;
    }
  };
  return (
    <>
      <div className="w-full h-2 bg-slate-300 mt-2 grid rounded overflow-hidden">
        {progessBar()}
      </div>
    </>
  );
};

export default ProgressBar;
