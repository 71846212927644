import { LazyLoadImage } from "react-lazy-load-image-component";
import AppContainer from "../../../componant/private_components/AppContainer";

import lock from './assets/checkout/lock.png'
import infoCircle from './assets/checkout/info-circle.png'
import redCircle from './assets/checkout/red-circle.png'
import cardicon from './assets/checkout/card.png'
import calenderChecked from './assets/checkout/calender-checked.png'
import logo1 from './assets/logo1.png'
import logo2 from './assets/logo2.png'
import logo3 from './assets/logo3.png'
import shadow from './assets/checkout/shadow.png'
import cardLogo from '../../../assets/images/visa-mc-credit-card-logos.png';
import checkedIcon from './assets/checked.svg'

import './style.css';
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import PaymentForm from "../ResultCheckout/PaymentForm";


const NewCheckout = () => {

  return (
    <div className="bg-white text-black">
      <div className="wrapper">
        <LazyLoadImage
          src="https://dev.myketoslim.com/images/keto-logo.png"
          alt="Keto Slim"
          className="w-[240px] p-5 mx-auto"
        />
      </div>

      <section className="section-bg1 relative overflow-hidden">
        <div className="section-wrapper">

          <div className="text-center py-5">
            <h1 className="text-5xl font-semibold">Get your personal Keto Diet</h1>
            <p className="text-xs font-medium mt-2">The most powerful Keto diet! Over 270 000 plans ordered.</p>
          </div>

          <div className="text-red-400 flex gap-2 font-bold text-sm px-4 mt-4">
            <ArrowLeftIcon className="w-4" />
            <span>Back to plans</span>
          </div>

          <div className="grid lg:grid-cols-2 p-4 gap-6 justify-center">

            <div>
              <div className="bg-white p-4 rounded-lg border-[1px] border-gray-100 shadow-sm flex flex-col gap-2">
                <div className="flex justify-between items-center border-b-[1px] border-gray-200 pb-2">
                  <div className="flex items-center gap-3">
                    <img className="w-[16px] h-[16px]" src={redCircle} alt="" />
                    <img className="w-[25px] h-[18px]" src={cardicon} alt="" />
                    <p className="text-black font-semibold">Credit Cards</p>
                  </div>
                  <img src={cardLogo} alt="card logo" className="w-16" />
                </div>

                <div className="grid grid-cols-2 gap-1 border-b-[1px] border-gray-200 pb-4 my-2">
                  <p className="text-xs font-light text-gray-500">6 months Keto diet</p>
                  <span className="justify-self-end text-xs font-light">$199.99</span>

                  <p className="text-xs font-light text-gray-500">Discount -75%</p>
                  <span className="justify-self-end text-xs text-red-500">-$90</span>

                  <p className="text-xs font-bold">Total</p>
                  <span className="justify-self-end text-xs font-bold">$29.99</span>
                </div>

                <div className="py-2">
                  <PaymentForm />
                </div>

              </div>
              <div className="my-6">
                <p className="text-center text-black text-sm">
                  GUARANTEED <span className="font-semibold text-sm">SAFE</span> CHECKOUT
                </p>
                <img src={cardLogo} alt="card logo" className="w-16 mx-auto my-2" />
                <div className="w-full flex justify-center items-center">
                  <img src="/images/result-page/SSL.png" alt="ssl" className="w-24" />
                  <img
                    src={"/images/result-page/Norton.png"}
                    alt="norton"
                    className="w-24"
                  />
                  <img
                    src="/images/result-page/McAfee.png"
                    alt="McAfee"
                    className="w-24"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex gap-1">
                <div className="flex flex-col justify-center"><img className="transform translate-y-[-1px]" src={lock} alt="lock" /></div>
                <p className="text-[11px] font-extralight">
                  Follow the instructions on the screen to
                  <br />
                  complete your purchase securely.
                </p>
              </div>
              <div className="flex gap-1">
                <div className="flex flex-col justify-center"><img className="transform translate-y-[-1px]" src={infoCircle} alt="lock" /></div>
                <p className="text-[11px] font-extralight">
                  Plan automatically renews until cancelled.
                </p>
              </div>
              <div className="flex gap-1">
                <div className="flex flex-col justify-center"><img className="transform translate-y-[-1px]" src={calenderChecked} alt="lock" /></div>
                <p className="text-[11px] font-extralight">
                  $29.99 billed every 6 months. Cancel anytime.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-wrapper px-4 py-5">
        <div className="flex justify-center gap-2 lg:justify-between items-center my-6 flex-wrap">
          <img src={logo2} alt="logo" />
          <img src={logo1} alt="logo" />
          <img src={logo3} alt="logo" />
        </div>
      </section>

      <section className="bg-[#FFE3E1]">
        <div className="section-wrapper px-4 py-12 text-center">
          <h2 className="text-4xl font-semibold">You're in good hands</h2>
          <p className="mt-2 text-sm">
            Keto Slim has helped more than <span className="font-semibold underline text-teal-400">300,000</span> people successfully lose
            <br />
            weight in the Keto way.
          </p>
        </div>
        <div className="flex px-4 gap-5 justify-center">
          <div className="max-w-[414px] overflow-hidden relative text-white text-center">
            <img className="transform scale-[1.1]" src="../images/result-page/brie.jpg" alt="" />
            <div style={{ backgroundImage: `url(${shadow})` }} className="w-full h-[130px] absolute left-0 bottom-0 flex flex-col justify-center">
              <h2 className="font-bold">-75 <span>lbs</span></h2>
              <span className="block mt-2 text-sm font-medium">Brie K.</span>
            </div>
          </div>
          <div className="max-w-[414px] overflow-hidden relative text-white text-center">
            <img className="transform scale-[1.1]" src="../images/result-page/brie.jpg" alt="" />
            <div style={{ backgroundImage: `url(${shadow})` }} className="w-full h-[130px] absolute left-0 bottom-0 flex flex-col justify-center">
              <h2 className="font-bold">-75 <span>lbs</span></h2>
              <span className="block mt-2 text-sm font-medium">Brie K.</span>
            </div>
          </div>
          <div className="max-w-[414px] overflow-hidden relative text-white text-center">
            <img className="transform scale-[1.1]" src="../images/result-page/brie.jpg" alt="" />
            <div style={{ backgroundImage: `url(${shadow})` }} className="w-full h-[130px] absolute left-0 bottom-0 flex flex-col justify-center">
              <h2 className="font-bold">-75 <span>lbs</span></h2>
              <span className="block mt-2 text-sm font-medium">Brie K.</span>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="section-wrapper py-10">
          <h2 className="mb-6 text-4xl font-semibold text-center">
            Keto your way to a healthier life
            <br />
            one step at a time
          </h2>
          <p className="text-sm text-center font-extralight">
            Weight loss and healthy habits don’t happen overnight.
            <br />
            Keto Slim helps you get there in 3 steps.
          </p>

          <div className="flex gap-4">

            <div className="relative flex flex-col gap-4 max-w-md p-4">
              <div className="w-[5px] h-full bg-gray-100 absolute -left-10 top-0 flex flex-col justify-center">
                <div className="h-1/2 w-full bg-teal-500 flex flex-col justify-center">
                  <b className="w-[70px] h-[70px] bg-gray-50 rounded-2xl shadow-xl transform -translate-x-[50%] flex justify-center items-center text-3xl">
                    1
                  </b>
                </div>
              </div>

              <h4 className="text-3xl text-teal-500">Well done - you are on step ahead of your goals</h4>
              
              <b className="text-sm">The quiz was the first step you had to take.</b>

              <p className="font-extralight">Now, your honest answers help us determine you preferences and create a personalized Keto plan that:</p>

              <ul className="pt-2 flex flex-col gap-1">
                <li className="flex gap-2 ">
                  <img className="w-[24px]" src={checkedIcon} alt="checked" />
                  <b className="text-sm">Helps you lose weight</b>
                </li>
                <li className="flex gap-2 ">
                  <img className="w-[24px]" src={checkedIcon} alt="checked" />
                  <b className="text-sm">Increases your energy level</b>
                </li>
                <li className="flex gap-2 ">
                  <img className="w-[24px]" src={checkedIcon} alt="checked" />
                  <b className="text-sm">Gives you mental clarity</b>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </section>


      <footer className="p-8 text-gray-200 text-center text-xs sm:text-sm">
        Copyright ® 2023 KetoSlim Diet. All Rights Reserved.
      </footer>
    </div>
  );
}

const youGet = [
  {
    title: "Personalized meal plan",
    description: "Breakfast, lunch, dinner, and even those in-between snacks – it's all laid out for you in your personalized plan. No need to bother with tracking calories or tallying up carbs, because every meal is already designed for on-the-spot fat burning. It's as if you've got your very own personal nutritionist who's also a master in the art of healthy weight loss!"
  },
  {
    title: "Convenient grocery lists",
    description: `With our simple, fast-to-fix recipes, the "what's for dinner?" question will be a thing of the past. Each recipe comes with a list of wallet-friendly ingredients available at your neighborhood supermarket. Just open your plan, print out the list for the week, and you're ready to go with only what you need. It's meal planning made easy, just for you!`
  },
  {
    title: "24/7 support",
    description: `With round-the-clock support, our team is here for you every step of your keto journey. Whether it's a question about your diet, needing some tips, or just a boost of motivation to keep you on track, we've got you covered. Day or night, our dedicated team works tirelessly to provide you with the guidance and encouragement you need. Remember, you're never alone in this journey; we're your support group and cheerleaders, here to help you every step of the way.`
  },
]

export default NewCheckout;