import { FaArrowLeftLong } from "react-icons/fa6";
import ProgressBar from "./ProgressBar";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/quizSlice/quizSlice";
import clsx from "clsx";
import {
  AccessControl,
  quiz10Routes,
  createPath,
} from "../../route-control/public-route";
import { useLocation, useNavigate } from "react-router";

export default function Header() {
  const state = useSelector(selectUserData);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const gender = state?.gender;
  const isFemale = gender === "female";
  const step = quiz10Routes.findIndex((route) => route.path === pathname);

  const noHeaderPages = [
    createPath(AccessControl.LoadingMessages),
    createPath(AccessControl.LoadingStorySequence),
    createPath(AccessControl.JoinCommunity),
    createPath(AccessControl.CommitmentMadeTasty),
    createPath(AccessControl.EnergySlump),
    createPath(AccessControl.ExpectedResultGraph),
    createPath(AccessControl.ExpectedResultGraphStep2),
    createPath(AccessControl.GreatJob),
    createPath(AccessControl.IntroKetoslim),
    createPath(AccessControl.KetoInfo),
    createPath(AccessControl.KetoIsNumberOne),
    createPath(AccessControl.NearlyThereInfo),
    createPath(AccessControl.NoStarvation),
    createPath(AccessControl.PeopleAbondonInfo),
    createPath(AccessControl.PlantFoodsInfo),
    createPath(AccessControl.SleepCardInfo),
    createPath(AccessControl.StayingCommittedInfo),
    createPath(AccessControl.TransformYourGoals),
    createPath(AccessControl.WeightLossUnlocked),
    createPath(AccessControl.YouAreFit),
    createPath(AccessControl.YouRock),
    createPath(AccessControl.YoyoEffect),
    createPath(AccessControl.PlanReady),
    createPath(AccessControl.ResultsLoadingScreen),
    createPath(AccessControl.AdjustingPlanLoading),
    createPath(AccessControl.SubmitEmail),
  ];
  const showHeader = !noHeaderPages.find((item) => item === pathname);

  const isHormonalChanges =
    pathname === createPath(AccessControl.HormonalChanges);

  const goBack = () => {
    setTimeout(() => {
      if (step > 0) {
        if (isHormonalChanges && !isFemale) {
          navigate(quiz10Routes[step - 2].path);
        } else {
          navigate(quiz10Routes[step - 1].path);
        }
      } else {
        navigate(AccessControl.StartStep.path);
      }
    }, 250);
  };
  return (
    showHeader && (
      <header className={clsx("flex flex-col px-6 transition-all")}>
        <nav className="relative flex justify-center w-full">
          <button
            onClick={goBack}
            title="Go back"
            className={clsx(
              "absolute outline-none left-0 inset-y-0 pr-4 flex items-center rounded-full group hover:text-opacity-80 transition-all",
              gender === "female" ? "text-[#f75950]" : "text-[#36bc9f]"
            )}
          >
            <FaArrowLeftLong
              size={24}
              className="p-0 m-0 group-hover:-translate-x-0.5 transition-all"
            />
          </button>

          <img
            src="https://dev.myketoslim.com/images/keto-logo.png"
            alt="Keto Slim"
            className="w-[102px]"
          />
        </nav>

        <ProgressBar className="mt-5" step={step} />
      </header>
    )
  );
}
