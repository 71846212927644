import clsx from "clsx";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, setStep } from "../../redux/quizSlice/quizSlice";
import { quiz10Routes } from "../../route-control/public-route";

export default function ProgressBar({ className, step = 0 }) {
  const dispatch = useDispatch();
  const state = useSelector(selectUserData);
  const gender = state?.gender;
  const steps = {};

  quiz10Routes.forEach((route, index) => {
    steps[route.name] = { step: index };
  });

  useEffect(() => {
    if (state?.step !== step) {
      dispatch(setStep(step));
    }
  }, [step, state?.step, dispatch]);

  const totalSteps = Object.keys(steps).length;

  const Bar1Steps = 26;
  const Bar2Steps = 16;

  // const width = state?.step && state?.step * (100 / Bar1Steps);

  let widthBar1 = 0;

  if (state?.step <= 10) {
    widthBar1 = (state?.step / 10) * 75;
  } else {
    const remainingSteps = Bar1Steps - 10;
    const remainingPercent = 100 - 75;
    widthBar1 = 75 + (state?.step - 10) * (remainingPercent / remainingSteps);
  }
  const widthBar2 =
    state?.step && (state?.step - Bar1Steps) * (100 / Bar2Steps);
  const widthBar3 =
    state?.step &&
    (state?.step - Bar1Steps - Bar2Steps) *
      (100 / (totalSteps - Bar1Steps - Bar2Steps));

  return (
    <div className="flex items-center gap-1.5">
      <div
        className={clsx(
          className,
          "w-full h-1 bg-slate-300 rounded-full relative overflow-hidden",
          step !== 0 ? "opacity-100" : "opacity-0"
        )}
      >
        <span
          className={clsx(
            gender === "female" ? "bg-[#ff9791]" : "bg-[#36bc9f]",
            "h-full rounded-full absolute transition-all duration-700 delay-100"
          )}
          style={{ width: `${widthBar1}%` }}
        />
      </div>
      {state?.step >= Bar1Steps && (
        <div
          className={clsx(
            className,
            "w-full h-1 bg-slate-300 rounded-full relative overflow-hidden",
            step !== 0 ? "opacity-100" : "opacity-0"
          )}
        >
          <span
            className={clsx(
              gender === "female" ? "bg-[#ff9791]" : "bg-[#36bc9f]",
              "h-full rounded-full absolute transition-all duration-700 delay-100"
            )}
            style={{ width: `${widthBar2}%` }}
          />
        </div>
      )}
      {state?.step >= Bar1Steps && (
        <div
          className={clsx(
            className,
            "w-full h-1 bg-slate-300 rounded-full relative overflow-hidden",
            step !== 0 ? "opacity-100" : "opacity-0"
          )}
        >
          <span
            className={clsx(
              gender === "female" ? "bg-[#ff9791]" : "bg-[#36bc9f]",
              "h-full rounded-full absolute transition-all duration-700 delay-100"
            )}
            style={{ width: `${widthBar3}%` }}
          />
        </div>
      )}
    </div>
  );
}
