import { lazy } from "react";
import accessRoute from "../path/public-path";
import ResultCheckout from "../../pages/quiz/ResultCheckout";
import NewResult from "../../pages/quiz/newPages/NewResult";
import NewResult2 from "../../pages/quiz/newPages/Design2";
import NewCheckout from "../../pages/quiz/newPages/NewCheckout";
import ProcessingPayment from "../../pages/quiz/ResultCheckout/ProcessingPayment";

import Step1Familiarity from "../../pages/quiz5/Step1Familiarity";
import Step2Info from "../../pages/quiz5/Step2Info";
import Step3Time from "../../pages/quiz5/Step3Time";
import Step4Meat from "../../pages/quiz5/Step4Meat";
import Step5Products from "../../pages/quiz5/Step5Products";
import Step6Activity from "../../pages/quiz5/Step6Activity";
import Step7Willingness from "../../pages/quiz5/Step7Willingness";
import Step8Medical from "../../pages/quiz5/Step8Medical";
import Step9Measurements from "../../pages/quiz5/Step9Measurements";

// import ActualBodyType from "../../pages/quiz10/ActualBodyType";
// import TargetBodyType from "../../pages/quiz10/TargetBodyType";
import { FUNNEL_TYPE } from "../../utils";
import QuizLayout from "../../pages/quiz10/QuizLayout";

// quiz10
// import StartAgeOver from "../../pages/quiz10/StartAgeOver";
// import MealsPerDay from "../../pages/quiz10/MealsPerDay";
// import ObGoalOver from "../../pages/quiz10/ObGoalOver";
// import CarbFoodFrequency from "../../pages/quiz10/CarbFoodFrequency";
// import CurrentHabits from "../../pages/quiz10/CurrentHabits";
// import DietaryPattern from "../../pages/quiz10/DietaryPattern";
// import SleepTimeInfo from "../../pages/quiz10/SleepTimeInfo";
// import TargetZonesOver from "../../pages/quiz10/TargetZonesOver";
// import ImproveSleepList from "../../pages/quiz10/ImproveSleepList";
// import DailyWaterInfo from "../../pages/quiz10/DailyWaterInfo";
// import LifeStyle from "../../pages/quiz10/LifeStyle";
// import ChildrenInfo from "../../pages/quiz10/ChildrenInfo";
// import SleepCardInfo from "../../pages/quiz10/SleepCardInfo";
// import BodyType from "../../pages/quiz10/BodyType";
// import LastTimeFineBody from "../../pages/quiz10/LastTimeFineBody";
// import EventComing from "../../pages/quiz10/EventComing";
// import TransformYourGoals from "../../pages/quiz10/TransformYourGoals";
// import CurrentHealthInfo from "../../pages/quiz10/CurrentHealthInfo";
// import TypicalDay from "../../pages/quiz10/TypicalDay";
// import FruitVegetableIntake from "../../pages/quiz10/FruitVegetableIntake";
// import PlantFoodsInfo from "../../pages/quiz10/PlantFoodsInfo";
// import HungerLevels from "../../pages/quiz10/HungerLevels";
// import NoStarvation from "../../pages/quiz10/NoStarvation";
// import YourEnergy from "../../pages/quiz10/YourEnergy";
// import EnergySlump from "../../pages/quiz10/EnergySlump";
// import RateHabits from "../../pages/quiz10/RateHabits";
// import MultiTaskingWhileEating from "../../pages/quiz10/MultiTaskingWhileEating";
// import UserHeight from "../../pages/quiz10/UserHeight";
// import CurrentWeight from "../../pages/quiz10/CurrentWeight";
// import WeightGoal from "../../pages/quiz10/WeightGoal";
// import EmotionComfort from "../../pages/quiz10/EmotionComfort";
// import OldHabits from "../../pages/quiz10/OldHabits";
// import ClearMyPlate from "../../pages/quiz10/ClearMyPlate";
// import AdjustingPlanLoading from "../../pages/quiz10/AdjustingPlanLoading";
// import ExpectedResultGraph from "../../pages/quiz10/ExpectedResultGraph";
// import NearlyThereInfo from "../../pages/quiz10/NearlyThereInfo";
// import WeightLossMotivation from "../../pages/quiz10/WeightLossMotivation";
// import MotivationToReactTarget from "../../pages/quiz10/MotivationToReactTarget";
// import YourUncertainity from "../../pages/quiz10/YourUncertainity";
// import PeopleAbondonInfo from "../../pages/quiz10/PeopleAbondonInfo";
// import YourFeelingOfHittingTarget from "../../pages/quiz10/YourFeelingOfHittingTarget";
// import WeightLossUnlocked from "../../pages/quiz10/WeightLossUnlocked";
// import YouRock from "../../pages/quiz10/YouRock";
// import KitchenAppliances from "../../pages/quiz10/KitchenAppliances";
// import Vegetable from "../../pages/quiz10/Vegetable";
// import Products from "../../pages/quiz10/Products";
// import Meat from "../../pages/quiz10/Meat";
// import HowQuicklyWeightLoss from "../../pages/quiz10/HowQuicklyWeightLoss";
// import GreatJob from "../../pages/quiz10/GreatJob";
// import YoyoEffect from "../../pages/quiz10/YoyoEffect";
// import ResultsLoadingScreen from "../../pages/quiz10/ResultsLoadingScreen";
// import LoadingMessages from "../../pages/quiz10/LoadingMessages";
// import SimpleJourneyFeel from "../../pages/quiz10/SimpleJourneyFeel";
// import WantWithKetoSlim from "../../pages/quiz10/WantWithKetoSlim";
// import HappyWeight from "../../pages/quiz10/HappyWeight";
// import ThinkOfReachingHappyWeight from "../../pages/quiz10/ThinkOfReachingHappyWeight";
// import DriveOfLosingWeight from "../../pages/quiz10/DriveOfLosingWeight";
// import ChangeYourSkin from "../../pages/quiz10/ChangeYourSkin";
// import BackIssues from "../../pages/quiz10/BackIssues";
// import StomachDiscomfort from "../../pages/quiz10/StomachDiscomfort";
// import KetoInfo from "../../pages/quiz10/KetoInfo";
// import WeightLossPrograms from "../../pages/quiz10/WeightLossPrograms";
// import WeightLossMethods from "../../pages/quiz10/WeightLossMethods";
// import KetoIsNumberOne from "../../pages/quiz10/KetoIsNumberOne";
// import HealthConditions from "../../pages/quiz10/HealthConditions";
// import AnyEatingDisorder from "../../pages/quiz10/AnyEatingDisorder";
// import AnyMedications from "../../pages/quiz10/AnyMedications";
// import PregnantOrLaboring from "../../pages/quiz10/PregnantOrLaboring";
// import HormonalChanges from "../../pages/quiz10/HormonalChanges";
// import YouAreFit from "../../pages/quiz10/YouAreFit";
// import AlcoholConsumption from "../../pages/quiz10/AlcoholConsumption";
// import EatingHabits from "../../pages/quiz10/EatingHabits";
// import SnackCravingsTime from "../../pages/quiz10/SnackCravingsTime";
// import WhySnackCravings from "../../pages/quiz10/WhySnackCravings";
// import IntroKetoslim from "../../pages/quiz10/IntroKetoslim";
// import StayingCommittedInfo from "../../pages/quiz10/StayingCommittedInfo";
// import CommitmentMadeTasty from "../../pages/quiz10/CommitmentMadeTasty";
// import EventDate from "../../pages/quiz10/EventDate";
// import SubmitEmail from "../../pages/quiz10/SubmitEmail";
// import ExpectedResultGraphStep2 from "../../pages/quiz10/ExpectedResultGraphStep2";
// import LoadingStorySequence from "../../pages/quiz10/LoadingStorySequence";
// import JoinCommunity from "../../pages/quiz10/JoinCommunity";

const StartAgeOver = lazy(() => import("../../pages/quiz10/StartAgeOver"));
const MealsPerDay = lazy(() => import("../../pages/quiz10/MealsPerDay"));
const ObGoalOver = lazy(() => import("../../pages/quiz10/ObGoalOver"));
const CarbFoodFrequency = lazy(() =>
  import("../../pages/quiz10/CarbFoodFrequency")
);
const CurrentHabits = lazy(() => import("../../pages/quiz10/CurrentHabits"));
const DietaryPattern = lazy(() => import("../../pages/quiz10/DietaryPattern"));
const SleepTimeInfo = lazy(() => import("../../pages/quiz10/SleepTimeInfo"));
const TargetZonesOver = lazy(() =>
  import("../../pages/quiz10/TargetZonesOver")
);
const ImproveSleepList = lazy(() =>
  import("../../pages/quiz10/ImproveSleepList")
);
const DailyWaterInfo = lazy(() => import("../../pages/quiz10/DailyWaterInfo"));
const LifeStyle = lazy(() => import("../../pages/quiz10/LifeStyle"));
const ChildrenInfo = lazy(() => import("../../pages/quiz10/ChildrenInfo"));
const SleepCardInfo = lazy(() => import("../../pages/quiz10/SleepCardInfo"));
const BodyType = lazy(() => import("../../pages/quiz10/BodyType"));
const LastTimeFineBody = lazy(() =>
  import("../../pages/quiz10/LastTimeFineBody")
);
const EventComing = lazy(() => import("../../pages/quiz10/EventComing"));
const TransformYourGoals = lazy(() =>
  import("../../pages/quiz10/TransformYourGoals")
);
const CurrentHealthInfo = lazy(() =>
  import("../../pages/quiz10/CurrentHealthInfo")
);
const TypicalDay = lazy(() => import("../../pages/quiz10/TypicalDay"));
const FruitVegetableIntake = lazy(() =>
  import("../../pages/quiz10/FruitVegetableIntake")
);
const PlantFoodsInfo = lazy(() => import("../../pages/quiz10/PlantFoodsInfo"));
const HungerLevels = lazy(() => import("../../pages/quiz10/HungerLevels"));
const NoStarvation = lazy(() => import("../../pages/quiz10/NoStarvation"));
const YourEnergy = lazy(() => import("../../pages/quiz10/YourEnergy"));
const EnergySlump = lazy(() => import("../../pages/quiz10/EnergySlump"));
const RateHabits = lazy(() => import("../../pages/quiz10/RateHabits"));
const MultiTaskingWhileEating = lazy(() =>
  import("../../pages/quiz10/MultiTaskingWhileEating")
);
const UserHeight = lazy(() => import("../../pages/quiz10/UserHeight"));
const CurrentWeight = lazy(() => import("../../pages/quiz10/CurrentWeight"));
const WeightGoal = lazy(() => import("../../pages/quiz10/WeightGoal"));
const SubmitEmail = lazy(() => import("../../pages/quiz10/SubmitEmail"));

const EmotionComfort = lazy(() => import("../../pages/quiz10/EmotionComfort"));
const OldHabits = lazy(() => import("../../pages/quiz10/OldHabits"));
const ClearMyPlate = lazy(() => import("../../pages/quiz10/ClearMyPlate"));
const AdjustingPlanLoading = lazy(() =>
  import("../../pages/quiz10/AdjustingPlanLoading")
);
const ExpectedResultGraph = lazy(() =>
  import("../../pages/quiz10/ExpectedResultGraph")
);
const ExpectedResultGraphStep2 = lazy(() =>
  import("../../pages/quiz10/ExpectedResultGraphStep2")
);
const NearlyThereInfo = lazy(() =>
  import("../../pages/quiz10/NearlyThereInfo")
);
const EventDate = lazy(() => import("../../pages/quiz10/EventDate"));

const WeightLossMotivation = lazy(() =>
  import("../../pages/quiz10/WeightLossMotivation")
);
const MotivationToReactTarget = lazy(() =>
  import("../../pages/quiz10/MotivationToReactTarget")
);
const YourUncertainity = lazy(() =>
  import("../../pages/quiz10/YourUncertainity")
);
const PeopleAbondonInfo = lazy(() =>
  import("../../pages/quiz10/PeopleAbondonInfo")
);
const YourFeelingOfHittingTarget = lazy(() =>
  import("../../pages/quiz10/YourFeelingOfHittingTarget")
);
const WeightLossUnlocked = lazy(() =>
  import("../../pages/quiz10/WeightLossUnlocked")
);
const YouRock = lazy(() => import("../../pages/quiz10/YouRock"));
const KitchenAppliances = lazy(() =>
  import("../../pages/quiz10/KitchenAppliances")
);
const Vegetable = lazy(() => import("../../pages/quiz10/Vegetable"));
const Products = lazy(() => import("../../pages/quiz10/Products"));
const Meat = lazy(() => import("../../pages/quiz10/Meat"));
const HowQuicklyWeightLoss = lazy(() =>
  import("../../pages/quiz10/HowQuicklyWeightLoss")
);
const GreatJob = lazy(() => import("../../pages/quiz10/GreatJob"));
const YoyoEffect = lazy(() => import("../../pages/quiz10/YoyoEffect"));
const ResultsLoadingScreen = lazy(() =>
  import("../../pages/quiz10/ResultsLoadingScreen")
);
const LoadingMessages = lazy(() =>
  import("../../pages/quiz10/LoadingMessages")
);
const SimpleJourneyFeel = lazy(() =>
  import("../../pages/quiz10/SimpleJourneyFeel")
);
const WantWithKetoSlim = lazy(() =>
  import("../../pages/quiz10/WantWithKetoSlim")
);
const HappyWeight = lazy(() => import("../../pages/quiz10/HappyWeight"));
const ThinkOfReachingHappyWeight = lazy(() =>
  import("../../pages/quiz10/ThinkOfReachingHappyWeight")
);
const DriveOfLosingWeight = lazy(() =>
  import("../../pages/quiz10/DriveOfLosingWeight")
);
const ChangeYourSkin = lazy(() => import("../../pages/quiz10/ChangeYourSkin"));
const BackIssues = lazy(() => import("../../pages/quiz10/BackIssues"));
const StomachDiscomfort = lazy(() =>
  import("../../pages/quiz10/StomachDiscomfort")
);
const KetoInfo = lazy(() => import("../../pages/quiz10/KetoInfo"));
const WeightLossPrograms = lazy(() =>
  import("../../pages/quiz10/WeightLossPrograms")
);
const WeightLossMethods = lazy(() =>
  import("../../pages/quiz10/WeightLossMethods")
);
const KetoIsNumberOne = lazy(() =>
  import("../../pages/quiz10/KetoIsNumberOne")
);
const HealthConditions = lazy(() =>
  import("../../pages/quiz10/HealthConditions")
);

const AnyEatingDisorder = lazy(() =>
  import("../../pages/quiz10/AnyEatingDisorder")
);
const AnyMedications = lazy(() => import("../../pages/quiz10/AnyMedications"));
const PregnantOrLaboring = lazy(() =>
  import("../../pages/quiz10/PregnantOrLaboring")
);
const HormonalChanges = lazy(() =>
  import("../../pages/quiz10/HormonalChanges")
);
const YouAreFit = lazy(() => import("../../pages/quiz10/YouAreFit"));
const AlcoholConsumption = lazy(() =>
  import("../../pages/quiz10/AlcoholConsumption")
);
const EatingHabits = lazy(() => import("../../pages/quiz10/EatingHabits"));
const SnackCravingsTime = lazy(() =>
  import("../../pages/quiz10/SnackCravingsTime")
);
const WhySnackCravings = lazy(() =>
  import("../../pages/quiz10/WhySnackCravings")
);
const IntroKetoslim = lazy(() => import("../../pages/quiz10/IntroKetoslim"));
const StayingCommittedInfo = lazy(() =>
  import("../../pages/quiz10/StayingCommittedInfo")
);
const CommitmentMadeTasty = lazy(() =>
  import("../../pages/quiz10/CommitmentMadeTasty")
);
const LoadingStorySequence = lazy(() =>
  import("../../pages/quiz10/LoadingStorySequence")
);
const JoinCommunity = lazy(() => import("../../pages/quiz10/JoinCommunity"));

// // quiz10

const Email = lazy(() => import("../../pages/quiz/Email"));
const PremiumUpsell = lazy(() =>
  import("../../pages/quiz/premiumUpsell/PremiumUpsell")
);
const HomePage = lazy(() => import("../../pages/common_pages/Homepage"));
const Login = lazy(() => import("../../pages/common_pages/Login"));
const ThankYou = lazy(() => import("../../pages/quiz/ThankYou"));
const AboutUs = lazy(() => import("../../pages/common_pages/about_us"));
const PrivacyPolicy = lazy(() =>
  import("../../pages/common_pages/privacy_policy")
);
const Contact = lazy(() => import("../../pages/common_pages/contact"));

const Workout = lazy(() => import("../../pages/quiz/Workout"));

const Terms = lazy(() => import("../../pages/common_pages/Terms"));
const LandingPage = lazy(() => import("../../pages/quiz/LandingPage"));

const LandingPageMen = lazy(() => import("../../pages/quiz/LandingPageMen"));
const LandingPageWomen = lazy(() =>
  import("../../pages/quiz/LandingPageWomen")
);
const LandingPage40 = lazy(() => import("../../pages/quiz/LandingPage40"));

const LongTermResult = lazy(() => import("../../pages/quiz/LongTermResult10"));
const Checkout = lazy(() => import("../../pages/quiz/Chekout"));
const Results = lazy(() => import("../../pages/quiz/Result"));
const DataProcessing34 = lazy(() =>
  import("../../pages/quiz/DataProcessing34")
);
const StepMessages = lazy(() => import("../../pages/quiz/StepMessages"));
const Chargebee = lazy(() => import("../../pages/quiz/Chargebee"));
const YouRock27 = lazy(() => import("../../pages/quiz/YouRock27"));
const HelpPeople28 = lazy(() => import("../../pages/quiz/HelpPeople28"));
const WeightLossProgram29 = lazy(() =>
  import("../../pages/quiz/WeightLossProgram29")
);
const KetoBoostPage = lazy(() => import("../../componant/ketoboost"));
const CoachingPage = lazy(() => import("../../pages/quiz/Coaching"));
const Shipping = lazy(() => import("../../componant/ketoboost/Shipping"));
const YouFit21 = lazy(() => import("../../pages/quiz/YouFit21"));

export const AccessControl = {
  ...accessRoute,
};

export const createPath = (name) => `/${FUNNEL_TYPE}/${name}`;

export const quiz10Routes = [
  {
    path: createPath(AccessControl.StartAgeOver),
    name: AccessControl.StartAgeOver,
    element: <StartAgeOver />,
  },
  {
    path: createPath(AccessControl.ObGoalOver),
    name: AccessControl.ObGoalOver,
    element: <ObGoalOver />,
  },
  {
    path: createPath(AccessControl.MealsPerDay),
    name: AccessControl.MealsPerDay,
    element: <MealsPerDay />,
  },
  {
    path: createPath(AccessControl.CarbFoodFrequency),
    name: AccessControl.CarbFoodFrequency,
    element: <CarbFoodFrequency />,
  },
  {
    path: createPath(AccessControl.CurrentHabits),
    name: AccessControl.CurrentHabits,
    element: <CurrentHabits />,
  },
  {
    path: createPath(AccessControl.DietaryPattern),
    name: AccessControl.DietaryPattern,
    element: <DietaryPattern />,
  },
  {
    path: createPath(AccessControl.IntroKetoslim),
    name: AccessControl.IntroKetoslim,
    element: <IntroKetoslim />,
  },
  {
    path: createPath(AccessControl.TargetZonesOver),
    name: AccessControl.TargetZonesOver,
    element: <TargetZonesOver />,
  },

  {
    path: createPath(AccessControl.SleepTimeInfo),
    name: AccessControl.SleepTimeInfo,
    element: <SleepTimeInfo />,
  },
  {
    path: createPath(AccessControl.ImproveSleepList),
    name: AccessControl.ImproveSleepList,
    element: <ImproveSleepList />,
  },
  {
    path: createPath(AccessControl.SleepCardInfo),
    name: AccessControl.SleepCardInfo,
    element: <SleepCardInfo />,
  },
  {
    path: createPath(AccessControl.DailyWaterInfo),
    name: AccessControl.DailyWaterInfo,
    element: <DailyWaterInfo />,
  },
  {
    path: createPath(AccessControl.LifeStyle),
    name: AccessControl.LifeStyle,
    element: <LifeStyle />,
  },
  {
    path: createPath(AccessControl.ChildrenInfo),
    name: AccessControl.ChildrenInfo,
    element: <ChildrenInfo />,
  },
  {
    path: createPath(AccessControl.StayingCommittedInfo),
    name: AccessControl.StayingCommittedInfo,
    element: <StayingCommittedInfo />,
  },
  {
    path: createPath(AccessControl.BodyType),
    name: AccessControl.BodyType,
    element: <BodyType />,
  },
  {
    path: createPath(AccessControl.LastTimeFineBody),
    name: AccessControl.LastTimeFineBody,
    element: <LastTimeFineBody />,
  },
  {
    path: createPath(AccessControl.CommitmentMadeTasty),
    name: AccessControl.CommitmentMadeTasty,
    element: <CommitmentMadeTasty />,
  },

  {
    path: createPath(AccessControl.Height),
    name: AccessControl.Height,
    element: <UserHeight />,
  },
  {
    path: createPath(AccessControl.CurrentWeight),
    name: AccessControl.CurrentWeight,
    element: <CurrentWeight />,
  },
  {
    path: createPath(AccessControl.WeightGoal),
    name: AccessControl.WeightGoal,
    element: <WeightGoal />,
  },
  {
    path: createPath(AccessControl.EventComing),
    name: AccessControl.EventComing,
    element: <EventComing />,
  },
  {
    path: createPath(AccessControl.EventDate),
    name: AccessControl.EventDate,
    element: <EventDate />,
  },

  {
    path: createPath(AccessControl.ExpectedResultGraph),
    name: AccessControl.ExpectedResultGraph,
    element: <ExpectedResultGraph />,
  },

  {
    path: createPath(AccessControl.TransformYourGoals),
    name: AccessControl.TransformYourGoals,
    element: <TransformYourGoals />,
  },
  {
    path: createPath(AccessControl.CurrentHealthInfo),
    name: AccessControl.CurrentHealthInfo,
    element: <CurrentHealthInfo />,
  },
  {
    path: createPath(AccessControl.TypicalDay),
    name: AccessControl.TypicalDay,
    element: <TypicalDay />,
  },
  {
    path: createPath(AccessControl.FruitVegetableIntake),
    name: AccessControl.FruitVegetableIntake,
    element: <FruitVegetableIntake />,
  },
  {
    path: createPath(AccessControl.PlantFoodsInfo),
    name: AccessControl.PlantFoodsInfo,
    element: <PlantFoodsInfo />,
  },
  {
    path: createPath(AccessControl.HungerLevels),
    name: AccessControl.HungerLevels,
    element: <HungerLevels />,
  },
  {
    path: createPath(AccessControl.NoStarvation),
    name: AccessControl.NoStarvation,
    element: <NoStarvation />,
  },
  {
    path: createPath(AccessControl.YourEnergy),
    name: AccessControl.YourEnergy,
    element: <YourEnergy />,
  },
  {
    path: createPath(AccessControl.EnergySlump),
    name: AccessControl.EnergySlump,
    element: <EnergySlump />,
  },
  {
    path: createPath(AccessControl.RateHabits),
    name: AccessControl.RateHabits,
    element: <RateHabits />,
  },

  {
    path: createPath(AccessControl.EmotionComfort),
    name: AccessControl.EmotionComfort,
    element: <EmotionComfort />,
  },
  {
    path: createPath(AccessControl.OldHabits),
    name: AccessControl.OldHabits,
    element: <OldHabits />,
  },
  {
    path: createPath(AccessControl.ClearMyPlate),
    name: AccessControl.ClearMyPlate,
    element: <ClearMyPlate />,
  },

  {
    path: createPath(AccessControl.MultiTaskingWhileEating),
    name: AccessControl.MultiTaskingWhileEating,
    element: <MultiTaskingWhileEating />,
  },
  {
    path: createPath(AccessControl.AdjustingPlanLoading),
    name: AccessControl.AdjustingPlanLoading,
    element: <AdjustingPlanLoading />,
  },
  {
    path: createPath(AccessControl.ExpectedResultGraphStep2),
    name: AccessControl.ExpectedResultGraphStep2,
    element: <ExpectedResultGraphStep2 />,
  },

  {
    path: createPath(AccessControl.NearlyThereInfo),
    name: AccessControl.NearlyThereInfo,
    element: <NearlyThereInfo />,
  },

  // Second last

  {
    path: createPath(AccessControl.SimpleJourneyFeel),
    name: AccessControl.SimpleJourneyFeel,
    element: <SimpleJourneyFeel />,
  },
  {
    path: createPath(AccessControl.WantWithKetoSlim),
    name: AccessControl.WantWithKetoSlim,
    element: <WantWithKetoSlim />,
  },
  {
    path: createPath(AccessControl.HappyWeight),
    name: AccessControl.HappyWeight,
    element: <HappyWeight />,
  },
  {
    path: createPath(AccessControl.ThinkOfReachingHappyWeight),
    name: AccessControl.ThinkOfReachingHappyWeight,
    element: <ThinkOfReachingHappyWeight />,
  },
  {
    path: createPath(AccessControl.DriveOfLosingWeight),
    name: AccessControl.DriveOfLosingWeight,
    element: <DriveOfLosingWeight />,
  },
  {
    path: createPath(AccessControl.ChangeYourSkin),
    name: AccessControl.ChangeYourSkin,
    element: <ChangeYourSkin />,
  },
  {
    path: createPath(AccessControl.BackIssues),
    name: AccessControl.BackIssues,
    element: <BackIssues />,
  },
  {
    path: createPath(AccessControl.StomachDiscomfort),
    name: AccessControl.StomachDiscomfort,
    element: <StomachDiscomfort />,
  },
  {
    path: createPath(AccessControl.KetoInfo),
    name: AccessControl.KetoInfo,
    element: <KetoInfo />,
  },
  {
    path: createPath(AccessControl.WeightLossPrograms),
    name: AccessControl.WeightLossPrograms,
    element: <WeightLossPrograms />,
  },
  {
    path: createPath(AccessControl.WeightLossMethods),
    name: AccessControl.WeightLossMethods,
    element: <WeightLossMethods />,
  },
  {
    path: createPath(AccessControl.KetoIsNumberOne),
    name: AccessControl.KetoIsNumberOne,
    element: <KetoIsNumberOne />,
  },
  {
    path: createPath(AccessControl.HealthConditions),
    name: AccessControl.HealthConditions,
    element: <HealthConditions />,
  },
  {
    path: createPath(AccessControl.AnyEatingDisorder),
    name: AccessControl.AnyEatingDisorder,
    element: <AnyEatingDisorder />,
  },
  {
    path: createPath(AccessControl.AnyMedications),
    name: AccessControl.AnyMedications,
    element: <AnyMedications />,
  },
  {
    path: createPath(AccessControl.PregnantOrLaboring),
    name: AccessControl.PregnantOrLaboring,
    element: <PregnantOrLaboring />,
  },
  {
    path: createPath(AccessControl.HormonalChanges),
    name: AccessControl.HormonalChanges,
    element: <HormonalChanges />,
  },
  {
    path: createPath(AccessControl.YouAreFit),
    name: AccessControl.YouAreFit,
    element: <YouAreFit />,
  },
  {
    path: createPath(AccessControl.AlcoholConsumption),
    name: AccessControl.AlcoholConsumption,
    element: <AlcoholConsumption />,
  },
  {
    path: createPath(AccessControl.EatingHabits),
    name: AccessControl.EatingHabits,
    element: <EatingHabits />,
  },
  {
    path: createPath(AccessControl.SnackCravingsTime),
    name: AccessControl.SnackCravingsTime,
    element: <SnackCravingsTime />,
  },
  {
    path: createPath(AccessControl.WhySnackCravings),
    name: AccessControl.WhySnackCravings,
    element: <WhySnackCravings />,
  },
  // LoadingStorySequence

  {
    path: createPath(AccessControl.LoadingStorySequence),
    name: AccessControl.LoadingStorySequence,
    element: <LoadingStorySequence />,
  },
  {
    path: createPath(AccessControl.JoinCommunity),
    name: AccessControl.JoinCommunity,
    element: <JoinCommunity />,
  },

  // Last row
  {
    path: createPath(AccessControl.WeightLossMotivation),
    name: AccessControl.WeightLossMotivation,
    element: <WeightLossMotivation />,
  },
  {
    path: createPath(AccessControl.MotivationToReactTarget),
    name: AccessControl.MotivationToReactTarget,
    element: <MotivationToReactTarget />,
  },
  {
    path: createPath(AccessControl.YourUncertainity),
    name: AccessControl.YourUncertainity,
    element: <YourUncertainity />,
  },
  {
    path: createPath(AccessControl.PeopleAbondonInfo),
    name: AccessControl.PeopleAbondonInfo,
    element: <PeopleAbondonInfo />,
  },
  {
    path: createPath(AccessControl.YourFeelingOfHittingTarget),
    name: AccessControl.YourFeelingOfHittingTarget,
    element: <YourFeelingOfHittingTarget />,
  },
  {
    path: createPath(AccessControl.WeightLossUnlocked),
    name: AccessControl.WeightLossUnlocked,
    element: <WeightLossUnlocked />,
  },
  {
    path: createPath(AccessControl.YouRock),
    name: AccessControl.YouRock,
    element: <YouRock />,
  },
  {
    path: createPath(AccessControl.KitchenAppliances),
    name: AccessControl.KitchenAppliances,
    element: <KitchenAppliances />,
  },
  {
    path: createPath(AccessControl.Vegetable),
    name: AccessControl.Vegetable,
    element: <Vegetable />,
  },
  {
    path: createPath(AccessControl.Products),
    name: AccessControl.Products,
    element: <Products />,
  },
  {
    path: createPath(AccessControl.Meat),
    name: AccessControl.Meat,
    element: <Meat />,
  },
  {
    path: createPath(AccessControl.HowQuicklyWeightLoss),
    name: AccessControl.HowQuicklyWeightLoss,
    element: <HowQuicklyWeightLoss />,
  },
  {
    path: createPath(AccessControl.GreatJob),
    name: AccessControl.GreatJob,
    element: <GreatJob />,
  },
  {
    path: createPath(AccessControl.YoyoEffect),
    name: AccessControl.YoyoEffect,
    element: <YoyoEffect />,
  },
  {
    path: createPath(AccessControl.ResultsLoadingScreen),
    name: AccessControl.ResultsLoadingScreen,
    element: <ResultsLoadingScreen />,
  },
  {
    path: createPath(AccessControl.LoadingMessages),
    name: AccessControl.LoadingMessages,
    element: <LoadingMessages />,
  },
  {
    path: createPath(AccessControl.SubmitEmail),
    name: AccessControl.SubmitEmail,
    element: <SubmitEmail />,
  },
  {
    path: createPath(AccessControl.PlanReady),
    name: AccessControl.PlanReady,
    element: <ResultCheckout />,
  },
];

export default function Route() {
  return [
    {
      path: AccessControl.Homepage.path,
      name: "Home",
      element: <HomePage />,
    },
    {
      path: AccessControl.Login.path,
      name: "Login",
      element: <Login />,
    },
    {
      path: AccessControl.Email.path,
      name: "Email",
      element: <Email />,
    },
    {
      path: AccessControl.About_Us.path,
      name: "About_Us",
      element: <AboutUs />,
    },
    {
      path: AccessControl.Privacy_policy.path,
      name: "privacy_policy",
      element: <PrivacyPolicy />,
    },
    {
      path: AccessControl.contact_us.path,
      name: "Contact_us",
      element: <Contact />,
    },
    {
      path: AccessControl.terms_condition.path,
      name: "Terms&condition",
      element: <Terms />,
    },
    {
      path: AccessControl.StartStep.path,
      name: "landing",
      element: <LandingPage />,
    },
    {
      path: AccessControl.StartStepMen.path,
      name: "landing-men",
      element: <LandingPageMen />,
    },
    {
      path: AccessControl.StartStepWomen.path,
      name: "landing-women",
      element: <LandingPageWomen />,
    },
    {
      path: AccessControl.StartStep40.path,
      name: "landing-40",
      element: <LandingPage40 />,
    },
    {
      path: AccessControl.YouFit.path,
      name: "YouFit",
      element: <YouFit21 />,
    },
    {
      path: AccessControl.YouRock.path,
      name: "YouRock",
      element: <YouRock27 />,
    },
    {
      path: AccessControl.TargetReach.path,
      name: "TargetReach",
      element: <HelpPeople28 />,
    },
    {
      path: AccessControl.WeightLossProgram29.path,
      name: "WeightLossProgram29",
      element: <WeightLossProgram29 />,
    },
    {
      path: AccessControl.TypicleInfo.path,
      name: "TypicleInfo",
      element: <LongTermResult />,
    },
    {
      path: AccessControl.Result.path,
      name: "Result",
      element: <Results />,
    },
    {
      path: AccessControl.ResultNew.path,
      name: "ResultNew",
      element: <NewResult />,
    },
    {
      path: AccessControl.ResultNew2.path,
      name: "ResultNew2",
      element: <NewResult2 />,
    },
    {
      path: AccessControl.Checkout2.path,
      name: "CheckoutNew",
      element: <NewCheckout />,
    },
    {
      path: AccessControl.ResultCheckout.path,
      name: "ResultCheckout",
      element: <ResultCheckout />,
    },
    {
      path: AccessControl.ProcessingPayment.path,
      name: "ProcessingPayment",
      element: <ProcessingPayment />,
    },
    {
      path: AccessControl.Checkout.path,
      name: "Checkout",
      element: <Checkout />,
    },
    {
      path: AccessControl.KetoBoost.path,
      name: "KetoBoost",
      element: <KetoBoostPage />,
    },
    {
      path: AccessControl.Premium.path,
      name: "PremiumUpsell",
      element: <PremiumUpsell />,
    },
    {
      path: AccessControl.Coaching.path,
      name: "Coaching",
      element: <CoachingPage />,
    },
    {
      path: AccessControl.Shipping.path,
      name: "Shipping",
      element: <Shipping />,
    },
    {
      path: AccessControl.ThankYou.path,
      name: "ThankYou",
      element: <ThankYou />,
    },
    {
      path: AccessControl.CampaignResult.path,
      name: "CampaignResult",
      element: <Results />,
    },
    {
      path: AccessControl.CampaignCheckout.path,
      name: "CampaignCheckout",
      element: <Checkout />,
    },
    {
      path: AccessControl.CampaignPlanReady.path,
      name: "ResultCheckout",
      element: <ResultCheckout campaign={true} />,
    },
    {
      path: AccessControl.CampaignKetoBoost.path,
      name: "CampaignKetoBoost",
      element: <KetoBoostPage />,
    },
    {
      path: AccessControl.CampaignShipping.path,
      name: "CampaignShipping",
      element: <Shipping />,
    },
    {
      path: AccessControl.CampaignThankYou.path,
      name: "CampaignThankYou",
      element: <ThankYou />,
    },
    {
      path: AccessControl.Chargebee.path,
      name: "Chargebee",
      element: <Chargebee />,
    },
    {
      path: AccessControl.DataProcessing34.path,
      name: "DataProcessing34",
      element: <DataProcessing34 />,
    },
    {
      path: AccessControl.StepMessages.path,
      name: "step-processing",
      element: <StepMessages />,
    },
    { path: AccessControl.Workout.path, name: "workout", element: <Workout /> },

    // quiz 5
    {
      path: AccessControl.Step1Familiarity.path,
      name: "step1-familiarity",
      element: <Step1Familiarity />,
    },
    {
      path: AccessControl.Step2Info.path,
      name: "step2-Info",
      element: <Step2Info />,
    },
    {
      path: AccessControl.Step3Time.path,
      name: "step3-time",
      element: <Step3Time />,
    },
    {
      path: AccessControl.Step4Meat.path,
      name: "step4-meat",
      element: <Step4Meat />,
    },
    {
      path: AccessControl.Step5Products.path,
      name: "step5-products",
      element: <Step5Products />,
    },
    {
      path: AccessControl.Step6Activity.path,
      name: "step6-activity",
      element: <Step6Activity />,
    },
    {
      path: AccessControl.Step7Willingness.path,
      name: "step7-willingness",
      element: <Step7Willingness />,
    },
    {
      path: AccessControl.Step8Medical.path,
      name: "step8-medical",
      element: <Step8Medical />,
    },
    {
      path: AccessControl.Step9Measurements.path,
      name: "step9-measurements",
      element: <Step9Measurements />,
    },

    {
      children: quiz10Routes,
      element: <QuizLayout />,
    },
  ];
}
