import { useEffect } from "react";
import { useLocation } from "react-router";

const RouteChange = () => {
  const location = useLocation();

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.href,
        title: window.location.pathname,
      },
    });
  }, [location]);
  return null;
};

export default RouteChange;
