export const MenCalories = 
[
    { weight: 0, calories: 55 },
    { weight: 5, calories: 110 },
    { weight: 10, calories: 165 },
    { weight: 15, calories: 220 },
    { weight: 20, calories: 275 },
    { weight: 25, calories: 330 },
    { weight: 30, calories: 385 },
    { weight: 35, calories: 440 },
    { weight: 40, calories: 495 },
    { weight: 45, calories: 550 },
    { weight: 50, calories: 605 },
    { weight: 55, calories: 660 },
    { weight: 60, calories: 715 },
    { weight: 65, calories: 770 },
    { weight: 70, calories: 825 },
    { weight: 75, calories: 880 },
    { weight: 80, calories: 935 },
    { weight: 85, calories: 990 },
    { weight: 90, calories: 1045 },
    { weight: 95, calories: 1100 },
    { weight: 100, calories: 1155 },
    { weight: 105, calories: 1210 },
    { weight: 110, calories: 1265 },
    { weight: 115, calories: 1320 },
    { weight: 120, calories: 1375 },
    { weight: 125, calories: 1430 },
    { weight: 130, calories: 1485 },
    { weight: 135, calories: 1540 },
    { weight: 140, calories: 1595 },
    { weight: 145, calories: 1650 },
    { weight: 150, calories: 1705 },
    { weight: 155, calories: 1760 },
    { weight: 160, calories: 1815 },
    { weight: 165, calories: 1870 },
    { weight: 170, calories: 1925 },
    { weight: 175, calories: 1980 },
    { weight: 180, calories: 2035 },
    { weight: 185, calories: 2090 },
    { weight: 190, calories: 2145 },
    { weight: 195, calories: 2200 },
    { weight: 200, calories: 2255 },
    { weight: 205, calories: 2310 },
    { weight: 210, calories: 2365 },
    { weight: 215, calories: 2420 },
    { weight: 220, calories: 2475 },
    { weight: 225, calories: 2530 },
    { weight: 230, calories: 2585 },
    { weight: 235, calories: 2640 },
    { weight: 240, calories: 2695 },
    { weight: 245, calories: 2750 },
    { weight: 250, calories: 2805 },
    { weight: 255, calories: 2860 },
    { weight: 260, calories: 2915 },
    { weight: 265, calories: 2970 },
    { weight: 270, calories: 3025 },
    { weight: 275, calories: 3080 },
    { weight: 280, calories: 3135 },
    { weight: 285, calories: 3190 },
    { weight: 290, calories: 3245 },
    { weight: 295, calories: 3300 },
    { weight: 300, calories: 3355 },
    { weight: 305, calories: 3410 },
    { weight: 310, calories: 3465 },
    { weight: 315, calories: 3520 },
    { weight: 320, calories: 3575 },
    { weight: 325, calories: 3630 },
    { weight: 330, calories: 3685 },
    { weight: 335, calories: 3740 },
    { weight: 340, calories: 3795 },
    { weight: 345, calories: 3850 },
    { weight: 350, calories: 3905 },
    { weight: 355, calories: 3960 },
    { weight: 360, calories: 4015 },
    { weight: 365, calories: 4070 },
    { weight: 370, calories: 4125 },
    { weight: 375, calories: 4180 },
    { weight: 380, calories: 4235 },
    { weight: 385, calories: 4290 },
    { weight: 390, calories: 4345 },
    { weight: 395, calories: 4400 },
    { weight: 400, calories: 4455 }
  ]

// [
//     {
//         weight: 140,
//         calories: 1527
//     },
//     {
//         weight: 145,
//         calories: 1582
//     },
//     {
//         weight: 150,
//         calories: 1636
//     },
//     {
//         weight: 155,
//         calories: 1691
//     },
//     {
//         weight: 160,
//         calories: 1745
//     },
//     {
//         weight: 165,
//         calories: 1800
//     },
//     {
//         weight: 170,
//         calories: 1855
//     },
//     {
//         weight: 175,
//         calories: 1909
//     },
//     {
//         weight: 180,
//         calories: 1964
//     },
//     {
//         weight: 185,
//         calories: 2018
//     },
//     {
//         weight: 190,
//         calories: 2073
//     },
//     {
//         weight: 195,
//         calories: 2127
//     },
//     {
//         weight: 200,
//         calories: 2182
//     },
//     {
//         weight: 205,
//         calories: 2236
//     },
//     {
//         weight: 210,
//         calories: 2291
//     },
//     {
//         weight: 215,
//         calories: 2345
//     },
//     {
//         weight: 220,
//         calories: 2400
//     },
//     {
//         weight: 225,
//         calories: 2455
//     },
//     {
//         weight: 230,
//         calories: 2509
//     },
//     {
//         weight: 235,
//         calories: 2564
//     },
//     {
//         weight: 240,
//         calories: 2618
//     },
//     {
//         weight: 245,
//         calories: 2673
//     },
//     {
//         weight: 250,
//         calories: 2727
//     },
//     {
//         weight: 255,
//         calories: 2782
//     },
//     {
//         weight: 260,
//         calories: 2836
//     },
//     {
//         weight: 265,
//         calories: 2891
//     }
// ]