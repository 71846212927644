import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mt-auto">
      <div className="mt-6 text-center text-gray-400">
        <p className="text-[14px] mb-2">You can opt-out at any time, Contact us at:</p>
        <p className="text-[14px]">Life Path Digital </p>
        <p className="text-[14px]">130 Broadway Blvd unit 110, Sherwood Park, AB T8H 2A3</p>
        <p className="text-[14px]">Life Path USA Inc. </p>
        <p className="text-[14px]">30 N Gould St # 21489, Sheridan, WY 82801</p>
        <p className="text-[14px]">Email: <a className="text-[14px]" href="mailto:support@myketoslim.com">support@myketoslim.com</a></p>
      </div>
      <p className="pt-4 pb-5 text-center">
        <a href="/">
          <button className="px-5 text-sm text-teal-500 border-r border-slate-400">
            Home
          </button>
        </a>
        <a href="/about-us">
          <button className="px-5 text-sm text-teal-500 border-r border-slate-400">
            About Us
          </button>
        </a>
        <a href="/privacy-policy">
          <button className="px-5 text-sm text-teal-500 border-r border-slate-400">
            Privacy
          </button>
        </a>
        <a href="/terms&condition">
          <button className="px-5 text-sm text-teal-500">Terms</button>
        </a>
        <a href="/contact-us">
          <button className="px-5 text-sm text-teal-500 border-l border-slate-400">
            Contact
          </button>
        </a>
      </p>
      <p className="pb-5 text-center text-[14px] text-gray-400">
        Copyright &copy; {new Date().getFullYear()} myketoslim.com. All Rights Reserved.
      </p>
    </div>
  );
};

export default Footer;
