import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import clsx from "clsx";
import Header from "./Header";
import { FaCheckCircle } from "react-icons/fa";
import { AccessControl } from "../../route-control/public-route";

const Step2 = () => {
  const navigate = useNavigate();

  const benefits = [
    "Weight loss",
    "Reduced blood pressure",
    "Slower ageing process",
    "Improved sleep and mood",
    "Increased energy efficiency",
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleForm = () => {
    setTimeout(() => {
      navigate(AccessControl.Step3Time.path);
    }, 250);
  };

  const goBack = () => {
    setTimeout(() => {
      navigate(AccessControl.Step1Familiarity.path);
    }, 250);
  };

  return (
    <div className="py-6 px-4 max-w-sm mx-auto">
      <Header goBack={goBack} step={2} />

      <section className="flex flex-col gap-4 sm:gap-6 mt-16 desktop1600:mt-10 smx:mt-5 md:rounded-lg sm:bg-gray-100 sm:p-6 md:p-8 lg:p-10">
        <h2 className="text-start font-semibold text-gray-800  dark:text-white dark:sm:text-gray-800 font-Montserrat text-3xl desktop1600:text-2xl smx:text-[18px]">
          What is Keto diet?
        </h2>

        <div>
          <p className="text-xs text-gray-800 dark:text-white dark:sm:text-gray-800">
            The ketogenic diet is a very low-carb, high-fat diet. To achieve
            positive results, this diet has to be very low in carbohydrates,
            high in dietary fat and include a moderate amount of proteins. This
            reduction in carbs puts your body into a metabolic state called
            ketosis. When this happens, your body becomes incredibly efficient
            at burning fat for energy. Ketogenic diet can cause massive
            reductions in blood sugar, insulin levels and help with weight loss.
          </p>

          <p className="mt-2 text-sm font-medium text-gray-800 dark:text-white dark:sm:text-gray-800 leading-6">
            Benefits of keto diet:
          </p>
          <ul className="text-gray-800 dark:text-white dark:sm:text-gray-800">
            {benefits.map((benefit) => (
              <li key={benefit} className="flex items-center gap-2">
                <FaCheckCircle size={12} className="text-teal-600" />
                <span className="text-xs leading-[1.25rem] font-medium ">
                  {benefit}
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="sm:px-4 flex flex-col items-center gap-2.5">
          <button
            onClick={handleForm}
            className={clsx(
              "w-full rounded-full p-2 h-[40px] md:h-[46px] font-medium font-Montserrat text-white  text-sm md:text-base",
              "flex items-center gap-3 text-center justify-center",
              "bg-[#13BBA0] hover:bg-teal-600 transition-all border-2 border-[#13BBA0]/50"
            )}
            type="button"
          >
            Got it
          </button>

          <a
            href="https://www.talkingaboutthescience.com/studies/Walczyk2017.pdf"
            target="_blank"
            rel="noreferrer noopener"
            className="text-[10px] underline-offset-2 hover:underline transition-all cursor-pointer sm:text-gray-800"
          >
            Click here for scientific information about keto diet
          </a>
        </div>
      </section>
    </div>
  );
};

export default Step2;
