import "./style.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BeforeImage from "./assets/female-before.png";
import AfterImage from "./assets/female-after.png";
import MaleBeforeImage from "./assets/male-before.png";
import MaleAfterImage from "./assets/male-after.png";
import checkedIcon from "./assets/tick.png";
import logo1 from "./assets/logo1.png";
import logo2 from "./assets/logo2.png";
import logo3 from "./assets/logo3.png";
import bar from "./assets/bar.png";
import barGray from "./assets/bar-gray.png";
import barBlack from "./assets/bar-black.png";
import plan_view from "./assets/plan_view.png";
import redArrow from "./assets/arrow-red.png";
import blackArrow from "./assets/arrow-black.png";
import foodPlate from "./assets/food-plates-desktop.png";

import { useNavigate } from "react-router";
import { AccessControl } from "../../../route-control/public-route";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserData,
  targetGoalTime,
} from "../../../redux/quizSlice/quizSlice";
import { useEffect } from "react";
import { calculateWeightLoss } from "../../quiz5/calculateWeightLoss";
import { MenCalories } from "../../../data/ManCalories";
import { WomanCalories } from "../../../data/WomanCalories";
import { FUNNEL_TYPE } from "../../../utils";

const WeightSeries = ({ target, unit }) => {
  return (
    <div className="-mx-6 mb-2 flex items-center justify-between md:mx-0">
      <span className="text-sm text-gray-200 hidden sm:block">
        {target + 4}
      </span>
      <span className="text-sm text-gray-300">{target + 3}</span>
      <span className="text-sm text-gray-400">{target + 2}</span>
      <span className="text-sm text-gray-600">{target + 1}</span>
      <span className="text-2xl font-bold transform translate-y-[-7px]">
        {target} <span className="font-medium text-xs">{unit}</span>
      </span>
      <span className="text-sm text-gray-600">{target - 1}</span>
      <span className="text-sm text-gray-400">{target - 2}</span>
      <span className="text-sm text-gray-300">{target - 3}</span>
      <span className="text-sm text-gray-200 hidden sm:block">
        {target - 4}
      </span>
    </div>
  );
};

function NewResult2() {
  const weightUnit = {
    LbFt: "lb",
    KgCm: "kg",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(selectUserData);
  const gender = state?.gender;

  const currentUnit = state?.measurements.unitType;
  const currentWeight = state?.measurements.weight;
  const height = state?.measurements.height;
  const targetWeight = state?.measurements.targetWeight;

  const {
    firstWeek,
    weightAfterFirstMonth,
    nextMonthShort,
    targetMonth,
    targetMonthShort,
    targetYear,
  } = calculateWeightLoss(currentWeight, targetWeight, weightUnit[currentUnit]);

  useEffect(() => {
    if (!targetWeight && !currentWeight) {
      navigate(AccessControl.Step9Measurements.path);
    }
  }, [currentWeight, targetWeight, navigate]);

  const bmiResult = () => {
    if (state?.measurements?.unitType === "KgCm") {
      const heightM = height * 0.01; // cm to m
      return (currentWeight / Math.pow(heightM, 2)).toFixed(2);
    } else if (state?.measurements?.unitType === "LbFt") {
      const inches = state?.measurements?.inch;
      const heightFT = parseFloat(height * 12) + parseFloat(inches);
      const weight = currentWeight * 703;
      return parseFloat(weight / Math.pow(heightFT, 2)).toFixed(2);
    }
  };

  const bmiStatus = () => {
    if (bmiResult() < 18.5) {
      return { status: "Underweight", position: "20%" };
    } else if (bmiResult() < 25) {
      return { status: "Normal", position: "50%" };
    } else if (bmiResult() < 29.9) {
      return { status: "Overweight", position: "75%" };
    } else if (bmiResult() >= 30) {
      return { status: "Obese", position: "95%" };
    } else {
      return { status: "Normal", position: "50%" };
    }
  };

  const calories = () => {
    let data = [];

    const allCaloriesRange =
      state.gender === "male" ? MenCalories : WomanCalories;

    data = allCaloriesRange.filter((item) => {
      const weightLbs =
        currentUnit === "KgCm"
          ? parseInt(targetWeight * 2.20462262)
          : parseFloat(targetWeight);
      const weightRange = item.weight + 20;

      return weightLbs >= item.weight && weightLbs <= weightRange;
    });

    const currentCalories = allCaloriesRange.filter((item) => {
      const weightLbs =
        currentUnit === "KgCm"
          ? parseInt(currentWeight * 2.20462262)
          : parseFloat(currentWeight);
      const weightRange = item.weight + 20;

      return weightLbs >= item.weight && weightLbs <= weightRange;
    })[0];

    const findPosition = (caloriesData) => {
      return `${
        ((allCaloriesRange.findIndex(
          (item) => item.calories === caloriesData?.calories
        ) +
          1) /
          allCaloriesRange.length) *
        100
      }%`;
    };

    if (data.length === 1) {
      return {
        range: data[0]?.calories,
        position: findPosition(data[0]),
        currentCaloriesPos: findPosition(currentCalories),
      };
    } else if (data.length > 1) {
      return {
        range: `${data[0]?.calories}-${data[data.length - 1]?.calories}`,
        position: findPosition(data[0]),
        currentCaloriesPos: findPosition(currentCalories),
      };
    } else {
      return null;
    }
  };

  const getYourPlan = () => {
    dispatch(
      targetGoalTime({
        month: targetMonth,
        year: targetYear,
      })
    );

    setTimeout(() => {
      navigate(AccessControl.Email.path);
    }, 250);
  };

  return (
    currentWeight &&
    targetWeight && (
      <div className="bg-[--bg1] text-black">
        <div className="wrapper">
          <LazyLoadImage
            src="https://dev.myketoslim.com/images/keto-logo.png"
            alt="Keto Slim"
            className="w-[240px] p-5 mx-auto"
          />
        </div>

        <section className="relative overflow-hidden">
          <div className="section-wrapper">
            <div className="grid lg:grid-cols-2 p-4 py-6 sm:py-10 lg:py-12 gap-6 lg:mt-8 justify-center">
              <div className="flex flex-col justify-center">
                <h1 className="text-4xl md:text-5xl font-bold max-w-[18rem] sm:max-w-sm md:!leading-[1.125]">
                  Get your{" "}
                  <span className="inline text-red-600 text-4xl md:text-5xl font-bold">
                    personalized
                  </span>{" "}
                  Keto Diet
                </h1>
                <p className="text-sm font-bold mt-2">
                  Based on your profile, you will be...
                </p>
              </div>

              <div className="p-4 sm:p-5 bg-white rounded-lg shadow-sm max-w-lg overflow-hidden">
                <WeightSeries
                  target={targetWeight}
                  unit={weightUnit[currentUnit]}
                />
                <p className="text-center text-xs font-medium transform -translate-y-1">
                  By {targetMonth} {targetYear}
                </p>
                <div className="bg-teal-100/40 mt-2 mb-4 pt-1 rounded-lg relative overflow-hidden">
                  <div className="flex h-full justify-around absolute w-full z-[1]">
                    <div className="h-full border-l-2 border-gray-200"></div>
                    <div className="h-full border-l-2 border-gray-200"></div>
                    <div className="h-full border-l-2 border-gray-200"></div>
                  </div>
                  <svg
                    className="relative z-10 shadow-md"
                    width="100%"
                    height="100%"
                    viewBox="0 0 464 177"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 2C13.4184 2 142.77 84.6036 156.169 84.6036C169.568 84.6036 374.855 136.036 388.254 136.036C401.652 136.036 462 175 462 175"
                      stroke="rgb(255, 88, 75)"
                      strokeWidth="4"
                    />
                  </svg>
                  <div className="bg-red-600 w-3 h-3 border-2 border-white rounded-full left-[32%] top-[44%] absolute z-10"></div>
                  <div className="text-[10.5px] font-medium border-[1px] bg-white inline-block rounded-md py-0.5 px-1 left-[35%] top-[20%] sm:top-[30%] absolute z-10">
                    {nextMonthShort} {weightAfterFirstMonth}{" "}
                    {weightUnit[currentUnit]}
                  </div>
                  <div className="bg-red-600 w-3 h-3 border-2 border-white rounded-full left-[83%] top-[73%] absolute z-10"></div>
                  <div className="text-[10.5px] font-medium border-[1px] bg-white text-red-600 inline-block rounded-md py-0.5 px-1 left-[78%] top-[45%] sm:top-[55%] absolute z-10">
                    {targetMonthShort} {targetWeight} {weightUnit[currentUnit]}
                  </div>
                </div>
                <div className="text-[11px] font-medium">
                  This graph is not personalized. Prior to initiating any weight
                  loss, diet, exercise, or other health program, we strongly
                  advise you to seek guidance from your doctor.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-[--bg2] py-2">
          <div className="section-wrapper">
            <div className="grid grid-cols-1 justify-items-center lg:grid-cols-2 px-4 py-4 sm:py-6 gap-6 sm:gap-8 lg:mt-10 justify-center">
              <div className="p-8 bg-white rounded-lg shadow-sm w-full h-full max-w-md flex flex-col justify-center gap-2 text-center">
                <h2 className="text-6xl font-bold">
                  87<span className="text-2xl">%</span>
                </h2>
                <div>
                  <p className="text-sm font-medium">Users lost wight using</p>
                  <p className="text-sm font-medium">Keto Diet</p>
                </div>
              </div>

              <div className="p-8 bg-white rounded-lg shadow-sm w-full max-w-md flex flex-col gap-8">
                <div className="flex justify-between">
                  {["M", "T", "W", "T", "F", "S", "S"].map((d, n) => (
                    <div key={n} className="text-center">
                      <span className="text-gray-500 block text-xs mb-1">
                        {d}
                      </span>
                      <span className="font-bold text-sm">{n + 1}</span>
                    </div>
                  ))}
                </div>
                <div className="text-center">
                  <h6 className="text-5xl text-red-500 font-bold">
                    -{firstWeek} <span>{weightUnit[currentUnit]}</span>
                  </h6>
                  <p className="text-xs font-medium mt-2">After first week</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center py-8">
            <button
              type="button"
              className="bg-red-500 hover:bg-red-600 transition-all rounded-lg max-w-[16rem] py-2.5 w-full text-white font-medium text-sm"
              onClick={getYourPlan}
            >
              Get your plan
            </button>
          </div>

          <section className="section-wrapper px-4 pb-6">
            <h2 className="font-bold text-2xl pb-4 text-center mt-5">
              Personal Summary
            </h2>
            <div className="grid lg:grid-cols-2 p-4 gap-6 sm:gap-8 justify-center">
              {bmiResult() && (
                <div className="p-4 bg-white rounded-lg shadow-sm max-w-lg gap-2">
                  <h2 className="text-sm font-bold bg-[--bg2] inline-block px-2 rounded-lg mb-2">
                    Your BMI
                  </h2>
                  <h2 className="text-3xl font-bold text-red-500">
                    {bmiResult()}{" "}
                    <span className="">{bmiStatus()?.status}</span>
                  </h2>
                  <p className="text-xs font-medium">
                    {`Your BMI is ${
                      parseFloat(bmiStatus()?.position.split("%")[0]) - 50
                    }% above average`}
                  </p>
                  <div className="relative mt-4 pt-1">
                    <img className="w-full" src={bar} alt="bar" />
                    <img
                      className="absolute bottom-full"
                      style={{ left: `calc(${bmiStatus()?.position} - 10px)` }}
                      src={redArrow}
                      alt="redArrow"
                    />
                  </div>
                  <div className="grid grid-cols-3 mt-2">
                    <div className="text-[#78bcbf] uppercase font-normal text-center text-[11px]">
                      Underweight
                    </div>
                    <div className="text-[#bbd49a] uppercase font-normal text-center text-[11px]">
                      Normal
                    </div>
                    <div className="text-[#ff6458] uppercase font-normal text-center text-[11px]">
                      Overweight
                    </div>
                  </div>
                </div>
              )}

              {calories() && (
                <div className="p-4 bg-white rounded-lg shadow-sm max-w-lg gap-2">
                  <h2 className="text-sm font-bold bg-[--bg2] inline-block px-2 rounded-lg mb-2">
                    Daily calorie intake
                  </h2>
                  <h2 className="text-3xl font-bold">
                    {calories().range} <span className="">calories</span>
                  </h2>
                  <p className="text-xs font-medium">For optimal weight loss</p>
                  <div className="flex mt-4 pt-1 relative">
                    <img
                      style={{ left: `calc(${calories().position} - 4px)` }}
                      src={blackArrow}
                      alt="Arrow"
                      className="absolute bottom-full -translate-x-[50%]"
                    />
                    <img
                      style={{
                        left: `calc(${calories().currentCaloriesPos} - 4px)`,
                      }}
                      src={blackArrow}
                      alt="Arrow"
                      className="absolute bottom-full -translate-x-[50%]"
                    />
                  </div>
                  <div className="relative flex gap-1 overflow-hidden">
                    <img src={barGray} alt="bar" />
                    <img src={barGray} alt="bar" />
                    <img src={barGray} alt="bar" />
                    <div
                      style={{ width: `${calories().currentCaloriesPos}` }}
                      className="absolute left-0  h-full z-1 flex gap-1 overflow-hidden"
                    >
                      <img src={barBlack} alt="bar" />
                      <img src={barBlack} alt="bar" />
                      <img src={barBlack} alt="bar" />
                    </div>
                  </div>
                  <div className="text-center text-xs mt-2">Calories range</div>
                </div>
              )}

              <div className="p-4 bg-white rounded-lg shadow-sm max-w-lg gap-2">
                <h3 className="text-sm font-bold bg-[--bg2] inline-block px-2 rounded-lg mb-2">
                  Body Change Estimations
                </h3>
                <div className="grid grid-cols-3 px-4">
                  <div className="flex flex-col justify-center items-center">
                    <h4 className="text-xs mb-2">Before</h4>
                    <img
                      src={
                        state?.gender === "male" ? MaleBeforeImage : BeforeImage
                      }
                      alt="Body Before"
                    />
                  </div>
                  <div className="text-center flex flex-col justify-end">
                    <div className="estimations2 mb-2">
                      <span className="inline-block bg-white font-bold">
                        Waist <span className="text-red-500 ">-14%</span>
                      </span>
                    </div>
                    <div className="estimations2 mb-2">
                      <span className="inline-block bg-white font-bold">
                        Hips <span className="text-red-500 ">-10%</span>
                      </span>
                    </div>
                    <div className="estimations2 mb-2">
                      <span className="inline-block bg-white font-bold">
                        Thighs <span className="text-red-500 ">-12%</span>
                      </span>
                    </div>
                    <div className="estimations2 w-[120%]"></div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <h4 className="text-xs mb-2">After</h4>
                    <img
                      src={
                        state?.gender === "male" ? MaleAfterImage : AfterImage
                      }
                      alt="Body After"
                    />
                  </div>
                </div>
                <p className="text-[10px] font-bold mt-2">
                  This is not a customized graph and results may differ.
                  Estimation made on Keto Diet, UAB calculation based in part on
                  study Michalczyk et al. published 22 June 2020
                </p>
              </div>

              <div className="bg-white rounded-lg shadow-sm max-w-lg py-4">
                <h2 className="text-sm font-bold bg-[--bg2] inline-block px-2 rounded-lg mx-4">
                  Unique food variations for you
                </h2>
                <div className="py-6">
                  <img src={foodPlate} alt="food plate" />
                </div>
                <div className="text-center">
                  <h3 className="text-3xl font-bold">1000+</h3>
                  <p className="text-xs">Recipies</p>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="bg-[--bg3] relative overflow-hidden">
          <div className="section-wrapper px-4 py-10 lg:py-12">
            <h2 className="text-xl sm:text-2xl text-center font-bold">
              By Choosing KetoSlim you get
            </h2>
            <div className="grid lg:grid-cols-2 pt-10 lg:py-10 gap-10 lg:gap-5">
              <div className="flex flex-col gap-5">
                {youGet.map((item) => (
                  <div key={item.title} className="flex gap-2 flex-col">
                    <div className="flex gap-[6px]">
                      <div>
                        <img
                          className="transform translate-y-[3px]"
                          src={checkedIcon}
                          alt="checked"
                        />
                      </div>
                      <h4 className="font-bold mb-2">{item.title}</h4>
                    </div>
                    <p className="text-xs font-medium">{item.description}</p>
                  </div>
                ))}
              </div>

              <div className="flex flex-col justify-center">
                <img
                  className="transform lg:translate-y-10 lg:translate-x-24 lg:scale-[1.3]"
                  src={plan_view}
                  alt="keto-plan-phone"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section-wrapper px-4 py-10">
          <h2 className="text-2xl text-center font-bold">
            Keto Diet was covered in
          </h2>
          <div className="flex justify-center gap-2 lg:justify-between items-center my-6 flex-wrap">
            <img src={logo2} alt="logo" />
            <img src={logo1} alt="logo" />
            <img src={logo3} alt="logo" />
          </div>
        </section>

        <section className="pb-20 mdx:mx-4">
          <div className="section-wrapper px-4 py-12 bg-[--bg3] rounded-lg border-[1px] border-gray-200 text-center">
            <div className="flex flex-col gap-4 max-w-md mx-auto">
              <h4 className="uppercase text-xs font-bold">
                A word form our advisor
              </h4>
              <h2 className="text-3xl font-bold">
                Keto is the magic pill that gets your body healthy
              </h2>
              <p className="text-[12px] mt-4">
                The ketogenic lifestyle gets to the bottom of almost every
                chronic disease known to mankind. Keto does not treat these
                diseases one by one, as conventional doctors do, but gets to the
                root of the problem – insulin resistance.
                <br />
                <br />
                When you have insulin levels under control, you reduce
                inflammation and therefore the risk of chronic disease.
              </p>
              <div className="flex justify-center">
                <button
                  onClick={getYourPlan}
                  className="bg-red-500 hover:bg-red-600 transition-all rounded-lg max-w-[16rem] py-2.5 w-full text-white font-medium text-sm"
                >
                  Get your plan
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="p-8 text-gray-200 text-center text-xs sm:text-sm">
        Copyright ® 2023 KetoSlim Diet. All Rights Reserved.
      </div> */}
      </div>
    )
  );
}

const youGet = [
  {
    title: "Personalized meal plan",
    description:
      "Breakfast, lunch, dinner, even snacks – it's all planned for you. You don't have to spend time tracking calories or counting carbs because all meals are already customized for instant fat burning.",
  },
  {
    title: "Convenient grocery lists",
    description: `With easy, quick-to-prepare recipes, you'll never have to wonder what's for dinner again. Each dish comes with a list of inexpensive ingredients you can find at your local grocery store. Open your plan on your phone or online, print the list, and stock up for the weeks ahead.`,
  },
  {
    title: "24/7 support",
    description: `Whether you use your plan on your phone or online, you'll track your steps, water consumption, calories and weight every day, giving you valuable insight into your progress. Your plan will help you make informed decisions and develop new habits.`,
  },
];

export default NewResult2;
