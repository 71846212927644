import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ProgressBar from "./ProgressBars";
import LogoHeader from "./LogoHeader";
import { API_PATH, FUNNEL_TYPE } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  ingredientList,
  selectUserData,
} from "../../redux/quizSlice/quizSlice";

const Header = () => {
  const state = useSelector(selectUserData);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const funnelType = FUNNEL_TYPE;
  const handlePrev = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const StepCount = () => {
    if (location.pathname === `/${funnelType}/weight-loss-goal`) {
      return 1;
    } else if (location.pathname === `/${funnelType}/step-gender`) {
      return 2;
    } else if (location.pathname === `/${funnelType}/step-identify`) {
      return 3;
    } else if (location.pathname === `/${funnelType}/step-your-age`) {
      return 4;
    } else if (location.pathname === `/${funnelType}/step-measurement`) {
      return 5;
    } else if (location.pathname === `/${funnelType}/step-reach-weight`) {
      return 6;
    } else if (location.pathname === `/${funnelType}/step-body-type`) {
      return 7;
    } else if (location.pathname === `/${funnelType}/step-ideal-time`) {
      return 8;
    } else if (location.pathname === `/${funnelType}/step-event`) {
      return 9;
    } else if (location.pathname === `/${funnelType}/step-event-date`) {
      return 10;
    } else if (location.pathname === `/${funnelType}/step-typical-day`) {
      return 11;
    } else if (location.pathname === `/${funnelType}/step-back-issue`) {
      return 12;
    } else if (location.pathname === `/${funnelType}/step-stomach-discomfort`) {
      return 13;
    } else if (location.pathname === `/${funnelType}/step-tend-to-do`) {
      return 14;
    } else if (location.pathname === `/${funnelType}/step-emotional-comfort`) {
      return 15;
    } else if (location.pathname === `/${funnelType}/step-old-habit`) {
      return 16;
    } else if (location.pathname === `/${funnelType}/step-multitasking`) {
      return 17;
    } else if (location.pathname === `/${funnelType}/step-clear-plate`) {
      return 18;
    } else if (location.pathname === `/${funnelType}/step-workout`) {
      return 19;
    } else if (location.pathname === `/${funnelType}/step-consume-alchohol`) {
      return 20;
    } else if (location.pathname === `/${funnelType}/step-how-feeling`) {
      return 21;
    } else if (location.pathname === `/${funnelType}/step-sleep-info`) {
      return 22;
    } else if (location.pathname === `/${funnelType}/step-water-info`) {
      return 23;
    } else if (location.pathname === `/${funnelType}/step-meal-prepare`) {
      return 24;
    } else if (location.pathname === `/${funnelType}/step-kitchen-info`) {
      return 25;
    } else if (location.pathname === `/${funnelType}/step-vegetable`) {
      return 26;
    } else if (location.pathname === `/${funnelType}/step-product`) {
      return 27;
    } else if (location.pathname === `/${funnelType}/step-meat`) {
      return 28;
    }
  };
  const fetchQuizIngredients = async () => {
    try {
      const url = `${API_PATH}/quiz/step-ingredients`;
      const response = await axios.get(url);
      if (response?.data?.success) {
        const list = response?.data?.data;
        if (list?.length) {
          let meatArr = [];
          let vegetableArr = [];
          let productArr = [];
          // eslint-disable-next-line array-callback-return
          list.map((item) => {
            if (item?.ingredient_category_id.toString() === "1") {
              vegetableArr.push(item);
            } else if (item?.ingredient_category_id.toString() === "2") {
              meatArr.push(item);
            } else if (item?.ingredient_category_id.toString() === "3") {
              productArr.push(item);
            }
          });
          dispatch(
            ingredientList({
              meatArr,
              vegetableArr,
              productArr,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(state);
    if (
      state?.vegetableList?.length === 0 ||
      state?.productList?.length === 0 ||
      state?.meatList?.length === 0
    ) {
      console.log("dddd");
      fetchQuizIngredients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LogoHeader />
      <div className="w-full desktop:mt-12 grid grid-cols-2 mobile:mt-4">
        <div
          onClick={(e) => handlePrev(e)}
          className="w-max cursor-pointer text-gray-900 dark:text-slate-300 font-medium font-Montserrat flex items-center justify-start borde-none py-1 px-1"
        >
          {
            StepCount() !== 1 && (
              <React.Fragment>
                <i className="fa-solid fa-chevron-left mr-1 text-xs"></i>
                <p className="text-sm">Previous step</p>
              </React.Fragment>
            )
          }
        </div>
        <div className="w-full text-gray-500 dark:text-slate-400 font-medium font-Montserrat flex items-center justify-end">
          <p className="text-sm font-normal">{StepCount()}/28</p>
        </div>
      </div>

      <ProgressBar />
    </>
  );
};

export default Header;
