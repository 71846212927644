import { LazyLoadImage } from "react-lazy-load-image-component";
import AppContainer from "../../../componant/private_components/AppContainer";

import BeforeImage from "./assets/BeforeImage.jpg";
import AfterImage from "./assets/AfterImage.jpg";
import heartFood from "./assets/heartFood.jpg";
import marker from "./assets/polygon.svg";
import checkedIcon from "./assets/checked.svg";
import egg from "./assets/egg.png";
import logo1 from "./assets/logo1.png";
import logo2 from "./assets/logo2.png";
import logo3 from "./assets/logo3.png";
import ketoPhone from "./assets/ketoPhone.png";
import water from "./assets/water.png";
import fullGalss from "./assets/full-glass.png";
import emptyGlass from "./assets/empty-glass.png";

import "./style.css";

const WeightSeries = ({ target }) => {
  return (
    <div className="flex justify-between">
      <span className="text-sm text-gray-200">{target - 3}</span>
      <span className="text-sm text-gray-400">{target - 2}</span>
      <span className="text-sm text-gray-600">{target - 1}</span>
      <span className="text-teal-500 text-2xl font-bold transform translate-y-[-7px]">
        {target} <span className="font-medium">lb</span>
      </span>
      <span className="text-sm text-gray-600">{target + 1}</span>
      <span className="text-sm text-gray-400">{target + 2}</span>
      <span className="text-sm text-gray-200">{target + 3}</span>
    </div>
  );
};

const NewResult = () => {
  return (
    <div className="bg-white text-black">
      <div className="wrapper">
        <LazyLoadImage
          src="https://dev.myketoslim.com/images/keto-logo.png"
          alt="Keto Slim"
          className="w-[240px] p-5 mx-auto"
        />
      </div>

      <section className="section-bg1 relative overflow-hidden">
        <div className="section-wrapper">
          <div className="grid lg:grid-cols-2 p-4 gap-5 lg:mt-10 justify-center">
            <div className="min-h-[320px] flex flex-col justify-center">
              <h1 className="text-5xl font-extralight">Get your</h1>
              <h1 className="text-red-600 text-5xl font-semibold">
                personalized
              </h1>
              <h1 className="text-4xl font-bold">Keto Diet</h1>
              <p className="text-xs font-bold mt-2">
                Based on your profile, you will be..
              </p>
            </div>

            <div className="p-5 bg-white rounded-lg border-[1px] border-gray-300 drop-shadow-2xl max-w-lg">
              <WeightSeries target={122} />
              <p className="text-center text-teal-500 text-xs font-medium transform -translate-y-2">
                By September 2023
              </p>
              <div className="bg-teal-400/20 mt-2 mb-4 rounded-md relative">
                <div className="flex h-full justify-around absolute w-full z-[1]">
                  <div className="h-full border-l-[1px] border-gray-200/60"></div>
                  <div className="h-full border-l-[1px] border-gray-200/60"></div>
                  <div className="h-full border-l-[1px] border-gray-200/60"></div>
                </div>
                <svg
                  className="relative z-10"
                  width="100%"
                  height="100%"
                  viewBox="0 0 464 177"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 2C13.4184 2 142.77 84.6036 156.169 84.6036C169.568 84.6036 374.855 136.036 388.254 136.036C401.652 136.036 462 175 462 175"
                    stroke="#13BBA0"
                    strokeWidth="4"
                  />
                </svg>
                <div className="bg-red-600 w-3 h-3 border-2 border-white rounded-full left-[32%] top-[42%] absolute z-10"></div>
                <div className="text-[10px] font-medium bg-white inline-block rounded-md p-1 left-[35%] top-[20%] sm:top-[30%] absolute z-10">
                  Jul 138 lb
                </div>
                <div className="bg-red-600 w-3 h-3 border-2 border-white rounded-full left-[83%] top-[72%] absolute z-10"></div>
                <div className="text-[10px] font-medium bg-white inline-block rounded-md p-1 left-[78%] top-[45%] sm:top-[55%] absolute z-10">
                  Sep 122 lb
                </div>
              </div>
              <div className="text-[10px] text-gray-200">
                This graph is not personalized. Prior to initiating any weight
                loss, diet, exercise, or other health program, we strongly
                advise you to seek guidance from your doctor.
              </div>
            </div>

            <div className="p-4 bg-white rounded-lg border-[1px] border-gray-300 drop-shadow-2xl max-w-lg">
              <h3 className="mb-1 font-bold">Body Change Estimations</h3>
              <div className="grid grid-cols-3">
                <div>
                  <h4 className="text-gray-500">Before</h4>
                  <img src={BeforeImage} alt="Body Before" />
                </div>
                <div className="text-center flex flex-col justify-end gap-2">
                  <div className="estimations mb-2 relative">
                    <span className="inline-block bg-white">
                      Waist <span className="text-teal-600 ">-14%</span>
                    </span>
                  </div>
                  <div className="estimations mb-8 relative">
                    <span className="inline-block bg-white">
                      Hips <span className="text-teal-600 ">-10%</span>
                    </span>
                  </div>
                  <div className="estimations mb-8 relative">
                    <span className="inline-block bg-white">
                      Legs <span className="text-teal-600 ">-10%</span>
                    </span>
                  </div>
                </div>
                <div className="ml-auto">
                  <h4 className="text-gray-500">After</h4>
                  <img src={AfterImage} alt="Body After" />
                </div>
              </div>
            </div>
            <div className="p-5 bg-white rounded-lg border-[1px] border-gray-300 drop-shadow-2xl max-w-lg flex flex-col gap-8">
              <h3 className="mb-1 font-bold">Weight Loss</h3>
              <div className="flex justify-between">
                {[1, 2, 3, 4, 5, 6, 7].map((n) => (
                  <div className="text-center">
                    <span className="text-gray-300 text-xs">{n}</span>
                    <span className="font-bold text-gray-400 block text-sm">
                      Mon
                    </span>
                  </div>
                ))}
              </div>
              <div className="text-center">
                <h6 className="text-3xl text-teal-500 font-bold">
                  -1.7 <span>lb</span>
                </h6>
                <p className="text-gray-200 text-xs">After first week</p>
              </div>
            </div>
          </div>

          <div className="flex justify-center py-8">
            <button className="bg-red-600 rounded-xl p-2 px-10 text-white font-medium">
              Get your plan
            </button>
          </div>
        </div>
      </section>

      <section className="section-wrapper px-4">
        <h2 className="font-bold text-2xl pb-2">Personal Summary</h2>
        <div className="p-4 bg-white rounded-lg border-[1px] border-gray-300 drop-shadow-2xl my-4">
          <h3 className="text-sm font-bold pb-2">Current BMI</h3>
          <h4 className="text-3xl text-teal-600 font-bold">24.26 BMI</h4>
          <div className="gradient w-full h-[18px] rounded-xl mb-5">
            <span className="absolute left-0 top-[120%] text-xs font-thin">
              Underweight
            </span>
            <img
              src={marker}
              alt="marker"
              className="w-[28px] absolute left-1/2 top-[85%]"
            />
            <span className="absolute right-0 top-[120%] text-xs font-thin">
              Obese
            </span>
          </div>
          <div className="pt-5 pb-3">
            <h3 className="text-sm font-bold pb-1 text-teal-600">
              Slightly Overweight
            </h3>
            <p className="text-xs font-light text-gray-700">
              BMI stands for Body Mass Index, a numerical measure that assesses
              a person's body fat based on their height and weight.
            </p>
          </div>
        </div>
      </section>

      <section className="relative section-bg2 overflow-hidden">
        <section className="section-wrapper pb-5 px-4 grid md:grid-cols-2 gap-4">
          <div className="p-4 bg-white rounded-lg border-[1px] border-gray-300 drop-shadow-2xl">
            <div className="flex gap-4">
              <div className="bg-gray-100">
                <img src={egg} alt="food" />
              </div>
              <div>
                <h5 className="text-sm font-bold">Daily Calorie Intake</h5>
                <p className="text-teal-600 text-2xl font-bold leading-4 mt-2">
                  1715 <span>kcal</span>
                </p>
                <p className="text-gray-500 font-light text-xs">RECOMMENDED</p>
              </div>
            </div>
            <div className="gradient w-full h-[10px] rounded-xl mb-5 mt-2">
              <span className="absolute left-0 top-[120%] text-xs font-thin">
                1000 kcal
              </span>
              <img
                src={marker}
                alt="marker"
                className="w-[28px] absolute left-1/2 top-[70%]"
              />
              <span className="absolute right-0 top-[120%] text-xs font-thin">
                5000 kcal
              </span>
            </div>
          </div>
          <div className="p-4 bg-white rounded-lg border-[1px] border-gray-300 drop-shadow-2xl">
            <div className="flex gap-4">
              <div className="bg-gray-100">
                <img className="p-4 w-[82px]" src={water} alt="food" />
              </div>
              <div>
                <h5 className="text-sm font-bold">Daily Water Intake</h5>
                <p className="text-teal-600 text-2xl font-bold leading-4 mt-2">
                  63.8 <span>oz</span>
                </p>
                <p className="text-gray-500 font-light text-xs">RECOMMENDED</p>
              </div>
            </div>
            <div className="flex gap-2 mt-4 justify-between items-center">
              {[1, 2, 3, 4, 5, 6].map((g) => (
                <img
                  className="w-[20px] md:w-[25px]"
                  src={fullGalss}
                  alt="full-glass"
                />
              ))}
              <img
                className="w-[20px] md:w-[25px]"
                src={emptyGlass}
                alt="empty-glass"
              />
              <img
                className="w-[20px] md:w-[25px]"
                ß
                src={emptyGlass}
                alt="empty-glass"
              />
              <span className="text-gray-300 text-xs inline-block ml-1">
                Glasses
              </span>
            </div>
          </div>
        </section>
        <div className="section-wrapper px-4 py-20">
          <h2 className="text-xl sm:text-2xl text-center font-bold">
            By Choosing KetoSlim you get
          </h2>
          <div className="grid lg:grid-cols-2 py-10 gap-5">
            <div className="flex flex-col gap-5">
              {youGet.map((item) => (
                <div className="flex gap-2 items-start">
                  <img className="w-[35px]" src={checkedIcon} alt="checked" />
                  <div>
                    <h4 className="text-red-500 font-bold text-lg mb-2">
                      {item.title}
                    </h4>
                    <p className="text-xs lg:text-sm">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col justify-center">
              <img
                className="transform translate-x-10"
                src={ketoPhone}
                alt="keto-plan-phone"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-wrapper px-4 py-10">
        <h2 className="text-2xl text-center font-bold">
          Keto Diet was covered in
        </h2>
        <div className="flex justify-center gap-2 lg:justify-between items-center my-6 flex-wrap">
          <img src={logo2} alt="logo" />
          <img src={logo1} alt="logo" />
          <img src={logo3} alt="logo" />
        </div>
      </section>

      <section className="bg-[#FFE3E1]">
        <div className="section-wrapper px-4 py-12">
          <div className="grid lg:grid-cols-2 gap-4">
            <div>
              <img src={heartFood} alt="" />
            </div>
            <div className="flex flex-col gap-5">
              <h4 className="text-3xl font-bold">
                Keto is the master key to unlocking a healthier{" "}
                <span className="text-teal-500 text-3xl">you</span>
              </h4>
              <p className="text-sm">
                Keto is like a health superhero, helping your body to fight back
                and get strong. Instead of only tackling one problem at a time,
                keto goes after the big boss - insulin resistance - the root
                cause of many chronic diseases.
              </p>
              <p className="text-sm">
                When you manage to keep your insulin levels in balance, you can
                lower body inflammation and cut down the risk of getting
                long-term illnesses. Simply put, keto is not just about losing
                weight, it's your gold ticket to a healthier life.
              </p>
              <div>
                <button className="bg-red-600 rounded-xl p-2 px-10 text-white font-medium">
                  Get your plan
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="p-8 text-gray-200 text-center text-xs sm:text-sm">
        Copyright ® 2023 KetoSlim Diet. All Rights Reserved.
      </div>
    </div>
  );
};

const youGet = [
  {
    title: "Personalized meal plan",
    description:
      "Breakfast, lunch, dinner, and even those in-between snacks – it's all laid out for you in your personalized plan. No need to bother with tracking calories or tallying up carbs, because every meal is already designed for on-the-spot fat burning. It's as if you've got your very own personal nutritionist who's also a master in the art of healthy weight loss!",
  },
  {
    title: "Convenient grocery lists",
    description: `With our simple, fast-to-fix recipes, the "what's for dinner?" question will be a thing of the past. Each recipe comes with a list of wallet-friendly ingredients available at your neighborhood supermarket. Just open your plan, print out the list for the week, and you're ready to go with only what you need. It's meal planning made easy, just for you!`,
  },
  {
    title: "24/7 support",
    description: `With round-the-clock support, our team is here for you every step of your keto journey. Whether it's a question about your diet, needing some tips, or just a boost of motivation to keep you on track, we've got you covered. Day or night, our dedicated team works tirelessly to provide you with the guidance and encouragement you need. Remember, you're never alone in this journey; we're your support group and cheerleaders, here to help you every step of the way.`,
  },
];

export default NewResult;
