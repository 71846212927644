import { useEffect, useMemo, useState } from "react";
import moment from 'moment';
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/quizSlice/quizSlice";

const useGoal = () => {
  const [goal, setGoal] = useState({
    date: {month: ""}, 
    target: {}, 
    loss: "",
    current: {},
    currentUnit: 'lb',
    targetUnit: 'lb'
  });
  const state = useSelector(selectUserData);

  useEffect(() => {
    const weighloss = Math.abs(state?.measurements.weight - state?.reachWeight.weight);

    const totalDay = (weighloss / 2) * 7;

    let d = moment(new Date(), "DD-MM-YYYY").add(totalDay, "days");

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = months[parseInt(d.format("MM")) - 1];

    const values = {
      month,
      noYr: `${month} ${d.format("DD")}`,
      withYr: `${month} ${d.format("DD")}, ${d.format("YYYY")}`,
    };

    const currentUnit = state?.measurements.unitType === "LbFt" ? "lb" : "kg";
    const targetUnit = state?.reachWeight.unitType === "LbFt" ? "lb" : "kg";

    return setGoal({date: values, target: state?.reachWeight, loss: weighloss, current: state?.measurements, currentUnit, targetUnit});
  }, [state]);

  return goal;
}

export default useGoal;