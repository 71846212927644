import { Outlet, useLocation } from "react-router-dom";
import Header from "../quiz5/Header";
import { selectUserData } from "../../redux/quizSlice/quizSlice";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

export default function QuizLayout() {
  const state = useSelector(selectUserData);
  const gender = state?.gender;
  const isFemale = gender === "female";
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.25,
  };

  return (
    <div className={isFemale ? "bg-[#f8f4f4]" : "bg-[#12241f]"}>
      <motion.div
        key={location.pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <div className="relative flex flex-col min-h-screen max-w-sm mx-auto py-5 gap-6">
          <Header />

          <Outlet />
        </div>
      </motion.div>
    </div>
  );
}
