export function calculateWeightLoss(currentWeight, targetWeight, unit) {
  const totalWeightLoss = currentWeight - targetWeight;
  const initialValueLb = unit === "lb" ? 3.3 : 3.3 / 2.204;
  const weightLossPerUnit = totalWeightLoss / (unit === "lb" ? 10 : 10 / 2.204);
  const ratePerWeek = weightLossPerUnit * 0.22;

  const firstWeek = initialValueLb + ratePerWeek;

  const weightlossInADay = firstWeek / 7;

  const weightAfterFirstMonth = currentWeight - firstWeek * 4;

  const daysNeeded = totalWeightLoss / weightlossInADay;

  const currentDate = new Date();

  const nextMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );

  const targetDate = new Date(
    currentDate.getTime() + daysNeeded * 24 * 60 * 60 * 1000
  );

  const targetMonth = targetDate.toLocaleString("default", { month: "long" });
  const targetMonthShort = targetDate.toLocaleString("default", {
    month: "short",
  });
  const targetYear = targetDate.getFullYear();

  return {
    daysNeeded: Math.ceil(daysNeeded),
    firstWeek: Number(firstWeek.toFixed(1)),
    weightAfterFirstMonth: Math.ceil(weightAfterFirstMonth),
    nextMonthShort: nextMonth.toLocaleString("default", { month: "short" }),
    targetMonth,
    targetMonthShort,
    targetYear,
  };
}
