import Route from "./public-route";
import PrivateRoute from "./private-route";
import { Layout } from "../componant/private_components/Layout";

export const routes = () => {
  return [
    {
      children: [...Route()],
    },

    {
      element: <Layout />,
      children: [...PrivateRoute()],
    },
  ];
};
