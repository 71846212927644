import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LogoHeader = () => {
  return (
    <div className="text-5xl uppercase flex w-full justify-center mobile:text-3xl desktop:text-5xl min-h-[35px] desktop:w-60 mobile:w-48 mx-auto mt-0">
      <LazyLoadImage
        src="https://dev.myketoslim.com/images/keto-logo.png"
        alt="Keto Slim"
        className="w-full"
      />
    </div>
  );
};

export default LogoHeader;
