import { useRoutes } from "react-router-dom";
import { Fragment, Suspense, useEffect } from "react";
import { routes } from "./route-control";
import { ToastContainer } from "react-toastify";
import Scroll from "./componant/ketoboost/Scroll";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { selectUserData } from "./redux/quizSlice/quizSlice";

function RouteLayout({ path }) {
  const element = useRoutes(path);
  return element;
}

function App() {
  const state = useSelector(selectUserData);
  const gender = state?.gender;

  // useEffect(() => {
  //   // Initialize chargebee.js
  //   window.Chargebee.init({
  //     site: "keto",
  //     publishableKey: "live_fZKGoN5zzqnu8QRcEaoy1nuoYVh0if7G",
  //   });
  // }, []);

  return (
    <Fragment>
      <Suspense
        async
        fallback={
          <div
            className={clsx(
              gender
                ? gender === "female"
                  ? "bg-[#f8f4f4]"
                  : "bg-[#12241f]"
                : "",
              "h-screen flex items-center justify-center"
            )}
          >
            <span className="loading loading-spinner text-accent loading-lg"></span>
          </div>
        }
      >
        <RouteLayout path={routes()} />
      </Suspense>
      <ToastContainer />
    </Fragment>
  );
}
export default App;
