import { useState, useEffect } from "react";
import {
  PayPalScriptProvider,
  BraintreePayPalButtons,
} from "@paypal/react-paypal-js";
import { API_PATH, Loader, PAYPAL_CLIENT_ID } from '../../utils';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { planDatas, selectUserData } from "../../redux/quizSlice/quizSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import accessRoute from "../../route-control/path/public-path";
import { LockClosedIcon } from "@heroicons/react/24/solid";

// This values are the props in the UI
const style = { "label": "paypal", "layout": "vertical" };

export default function BraintreeButton() {
  const [clientToken, setClientToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const state = useSelector(selectUserData);
  const planData = state?.planData;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const url = `${API_PATH}/braintree/client_token`;
      const { data } = await axios.get(url);
      setClientToken(data?.client_token || "");
    })();
  }, []);

  const handleClick = ()=>{
    window.dataLayer.push({
      event: "initiated_paypal",
      page: {
        url: window.location.href,
        title: window.location.pathname,
      },
    });
  }

  const onApprove = async (payload) => {
    console.log({plan_price: planData?.plan_price || ""})
    setLoading(true);
    const requestData = {
      email: state.email,
      session_id: state?.planData?.session_id || "",
      paymentMethodNonce: payload.nonce
    }
    try {
      const response = await axios.post(`${API_PATH}/braintree/create_subscription`, requestData);
      console.log(response);

      if (!response.data.success) throw Error("Payment Failed");
      else {
        const updatedState = {
          ...planData,
          txnId: response.data.data.txnId
        }

        dispatch(planDatas(updatedState))
        // navigate to ketoboost
        navigate(accessRoute.KetoBoost.path);
      }
    } catch (e) {
      console.log(e);
      toast(e.message, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="relative">
      <button
        className={`bg-teal-500 text-white p-[12px] rounded-xl font-semibold disabled:bg-gray-300 text-sm mt-3 flex justify-center gap-2 w-full cursor-pointer`}
      >
        {loading ?
          Loader :
          <>
            <LockClosedIcon className="w-4" /> Confirm purchase
          </>
        }
      </button>

      {clientToken ? (

        <PayPalScriptProvider
          options={{
            clientId: PAYPAL_CLIENT_ID,
            components: "buttons",
            // dataUserIdToken: "your-tokenization-key-here",
            dataClientToken: clientToken,
            intent: "tokenize",
            vault: true,
            currency: 'USD',
          }}
        >
          <BraintreePayPalButtons
            style={style}
            className="absolute left-0 top-0 w-full h-full opacity-[0.01]"
            disabled={false}
            fundingSource="paypal" // Available values are: ["paypal", "card", "credit", "paylater", "venmo"]
            forceReRender={[style]}
            createBillingAgreement={function (data, actions) {
              return actions.braintree.createPayment({
                // Required
                flow: "vault",
                amount: planData?.plan_price || "",
                currency: 'USD',
                requestBillingAgreement: true,
                intent: 'tokenize'
                // The following are optional params
                // billingAgreementDescription: "Your agreement description",
              });
            }}
            onApprove={function (data, actions) {
              return actions.braintree
                .tokenizePayment(data)
                .then((payload) => {
                  onApprove(payload)
                });
            }}
            onError={(err)=>{
              console.log(err)
            }}
            onInit={()=>{
              setLoading(false)
            }}
            onClick={handleClick}
          />
        </PayPalScriptProvider>
      ) : (
        null
      )}
    </div>
  );
}