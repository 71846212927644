import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FUNNEL_TYPE } from "../../utils";
import { selectUserData, medicalStep } from "../../redux/quizSlice/quizSlice";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { MdArrowForwardIos } from "react-icons/md";
import Header from "./Header";
import { FaCheckCircle } from "react-icons/fa";
import { AccessControl } from "../../route-control/public-route";

const Step8 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(selectUserData);
  const gender = state?.gender;

  const [medicalList, setMedicalList] = useState(state?.medicalList ?? []);
  const [errorMessage, setErrorMessage] = useState("");

  const data = [
    {
      title: "Diabetes (any stage)",
      value: "diabetes",
    },
    {
      title: "Kidney disease or issues",
      value: "kidney",
    },
    {
      title: "Liver disease or issues",
      value: "liver",
    },
    {
      title: "Pancreas disease or issues",
      value: "pancreas",
    },
    {
      title: "I am recovering from surgery",
      value: "surgery",
    },
    {
      title: "Mental health issues",
      value: "mental",
    },
    {
      title: "Cancer",
      value: "cancer",
    },
    {
      title: "Heart disease or stroke",
      value: "heart_disease",
    },
    {
      title: "High blood pressure",
      value: "high_blood_pressure",
    },
    {
      title: "Thyroid issues",
      value: "thyroid",
    },
    {
      title: "Other health issues",
      value: "other",
    },
    {
      title: "None of the above",
      value: "none",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleForm = (medical) => {
    setErrorMessage("");
    if (medicalList.includes(medical)) {
      if (medical !== "none") {
        setMedicalList(medicalList.filter((item) => item !== medical));
      } else {
        setMedicalList([]);
      }
    } else {
      if (medical !== "none") {
        setMedicalList([
          ...medicalList.filter((item) => item !== "none"),
          medical,
        ]);
      } else {
        setMedicalList([medical]);
      }
    }
  };

  const goBack = () => {
    setTimeout(() => {
      navigate(AccessControl.Step7Willingness.path);
    }, 250);
  };

  const submitList = () => {
    if (medicalList.length > 0) {
      dispatch(medicalStep(medicalList));
      setTimeout(() => {
        navigate(AccessControl.Step9Measurements.path);
      }, 250);
    } else {
      setErrorMessage("Please select an answer");
    }
  };

  return (
    <div className="py-6 px-4 max-w-sm mx-auto">
      <Header goBack={goBack} step={8} />

      <section className="mt-16 desktop1600:mt-10 smx:mt-5">
        <h2 className="text-start font-semibold text-gray-800 dark:text-white font-Montserrat text-3xl desktop1600:text-2xl smx:text-[18px] smx:leading-normal">
          Does any of the following apply for you?
        </h2>

        <div className="mb-6 mt-8 smx:mt-3 flex flex-col gap-2.5 sm:gap-3">
          {data.map((item) => (
            <button
              role="checkbox"
              aria-checked={!!medicalList.find((med) => med === item.value)}
              type="button"
              onClick={() => handleForm(item.value)}
              key={item.value}
              className={clsx(
                medicalList && medicalList.find((med) => med === item.value)
                  ? "border-teal-500 bg-teal-500 text-white"
                  : "bg-gray-100 text-gray-600 hover:border-teal-500 focus:border-teal-500 border-gray-100 hover:bg-teal-100 focus:bg-teal-100 hover:text-gray-600",
                "focus:border-2 border-2 transition-all outline-none",
                "w-full px-4 py-2 sm:py-2.5 smx:px-3",
                "text-left rounded-md font-Montserrat font-medium text-sm sm:text-base",
                "flex items-center justify-between group"
              )}
            >
              {item.title}

              {medicalList && medicalList.find((med) => med === item.value) && (
                <FaCheckCircle size={16} />
              )}
            </button>
          ))}
        </div>

        {errorMessage && (
          <p className="mb-3 px-4 py-1.5 w-full rounded-lg bg-red-900 text-white font-medium text-center">
            {errorMessage}
          </p>
        )}

        <button
          onClick={submitList}
          className={clsx(
            "w-full rounded-full p-2 h-[40px] md:h-[46px] font-medium font-Montserrat text-white text-sm md:text-base",
            "relative flex items-center gap-3 text-center justify-center",
            "group bg-[#13BBA0] hover:bg-teal-600 transition-all border-2 border-[#13BBA0]/50"
          )}
          type="button"
        >
          Next
          <MdArrowForwardIos
            size={14}
            className={clsx(
              "absolute top-1/2 right-4 -translate-y-1/2",
              "group-hover:translate-x-0.5 transition-all"
            )}
          />
        </button>
      </section>
    </div>
  );
};

export default Step8;
