import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { selectUserData, meatStep } from "../../redux/quizSlice/quizSlice";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { MdArrowForwardIos } from "react-icons/md";
import Header from "./Header";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { AccessControl } from "../../route-control/public-route";

export const meatListData = [
  {
    title: "I eat all meats",
    value: "all",
  },
  {
    title: "Poultry",
    value: "poultry",
  },
  {
    title: "Pork",
    value: "pork",
  },
  {
    title: "Beef",
    value: "beef",
  },
  {
    title: "Fish",
    value: "fish",
  },
  {
    title: "Lamb",
    value: "lamb",
  },
  {
    title: "Veal",
    value: "veal",
  },
  { title: "I am vegetarian", value: "none" },
];

const Step4 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(selectUserData);

  const [meatList, setMeatList] = useState(state?.ketoMeatList ?? []);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleForm = (meat) => {
    setErrorMessage("");
    if (meatList.includes(meat)) {
      if (meat !== "all" && meat !== "none") {
        setMeatList(meatList.filter((item) => item !== meat));
      } else {
        setMeatList([]);
      }
    } else {
      if (meat !== "all" && meat !== "none") {
        setMeatList([
          ...meatList.filter((item) => item !== "all" && item !== "none"),
          meat,
        ]);
      } else {
        setMeatList([meat]);
      }
    }
  };

  const goBack = () => {
    setTimeout(() => {
      navigate(AccessControl.Step3Time.path);
    }, 250);
  };

  const submitList = () => {
    if (meatList.length > 0) {
      const apiMeatList = state?.apiMeatList;
      if (apiMeatList && apiMeatList.length > 0) {
        const excludedMeatIds = apiMeatList
          .filter((item) => {
            if (meatList.includes("all")) {
              return null;
            } else if (meatList.includes("none")) {
              return item;
            } else {
              if (meatList.includes("poultry")) {
                return [...meatList, "chicken", "turkey"].includes(
                  item.name.toLowerCase()
                );
              } else {
                return meatList.includes(item.name.toLowerCase());
              }
            }
          })
          .map((item) => item.id);

        const includedMeatList = apiMeatList.filter(
          (item) => !excludedMeatIds.includes(item.id)
        );

        const excludedMeatString =
          excludedMeatIds.length > 0
            ? excludedMeatIds.reduce((a, b) => `${a},${b}`)
            : "";

        // dispatch(meatIdString(excludedMeatString));
        // dispatch(meatListSelected(includedMeatList));
      }

      dispatch(meatStep(meatList));
      setTimeout(() => {
        navigate(AccessControl.Step5Products.path);
      }, 250);
    } else {
      setErrorMessage("Please select an answer");
    }
  };

  return (
    <div className="py-6 px-4 max-w-sm mx-auto">
      <Header goBack={goBack} step={4} />

      <section className="mt-16 desktop1600:mt-10 smx:mt-5">
        <h2 className="text-start font-semibold text-gray-800 dark:text-white font-Montserrat text-3xl desktop1600:text-2xl smx:text-[18px] smx:leading-normal">
          Which meats you DON’T LIKE?
        </h2>

        <div className="mb-6 mt-8 smx:mt-3 flex flex-col gap-2.5 sm:gap-3">
          <p className="text-xs mb-3">
            Select all you don't want to be included in your plan.
          </p>

          {meatListData.map((item) => (
            <button
              role="checkbox"
              aria-checked={!!meatList.find((meat) => meat === item.value)}
              type="button"
              onClick={() => handleForm(item.value)}
              key={item.value}
              className={clsx(
                meatList && meatList.find((meat) => meat === item.value)
                  ? item.value === "all" || item.value === "none"
                    ? "border-teal-500 bg-teal-500 text-white"
                    : "border-red-500 bg-red-200 text-gray-600 line-through"
                  : "bg-gray-100 text-gray-600 hover:border-teal-500 focus:border-teal-500 border-gray-100 hover:bg-teal-100 focus:bg-teal-100 hover:text-gray-600",
                "focus:border-2 border-2 transition-all outline-none",
                "w-full px-4 py-2 sm:py-2.5 smx:px-3",
                "text-left rounded-md font-Montserrat font-medium text-sm sm:text-base",
                "flex items-center justify-between group"
              )}
            >
              {item.title}

              {meatList &&
                meatList.find((meat) => meat === item.value) &&
                (item.value === "all" || item.value === "none" ? (
                  <FaCheckCircle size={16} />
                ) : (
                  <MdCancel size={16} className="text-red-500" />
                ))}
            </button>
          ))}
        </div>

        {errorMessage && (
          <p className="mb-3 px-4 py-1.5 w-full rounded-lg bg-red-900 text-white font-medium text-center">
            {errorMessage}
          </p>
        )}

        <button
          onClick={submitList}
          className={clsx(
            "w-full rounded-full p-2 h-[40px] md:h-[46px] font-medium font-Montserrat text-white text-sm md:text-base",
            "relative flex items-center gap-3 text-center justify-center",
            "group bg-[#13BBA0] hover:bg-teal-600 transition-all border-2 border-[#13BBA0]/50"
          )}
          type="button"
        >
          Next
          <MdArrowForwardIos
            size={14}
            className={clsx(
              "absolute top-1/2 right-4 -translate-y-1/2",
              "group-hover:translate-x-0.5 transition-all"
            )}
          />
        </button>
      </section>
    </div>
  );
};

export default Step4;
