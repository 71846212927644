import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/quizSlice/quizSlice";
import clsx from "clsx";

export const Title = ({ className, children }) => {
  const state = useSelector(selectUserData);
  const gender = state?.gender;
  const isFemale = gender === "female";
  return (
    <h2
      className={clsx(
        className,
        isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]",
        "font-semibold font-Montserrat text-lg"
      )}
    >
      {children}
    </h2>
  );
};

export const BodyText = ({ className, children }) => {
  const state = useSelector(selectUserData);
  const gender = state?.gender;
  const isFemale = gender === "female";
  return (
    <p
      className={clsx(
        className,
        isFemale
          ? "text-[#135565] [&>span]:text-[#f75950]"
          : "text-[#cfe8e3] [&>span]:text-[#36bc9f]",
        // : "text-[#adccc6] [&>span]:text-[#36bc9f]",
        "font-Montserrat"
      )}
    >
      {children}
    </p>
  );
};
