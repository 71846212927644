import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FUNNEL_TYPE, Loader } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { kgcm, selectUserData } from "../../../redux/quizSlice/quizSlice";
import clsx from "clsx";
import { MdArrowForwardIos } from "react-icons/md";

const Kgcm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(selectUserData);

  const [loading, setLoading] = useState(false);

  const [inputValues, setInputValues] = useState({
    age: state?.measurements?.age ?? "",
    height: state?.measurements?.height ?? "",
    weight: state?.measurements?.weight ?? "",
    targetWeight: state?.measurements?.targetWeight ?? "",
  });

  const [errorValues, setErrorValues] = useState({
    age: "",
    height: "",
    weight: "",
    targetWeight: "",
  });

  const handleForm = (e) => {
    e.preventDefault();
    const newError = { ...errorValues };

    const age = parseInt(inputValues.age);
    const weight = parseInt(inputValues.weight);
    const height = parseInt(inputValues.height);
    const targetWeight = parseInt(inputValues.targetWeight);

    if (
      age >= 18 &&
      age <= 150 &&
      weight >= 40 &&
      weight <= 179 &&
      height >= 90 &&
      height <= 245 &&
      targetWeight >= 40 &&
      targetWeight <= 179
    ) {
      if (targetWeight === weight) {
        newError.targetWeight = "Target weight cannot equal current weight.";
      } else if (targetWeight > weight) {
        newError.targetWeight = "Target weight cannot exceed current weight..";
      } else {
        newError.age = "";
        newError.height = "";
        newError.weight = "";
        newError.targetWeight = "";
        setLoading(true);
        dispatch(kgcm({ age, height, weight, targetWeight }));
        setTimeout(() => {
          navigate(`/${FUNNEL_TYPE}/step-processing-data`);
          setLoading(false);
        }, 1500);
      }
    } else {
      if (age >= 18 && age <= 150) {
        newError.age = "";
      } else if (age < 18) {
        newError.age = "You must be at least 18 years old to continue";
      } else if (age > 150) {
        newError.age = "Invalid entry. Please check your age.";
      } else {
        newError.age = "This is required.";
      }

      if (height >= 90 && height <= 245) {
        newError.height = "";
      } else if (height < 90) {
        newError.height = "Height must be greater than or equal 90 cm";
      } else if (height > 245) {
        newError.height = "Height must be less than or equal 245 cm";
      } else {
        newError.height = "This is required.";
      }

      if (weight >= 40 && weight <= 179) {
        newError.weight = "";
      } else if (weight < 40) {
        newError.weight = "Weight must be greater than or equal 40 kg";
      } else if (weight > 179) {
        newError.weight = "Weight must be less than or equal 179 kg";
      } else {
        newError.weight = "This is required";
      }

      if (targetWeight >= 40 && targetWeight <= 179) {
        newError.targetWeight = "";
      } else if (targetWeight < 40) {
        newError.targetWeight =
          "Target weight must be greater than or equal 40 kg";
      } else if (targetWeight > 179) {
        newError.targetWeight =
          "Target weight must be greater than or equal 179 kg";
      } else {
        newError.targetWeight = "This is required";
      }
    }

    setErrorValues(newError);
  };

  const fields = {
    age: {
      label: "Age",
      unit: "Age",
    },
    height: {
      label: "Height",
      unit: "cm",
    },
    weight: {
      label: "Current weight",
      unit: "kg",
    },
    targetWeight: {
      label: "Target weight",
      unit: "kg",
    },
  };

  const nextFocus = {
    age: "height",
    height: "weight",
    weight: "targetWeight",
    targetWeight: null,
  };

  const maxLength = {
    age: 2,
    height: 3,
    weight: 3,
    targetWeight: 3,
  };

  const handleInputChange = (name, value) => {
    if (nextFocus[name] && value.length === maxLength[name]) {
      document.getElementById(nextFocus[name]).focus();
    }

    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  return (
    <form className="mt-6 smx:mt-5" onSubmit={(e) => handleForm(e)}>
      <div className="mb-8 smx:mb-5 grid grid-cols-2 gap-x-5">
        {Object.entries(inputValues).map(([name, value]) => (
          <div key={name} className="my-1 col-span-2">
            <label
              htmlFor={name}
              className={clsx(
                "w-full flex justify-start font-Montserrat font-semibold  smx:text-[14px]",
                errorValues[name]
                  ? "text-red-600 dark:text-red-100"
                  : "text-gray-800 dark:text-gray-100"
              )}
            >
              {fields[name].label}{" "}
              {name !== "age" ? `(${fields[name].unit})` : ""}
            </label>
            <input
              id={name}
              onChange={(e) => {
                const value = e.target.value;

                if (!isNaN(value) && value.length < maxLength[name] + 1) {
                  handleInputChange(name, value);
                }
              }}
              onKeyDown={(e) => e.code === "Space" && e.preventDefault()}
              value={value}
              type="text"
              inputMode="numeric"
              placeholder={fields[name].unit}
              className={clsx(
                "my-2 smx:py-1 smx:px-3 px-5 py-2",
                "rounded-md border-2 text-gray-800 ",
                "hover:text-gray-800 focus:border-2 transition-all outline-none ",
                "w-full text-left smx:text-[16px] text-lg font-medium font-Montserrat",
                errorValues[name]
                  ? "bg-red-100 hover:bg-red-100 border-red-400 focus:border-red-600 focus:bg-red-100"
                  : "bg-white border-gray-300 hover:border-teal-500 hover:bg-teal-100 focus:border-teal-500 focus:bg-teal-100"
              )}
            />

            {errorValues[name] && (
              <p className="mb-2 text-xs text-red-600 font-medium w-full justify-start flex font-Montserrat">
                {errorValues[name]}
              </p>
            )}
          </div>
        ))}
      </div>

      <button
        id="submitBtn"
        className={clsx(
          "w-full rounded-full p-2 h-[40px] md:h-[46px] font-medium font-Montserrat text-white text-sm md:text-base",
          "relative flex items-center gap-3 text-center justify-center",
          "group bg-[#13BBA0] hover:bg-teal-600 transition-all border-2 border-[#13BBA0]/50"
        )}
        type="submit"
      >
        {loading ? (
          Loader
        ) : (
          <Fragment>
            Next
            <MdArrowForwardIos
              size={14}
              className={clsx(
                "absolute top-1/2 right-4 -translate-y-1/2",
                "group-hover:translate-x-0.5 transition-all"
              )}
            />
          </Fragment>
        )}
      </button>
    </form>
  );
};

export default Kgcm;
