import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FUNNEL_TYPE } from "../../utils";
import { selectUserData, productStep } from "../../redux/quizSlice/quizSlice";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { MdArrowForwardIos } from "react-icons/md";
import Header from "./Header";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { AccessControl } from "../../route-control/public-route";

export const productListData = [
  {
    title: "I eat them all",
    value: "all",
  },
  {
    title: "Onions",
    value: "onions",
  },
  {
    title: "Mushrooms",
    value: "mushrooms",
  },
  {
    title: "Eggs",
    value: "egg",
  },
  {
    title: "Nuts",
    value: "nuts",
  },
  {
    title: "Cheese",
    value: "cheese",
  },
  {
    title: "Milk",
    value: "milk",
  },
  {
    title: "Avacados",
    value: "avacados",
  },
  {
    title: "Seafood",
    value: "shellfish",
  },
  {
    title: "Olives",
    value: "olives",
  },
  {
    title: "Capers",
    value: "capers",
  },
  {
    title: "Coconut",
    value: "coconut",
  },
  {
    title: "Goat Cheese",
    value: "goat_cheese",
  },
];

const Step5 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(selectUserData);

  const [productList, setProductList] = useState(state?.ketoProductList ?? []);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleForm = (product) => {
    setErrorMessage("");
    if (productList.includes(product)) {
      if (product !== "all") {
        setProductList(productList.filter((item) => item !== product));
      } else {
        setProductList([]);
      }
    } else {
      if (product !== "all") {
        setProductList([
          ...productList.filter((item) => item !== "all"),
          product,
        ]);
      } else {
        setProductList([product]);
      }
    }
  };

  const goBack = () => {
    setTimeout(() => {
      navigate(AccessControl.Step4Meat.path);
    }, 250);
  };

  const submitList = () => {
    if (productList.length > 0) {
      const apiProductList = state?.apiProductList;
      if (apiProductList && apiProductList.length > 0) {
        const excludedProductIds = apiProductList
          .filter((item) => {
            if (productList.includes("all")) {
              return null;
            } else {
              if (productList.includes("cheese")) {
                return [
                  ...productList,
                  "lactose",
                  "cheese",
                  "cottage cheese",
                ].includes(item.name.toLowerCase());
              } else if (productList.includes("milk")) {
                return [...productList, "lactose"].includes(
                  item.name.toLowerCase()
                );
              } else {
                return productList.includes(item.name.toLowerCase());
              }
            }
          })
          .map((item) => item.id);

        const includedProductList = apiProductList.filter(
          (item) => !excludedProductIds.includes(item.id)
        );

        const excludedProductString =
          excludedProductIds.length > 0
            ? excludedProductIds.reduce((a, b) => `${a},${b}`)
            : "";

        // dispatch(productIdString(excludedProductString));
        // dispatch(productListSelected(includedProductList));
      }

      dispatch(productStep(productList));
      setTimeout(() => {
        navigate(AccessControl.Step6Activity.path);
      }, 250);
    } else {
      setErrorMessage("Please select an answer");
    }
  };

  return (
    <div className="py-6 px-4 max-w-sm mx-auto">
      <Header goBack={goBack} step={5} />

      <section className="mt-16 desktop1600:mt-10 smx:mt-5">
        <h2 className="text-start font-semibold text-gray-800 dark:text-white font-Montserrat text-3xl desktop1600:text-2xl smx:text-[18px] smx:leading-normal">
          Which ingredients you DON’T LIKE?
        </h2>

        <div className="mb-6 mt-8 smx:mt-3 flex flex-col gap-2.5 sm:gap-3">
          <p className="text-xs mb-3">
            Select all you don't want to be included in your plan.
          </p>
          {productListData.map((item, index) => (
            <button
              role="checkbox"
              aria-checked={
                !!productList.find((product) => product === item.value)
              }
              type="button"
              onClick={() => handleForm(item.value)}
              key={`${item.value}-${index}`}
              className={clsx(
                productList &&
                  productList.find((product) => product === item.value)
                  ? item.value === "all"
                    ? "border-teal-500 bg-teal-500 text-white"
                    : "border-red-500 bg-red-200 text-gray-600 line-through"
                  : "bg-gray-100 text-gray-600 hover:border-teal-500 focus:border-teal-500 border-gray-100 hover:bg-teal-100 focus:bg-teal-100 hover:text-gray-600",
                "focus:border-2 border-2 transition-all outline-none",
                "w-full px-4 py-2 sm:py-2.5 smx:px-3",
                "text-left rounded-md font-Montserrat font-medium text-sm sm:text-base",
                "flex items-center justify-between group"
              )}
            >
              {item.title}

              {productList &&
                productList.find((product) => product === item.value) &&
                (item.value === "all" ? (
                  <FaCheckCircle size={16} />
                ) : (
                  <MdCancel size={16} className="text-red-500" />
                ))}
            </button>
          ))}
        </div>

        {errorMessage && (
          <p className="mb-3 px-4 py-1.5 w-full rounded-lg bg-red-900 text-white font-medium text-center">
            {errorMessage}
          </p>
        )}

        <button
          onClick={submitList}
          className={clsx(
            "w-full rounded-full p-2 h-[40px] md:h-[46px] font-medium font-Montserrat text-white text-sm md:text-base",
            "relative flex items-center gap-3 text-center justify-center",
            "group bg-[#13BBA0] hover:bg-teal-600 transition-all border-2 border-[#13BBA0]/50"
          )}
          type="button"
        >
          Next
          <MdArrowForwardIos
            size={14}
            className={clsx(
              "absolute top-1/2 right-4 -translate-y-1/2",
              "group-hover:translate-x-0.5 transition-all"
            )}
          />
        </button>
      </section>
    </div>
  );
};

export default Step5;
