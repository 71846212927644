import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FUNNEL_TYPE } from "../../utils";
import { selectUserData, timeStep } from "../../redux/quizSlice/quizSlice";
import { useDispatch, useSelector } from "react-redux";

import clsx from "clsx";
import { MdArrowForwardIos } from "react-icons/md";
import Header from "./Header";
import { AccessControl } from "../../route-control/public-route";

const Step3 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(selectUserData);
  const gender = state?.gender;

  const data = [
    {
      title: "Up to 30 minutes",
      value: "30min",
    },
    {
      title: "Up to 1 hour",
      value: "1hr",
    },
    {
      title: "More than 1 hour",
      value: "1hr+",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleForm = (time) => {
    dispatch(timeStep(time));
    setTimeout(() => {
      navigate(AccessControl.Step4Meat.path);
    }, 250);
  };

  const goBack = () => {
    setTimeout(() => {
      navigate(AccessControl.Step2Info.path);
    }, 250);
  };

  return (
    <div className="py-6 px-4 max-w-sm mx-auto">
      <Header goBack={goBack} step={3} />

      <section className="mt-16 desktop1600:mt-10 smx:mt-5">
        <h2 className="text-start font-semibold text-gray-800 dark:text-white font-Montserrat text-3xl desktop1600:text-2xl smx:text-[18px] smx:leading-normal">
          How much time do you have for meal preparation each day?
        </h2>

        <div
          role="tablist"
          className="mt-8 smx:mt-3 flex flex-col gap-2.5 sm:gap-3"
        >
          {data.map((item) => (
            <button
              type="button"
              role="tab"
              aria-selected={item.value === state?.time}
              onClick={() => handleForm(item.value)}
              key={item.value}
              className={clsx(
                item.value === state?.time
                  ? "border-teal-500 bg-teal-500 text-white"
                  : "bg-gray-100 text-gray-600 hover:border-teal-500 focus:border-teal-500 border-gray-100 hover:bg-teal-100 focus:bg-teal-100 hover:text-gray-600",
                "focus:border-2 border-2 transition-all outline-none",
                "w-full px-4 py-2 sm:py-2.5 smx:px-3",
                "text-left rounded-md font-Montserrat font-medium text-sm sm:text-base",
                "flex items-center justify-between group"
              )}
            >
              {item.title}

              <MdArrowForwardIos
                size={14}
                className={clsx(
                  item.value === state?.time
                    ? "translate-x-0.5"
                    : "group-hover:translate-x-0.5 transition-all"
                )}
              />
            </button>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Step3;
