import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import AppContainer from "./AppContainer";

export const Layout = () => {
  return (
    <AppContainer>
      <div className="wrapper">
        <Header />
        <Outlet />
      </div>
    </AppContainer>
  );
};
