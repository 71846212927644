import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { API_PATH, APP_SERVER, FUNNEL_TYPE, Loader } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { BodyText, Title } from "../../quiz10/Typography";
import {
  plan,
  planDatas,
  selectUserData,
  email,
  lbft,
  kgcm,
  reach_kgcm,
  reach_lbft,
  updatePaymentMethod,
} from "../../../redux/quizSlice/quizSlice";
import AppContainer from "../../../componant/private_components/AppContainer";
import Countdown from "react-countdown";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Ratings from "./Ratings";
import cardLogo from '../../../assets/images/visa-mc-credit-card-logos.png';
import paypalLogo from '../../../assets/images/paypal.png';
import Graph from "./graph";
import PaymentForm from "./PaymentForm";
import useGoal from "../../../hooks/useGoal";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import accessRoute from "../../../route-control/path/public-path";
import { CheckBadgeIcon, CheckIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import DeclineWarning from "./DeclineWarning";
import clsx from "clsx";
import BraintreeButton from "../../paypal/Braintree";

gsap.registerPlugin(ScrollToPlugin);

const AllPlan = [
  {
    id: 14,
    plan_name: "3 Payments of $29",
    planTerm: "1M-29",
    plan_price: 29,
    paymentTitle:
      "1 Payment Today. 1 Payment in 30 days, and 1 Payment in 60 Days.",
    currency: "USD",
  },

  {
    id: 15,
    plan_name: "Discount:",
    planTerm: "3M-67",
    plan_price: 67,
    paymentTitle: "1 Payment of $67. Pay in full today and save $20 instantly.",
    currency: "USD",
    discount: true,
    popular: true,
  },
];

const discountTimeDuration = 60 * 10 * 1000 - 1000; // 9:59

const units = {
  LbFt: "lb",
  KgCm: "kg",
};

const GetSelectedPlan = ({ plans, isFemale }) => {
  const sp = AllPlan.find((plan) => plan.id === plans);

  return (
    <p
      className={clsx(
        isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]",
        "text-[11px] sm:text-xs font-medium mb-4"
      )}
    >
      Custom 3-Month Plan: ${sp?.plan_price}{" "}
      {plans === 14 ? (
        "total today"
      ) : (
        <Fragment>
          (
          <span className="text-red-400 text-[11px] sm:text-xs font-medium">
            $20 Discount Applied
          </span>
          )
        </Fragment>
      )}
    </p>
  );
};

const ResultCheckout = ({ campaign }) => {
  const [params] = useSearchParams();
  const contact_id = params.get("contact_id");
  const session_id = params.get("session_id");
  const utm_source = params.get("utm_source");
  const utm_campaign = params.get("utm_campaign");
  const utm_medium = params.get("utm_medium");
  const utm_content = params.get("utm_content");

  const state = useSelector(selectUserData);
  const [localState, setLocalState] = useState("");
  const [error, setError] = useState(false);
  const [discountTime, setDiscountTime] = useState(null);

  const funnelType = FUNNEL_TYPE;
  // const { state, dispatch } = useContext(Context);
  const [plans, setPlan] = useState(state?.plan);
  const [localPlans, setLocalPlan] = useState(localState?.plan_id);
  const [termsMessage, setTermsMessage] = useState(AllPlan[1].terms);
  const [storeLoading, setStoreLoading] = useState(false);
  const dispatch = useDispatch();
  const goal = useGoal();
  const navigate = useNavigate();
  const plansToShow = AllPlan;
  const isFemale = state?.gender === "female";

  const weightUnit = units[state?.measurements.unitType];
  const currentWeight = state?.measurements.weight;
  const targetWeight = state?.measurements.targetWeight;
  const totalWeightLoss = currentWeight - targetWeight;

  const weightLossPerWeek = weightUnit === "lb" ? 2.5 : 2.5 / 2.204;
  const weightlossInADay = weightLossPerWeek / 7;
  const daysNeeded = totalWeightLoss / weightlossInADay;
  const currentDate = new Date();

  const targetDate = new Date(
    currentDate.getTime() + daysNeeded * 24 * 60 * 60 * 1000
  );

  const dateOfMonth = targetDate.toLocaleString("default", { day: "2-digit" });

  const targetMonthLong = targetDate.toLocaleString("default", {
    month: "long",
  });

  const targetMonthShort = targetDate.toLocaleString("default", {
    month: "short",
  });

  const targetYear = targetDate.getFullYear();
  const targetDateInFormat = `${targetMonthLong}/${dateOfMonth}/${targetYear}`;


  const paymentMethod = state.paymentMethod;
  const setPaymentMethod = (value) => dispatch(updatePaymentMethod(value));
  const offsetY = window.screen.width > 430 ? 145 : 210;

  const scrollToPayment = (duration) => {
    gsap.to(window, {
      duration: 0.75,
      scrollTo: { y: "#payment", offsetY: offsetY },
      ease: "power1.out",
    });
  };

  const fetchInformation = async (id) => {
    try {
      if (id) {
        let params = {};
        if (contact_id) {
          params = {
            contact_id: id,
          };
        } else {
          params = {
            session_id: id,
          };
        }

        const url = `${API_PATH}/funnel/get-information`;
        const response = await axios.get(url, { params });
        const sPref = response?.data?.data?.preferences;
        if (response?.data?.success) {
          setLocalState(sPref ? { ...sPref } : {});
          setLocalPlan(sPref?.plan_id);
          dispatch(email(sPref?.email));
          dispatch(
            planDatas({
              ...state.planData,
              session_id: sPref.session_id,
            })
          );

          if (sPref?.current_body_measure_unit === "LbFt") {
            dispatch(
              lbft({
                height: sPref?.current_body_height,
                weight: sPref?.current_body_weight,
                inch: sPref?.current_body_height_inch,
              })
            );
          } else if (sPref?.current_body_measure_unit === "KgCm") {
            dispatch(
              kgcm({
                height: sPref?.current_body_height,
                weight: sPref?.current_body_weight,
              })
            );
          }

          if (sPref?.ideal_body_measure_unit === "LbFt") {
            dispatch(reach_lbft({ weight: sPref?.ideal_body_weight }));
          } else if (sPref?.current_body_measure_unit === "KgCm") {
            dispatch(reach_kgcm({ weight: sPref?.ideal_body_weight }));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!discountTime) setDiscountTime(Date.now() + discountTimeDuration);
    if (contact_id || session_id) {
      fetchInformation(contact_id || session_id);
    }

    document.querySelector("html").style.scrollBehavior = "auto";
    return () =>
      (document.querySelector("html").style.scrollBehavior = "smooth");
  }, []);

  useEffect(() => {
    console.log(state);
    if (!state.email && !campaign) {
      return navigate(`/${funnelType}/submit-email`);
    }

    window.dataLayer.push({
      event: "begin_checkout",
      page: {
        url: window.location.href,
        title: window.location.pathname,
      },
      email: state.email,
    });

    let id = 15;
    setPlan(id);
  }, []);

  const getSP = () => AllPlan.find((plan) => plan.id === plans);

  const getSelectedPlan = () => {
    const sp = getSP();

    return (
      <p className="text-[11px] sm:text-xs font-medium text-gray-900 mb-4">
        Custom 3-Month Plan: ${sp?.plan_price}{" "}
        {plans === 14 ? (
          "total today"
        ) : (
          <>
            (
            <span className="text-red-400 text-[11px] sm:text-xs font-medium">
              $20 Discount Applied
            </span>
            )
          </>
        )}
      </p>
    );
  };

  const updatePlan = async (newPlanId) => {
    setStoreLoading(true);
    console.log(state, "state");
    try {
      let data = {};
      if (contact_id || session_id) {
        const obj1 = {
          plan_id: newPlanId,
          source: utm_content,
          utm_source: utm_source,
          utm_campaign: utm_campaign,
          utm_medium: utm_medium,
          utm_content: utm_content,
        };
        data = Object.assign({}, localState, obj1);
      } else {
        data = {
          consent: state?.lossWeight || "",
          weight_loss_goal: state?.goal || "",
          gender: state?.gender || "",
          gender_identify: state?.identifyGender || "",
          age: state?.age || "",
          current_body_measure_unit: state?.measurements?.unitType || "",
          current_body_weight: state?.measurements?.weight || "",
          current_body_height: state?.measurements?.height || "",
          current_body_height_inch: state?.measurements?.inch || "",
          ideal_body_measure_unit: state?.measurements?.unitType || "",
          ideal_body_weight: state?.measurements?.targetWeight || "",
          body_type: state?.bodyType || "",
          last_time_content: state?.lastTimeContent || "",
          imp_event: state?.eventComing || "",
          event_date: state?.eventDate || "",
          typical_spend: state?.typicalDay || "",
          back_issue: state?.backIssue || "",
          stomach_issue: state?.stomachDiscomfort || "",
          tend_to_do: state?.tendToDo || "",
          emotional_comfort: state?.ratingStatement2 || "",
          old_habit: state?.oldHabit || "",
          multitasking: state?.multitasking || "",
          clear_plate: state?.ratingStatement4 || "",
          workout: state?.activity || "", // to find
          alchohol: state?.consumeAlcohol || "",
          how_tired: state?.howFeeling || "", //to find
          sleep_info: state?.sleepInfo || "",
          water_info: state?.waterInfo || "",
          meal_prepare: state?.time || "", //to find
          kitchen_appliances: state?.kitchenAppliance || "",
          plan_id: newPlanId,
          discount: state?.discount || "",
          email: state?.email || "",

          vegetables: state?.vegetables ?? [],
          included_products: state?.ketoProductList ?? [],
          select_meat: state?.ketoMeatList ?? [],

          vegitables_ingredient_id: state?.vegetableSelectedIds || "",
          product_ingredient_id: state?.productSelectedIds || "",
          meat_ingredient_id: state?.meatSelectedIds || "",

          not_selected_vegitables: state?.notSelectedVegetableString || "",
          not_selected_product: state?.notSelectedProductString || "",
          not_selected_meat: state?.notSelectedMeatString || "",
          session_id: state?.planData?.session_id || "",
          target_date: targetDateInFormat || "",
          funnel_type: FUNNEL_TYPE,
          source: state.source || "",
        };
      }

      const res = await axios.post(`${API_PATH}/quiz/store-information`, data);
      if (res?.data?.success) {
        const planData = {
          ...res?.data?.data?.planData,
          session_id: res?.data?.data?.session_id,
        };
        // dispatch({ type: "planData", payload: planData });
        dispatch(planDatas(planData));
      }
    } catch (error) {
      // navigate("")
      console.log(error);
    }
    setStoreLoading(false);
  };

  const handlePlan = (val) => {
    if (contact_id || session_id) {
      setLocalPlan(val.id);
    } else {
      setPlan(val.id);
    }
    dispatch(plan(val));

    updatePlan(val.id);
  };

  const DiscountOver = () => (
    <div className="text-[12px] md:text-sm">Your discount Already Expired!</div>
  );
  const handleDiscountTimeout = () => {
    window.localStorage.setItem("discountChance", "false");
  };

  const timerRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <DiscountOver />;
    } else {
      // Render a countdown
      return (
        <div className="text-[12px] md:text-sm">
          🔥 YOUR DISCOUNT EXPIRES IN {minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </div>
      );
    }
  };

  const handlePaymentSubmit = (values) => {
    navigate(accessRoute.ProcessingPayment.path);
  };

  return (
    <AppContainer className={isFemale ? "bg-[#f8f4f4]" : "bg-[#12241f]"}>
      <div className="wrapper">
        <div className="flex justify-center">
          <img
            src="https://dev.myketoslim.com/images/keto-logo.png"
            alt="Keto Slim"
            className="w-[120px] my-3"
          />
        </div>

        <div className="text-center mt-4">
          <Title className="mb-2">You can reach your goal of</Title>
          <h2
            className={clsx(
              isFemale ? "text-[#135565]" : "text-[#ffffff]",
              "font-bold text-2xl sm:text-3xl"
            )}
          >
            <span className="text-teal-500 text-2xl sm:text-3xl">
              {targetWeight} {weightUnit} by
            </span>{" "}
            {targetMonthLong} {dateOfMonth}
          </h2>
        </div>
      </div>

      <div className="wrapper px-4 overflow-hidden">
        <div className="max-w-[387px] h-[250px] flex items-center justify-center mx-auto">
          <Graph targetMonthShort={targetMonthShort} />
        </div>
      </div>

      {/* Choose your plan */}

      <div className="wrapper ">
        <Title className="mb-2 text-center">
          Your Personal Keto Diet is Ready!
        </Title>
        <h2
          className={clsx(
            isFemale ? "text-[#135565]" : "text-[#ffffff]",
            "md:text-3xl text-[22px] font-bold mb-2 md:mb-3 smx:pb-[0px] text-center"
          )}
        >
          3-Month Custom Keto Plan
        </h2>

        <div className="wrapper text-[#ff6458] font-semibold text-center mb-4 lg:mb-6">
          {discountTime && (
            <Countdown
              onComplete={handleDiscountTimeout}
              date={discountTime}
              renderer={timerRenderer}
            />
          )}
        </div>

        <div className="wrapper px-0 font-Montserrat rounded-3xl mb-10 mdx:mb-0">
          <p
            className={clsx(
              isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]",
              "text-xs m-2 font-semibold"
            )}
          >
            Select an option and Continue:
          </p>
          {
            <div className="border-2 rounded-lg border-teal-600 w-full overflow-hidden">
              {plansToShow?.map(
                ({
                  id,
                  plan_price,
                  plan_name,
                  duration,
                  discount,
                  popular,
                  offerMonth,
                  terms,
                  perdayDiscount,
                  paymentTitle,
                }) => {
                  const isActive =
                    (contact_id || session_id ? localPlans : state.plan) === id;
                  let colorClass = isFemale
                    ? "text-[#3f6777]"
                    : "text-[#d8d8d8]";

                  return (
                    <div
                      className={`w-full flex justify-center items-center h-[100px] ${
                        discount
                          ? `border-t-2 border-teal-400 ${
                              isFemale ? "bg-[#FFF3F2]" : "bg-[#236d5a]"
                            }`
                          : null
                      }`}
                      key={id}
                      onClick={() => setTermsMessage(terms)}
                    >
                      <label
                        onClick={(e) => {
                          e.preventDefault();
                          handlePlan({
                            id: id,
                            discount: discount,
                          });
                        }}
                        htmlFor={`plan${id}`}
                        className={`flex w-full items-center transition rounded-lg cursor-pointer desktop:p-8 mobile:p-3`}
                      >
                        {isActive ? (
                          <input
                            id={`plan${id}`}
                            name="plan"
                            type={"radio"}
                            className="scale-150 accent-teal-600 mr-3"
                            checked
                          />
                        ) : (
                          <input
                            id={`plan${id}`}
                            name="plan"
                            type={"radio"}
                            className="scale-150 text-gray-400 accent-teal-600 mr-3"
                          />
                        )}
                        <div className="float-left flex-1">
                          <h5
                            className={`font-semibold ${
                              discount && "text-red-500"
                            } text-[16px] uppercase ${colorClass}`}
                          >
                            {plan_name}
                          </h5>

                          <p
                            className={clsx(
                              isFemale ? "text-[#3f6777]" : "text-[#d8d8d8]",
                              "font-semibold text-[12px] mt-2"
                            )}
                          >
                            {paymentTitle}
                          </p>
                        </div>
                      </label>
                    </div>
                  );
                }
              )}{" "}
            </div>
          }
          <div className="flex justify-center mt-6 w-full">
            <button
              onClick={() => scrollToPayment(2)}
              className={clsx(
                "w-full rounded-lg p-2 h-[54px] md:h-[60px] font-semibold font-Montserrat text-sm md:text-base",
                "relative flex items-center gap-3 text-center justify-center outline-none",
                "group active:scale-[0.99] transition-all",
                isFemale
                  ? "text-white bg-teal-500 hover:bg-teal-600 sm:focus:bg-teal-600"
                  : "text-[#12241f] bg-[#d8d8d8] hover:bg-[#ffffff] sm:focus:bg-[#ffffff]"
              )}
              type="button"
            >
              {storeLoading && Loader}
              GET MY PLAN
            </button>
          </div>

          {/* terms message */}
          <h4 className="text-center text-gray-400 text-[12px] md:px-4">
            {termsMessage}
          </h4>
        </div>
      </div>

      <div className="wrapper">
        <Ratings scrollToPayment={scrollToPayment} isFemale={isFemale} />
      </div>

      <div id="payment" className="wrapper pt-3 mt-7">
        {Boolean(error) && <DeclineWarning error={error} />}
        <h3
          className={clsx(
            isFemale ? "text-[#135565]" : "text-[#ffffff]",
            "mb-1 font-semibold"
          )}
        >
          Payment method
        </h3>

        {/* <p className="text-[11.5px] font-medium text-gray-400 mb-3.5">
          KetoSlim will use your payment details for seamless future payments.
        </p> */}

        <GetSelectedPlan plans={plans} isFemale={isFemale} />

        <div className="border-[2px] border-gray-300 rounded-lg shadow-md">
          <div className="border-b-2 px-4">
            <label htmlFor="paypalMethod" className="flex items-center py-4">
              <input
                onChange={(e) => setPaymentMethod(e.target.value)}
                value="paypal"
                checked={paymentMethod === "paypal"}
                id="paypalMethod"
                type="radio"
                name="paymentMethod"
                className="scale-150 accent-teal-600 mr-3"
              />
              <p className={clsx(isFemale? "text-[#3f6777]" : "text-[#d8d8d8]", 'font-semibold')}>Paypal</p>
              <img src={paypalLogo} alt="card logo" className="w-20 smx:w-12 ml-auto" />
            </label>

            <div className={`w-full mb-5 ${paymentMethod === "paypal" ? "visible" : 'hidden'}`}>
              <ul className="flex flex-col gap-2">
                <li className="flex">
                  <CheckIcon className="w-4 mr-2 text-teal-600" />
                  <span className={clsx(isFemale? "text-[#3f6777]" : "text-[#d8d8d8]", 'text-xs')}>Fast, convenient payment option</span>
                </li>
                <li className="flex">
                  <CheckIcon className="w-4 mr-2 text-teal-600" />
                  <span className={clsx(isFemale? "text-[#3f6777]" : "text-[#d8d8d8]", 'text-xs')}>Keeps your financial info safe with end-to-end encryption</span>
                </li>
                <li className="flex">
                  <CheckIcon className="w-4 mr-2 text-teal-600" />
                  <span className={clsx(isFemale? "text-[#3f6777]" : "text-[#d8d8d8]", 'text-xs')}>Backed by PayPal's industry-leading fraud protection</span>
                </li>
              </ul>

              <div className="my-4">
                <BraintreeButton />
              </div>

              <div>
                <p className={clsx(isFemale? "text-[#3f6777]" : "text-[#d8d8d8]", "text-center font-semibold text-xs")}>
                  Your payment information is secure with SSL/TLS encryption
                </p>
                <div className="flex justify-center items-center gap-6 mt-2">
                  <img className="h-[38px]" src="../result-checkout/SSL.png" alt="ssl" />
                </div>
              </div>
            </div>
          </div>

          <div className="px-4">
            <label htmlFor="CCMethod" className="flex items-center py-4">
              <input
                onChange={(e) => setPaymentMethod(e.target.value)}
                value="CC"
                checked={paymentMethod === "CC"}
                id="CCMethod"
                type="radio"
                name="paymentMethod"
                className="scale-150 accent-teal-600 mr-3"
              />
              <p className={clsx(isFemale? "text-[#3f6777]" : "text-[#d8d8d8]", 'font-semibold')}>Credit Card</p>
              <img src={cardLogo} alt="card logo" className="w-20 smx:w-12 ml-auto" />
            </label>

            <div className={`w-full mb-5 ${paymentMethod === "CC" ? "visible" : 'hidden'}`}>
              <PaymentForm handlePaymentSubmit={handlePaymentSubmit} setError={setError} isFemale={isFemale} />
              <div>
                <p className={clsx(isFemale? "text-[#3f6777]" : "text-[#d8d8d8]", "text-center font-semibold text-xs")}>
                  Your payment information is secure with SSL/TLS encryption
                </p>
                <div className="flex justify-center items-center gap-6 mt-2">
                  <img className="h-[38px]" src="../result-checkout/SSL.png" alt="ssl" />
                  <img className="h-[35px]" src="../result-checkout/visa.png" alt="visa" />
                  <img className="h-[44px]" src="../result-checkout/master.png" alt="mastercard" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="my-6">
          <Title className="mb-1">Secure checkout</Title>
          <BodyText className="text-sm">
            All information is encrypted and transmitted using Secure Sockets
            Layer protocol.
          </BodyText>
        </div>

        <BodyText className="text-sm my-2">
          By continuing, you agree with&nbsp;
          <Link
            to={`/terms&condition`}
            rel="noreferrer"
            className="text-sm text-teal-700"
            target="_blank"
          >
            Terms of Use
          </Link>
          &nbsp;and&nbsp;
          <Link
            to={`/privacy-policy`}
            rel="noreferrer"
            className="text-sm text-teal-700"
            target="_blank"
          >
            Privacy Policy
          </Link>
          .
        </BodyText>

        <div className="my-6">
          <Title className="mb-1">Need help?</Title>
          <BodyText className="text-sm">
            Contact us{" "}
            <Link className="text-sm text-teal-700" to={"/contact-us"}>
              here
            </Link>
            .
          </BodyText>
        </div>
      </div>
    </AppContainer>
  );
};

export default ResultCheckout;
