import { createSlice } from "@reduxjs/toolkit";

export const quizSlice = createSlice({
  name: "quizNew",
  initialState: {
    meatSelectedIds: "",
    energyStatus: "",
    vegetableSelectedIds: "",
    currentHealthInfo: "",
    fruitVegetableIntake: "",
    hungerLevels: "",
    snackTime: "",
    onMedication: "",
    weightMotivation: [],
    weightMotivation2: [],
    ketoMotivation: [],
    ketoMotivation2: [],
    uncertainity: [],
    previousPrograms: [],

    ratingStatement1: 0,
    ratingStatement2: 0,
    ratingStatement3: 0,
    ratingStatement4: 0,
    ratingStatement5: 0,

    weightLossMotivation: "",
    driveToLoseWeight: "",
    weightLossPace: "",
    eatingDisorder: "",
    childrenInfo: "",
    step: 0,
    time: "",
    familiar: "",
    activity: "",
    willingness: "",
    actualBodyType: "",
    apiMeatList: [],
    apiVegetableList: [],
    apiProductList: [],
    ketoMeatList: [],
    ketoProductList: [],
    notSelectedMeatString: "",
    notSelectedVegetableString: "",
    notSelectedProductString: "",
    selectedMeatList: [],
    selectedVegetableList: [],
    selectedProductList: [],
    medicalList: [],
    goals: "",
    targetZones: [],
    skinIssues: [],
    targetBodyType: "",
    source: "",
    lossWeight: null,
    goal: "",
    identifyGender: "",
    gender: "",
    age: "",
    measurements: {
      unitType: "LbFt",
      weight: "",
      height: "",
      inch: "",
      targetWeight: "",
    },
    targetTime: {
      month: "",
      year: "",
    },
    reachWeight: {
      unitType: "LbFt",
      weight: 0,
    },
    bodyType: "",
    productSelectedIds: "",
    lastTimeContent: "",
    eventComing: "",
    harmonalIssue: "",
    eventDate: "",
    typicalDay: "",
    backIssue: "",
    stomachDiscomfort: "",
    tendToDo: [],
    emotionalComfort: null,
    oldHabit: null,
    multitasking: null,
    clearPlate: null,
    workout: "",
    consumeAlcohol: "",
    howFeeling: "",
    sleepInfo: "",
    sleepRoutine: "",
    waterInfo: "",
    mealPrepareTime: "",
    kitchenAppliance: [],
    FeelOfTarget: "",
    vegetable: [],
    products: [],
    meat: [],
    vegetableId: [],
    productsId: [],
    meatId: [],
    notSelectedVegetableId: [],
    notSelectedProductsId: [],
    notSelectedMeatId: [],
    plan: 15,
    discount: true,
    // Keto Initial State
    dietType: "",
    preparingMeal: "",
    motivated: "",
    physicalActivity: "",
    ingredients: [],
    vegetables: [],
    conditions: [],
    meats: [],
    // ketoboos+
    ketoboost: {},
    coaching: {},
    email: "",
    vegetableList: [],
    productList: [],
    meatList: [],
    planData: {},
    knowKeto: "",
    kindOfMeal: "",
    howActive: "",
    weightLossTarget: "",
    medicalConditions: [],
    previousMethods: [],
    unitNew: "LbFt",
    weight: 0,
    height: 0,
    inch: 0,
    targetWeight: 0,
    name: "",
    pregnant: "",
    target_date: "",
    cardData: null,
    numberOfMeals: 2,
    carbFoodFrequency: "",
    habits: [],
    dietaryPattern: [],
    improveSleepList: [],
    lifeStyle: "",
    motivationToReachTarget: "",
    snackingReason: "",
    paymentMethod: 'paypal'
  },
  reducers: {
    StartStep: (state, action) => {
      return (state = {
        ...state,
        lossWeight: action.payload.startStep,
        gender: action.payload.gender,
        source: action.payload.source,
      });
    },

    snackTime: (state, action) => {
      return (state = { ...state, snackTime: action.payload });
    },
    fruitVegetableIntake: (state, action) => {
      return (state = { ...state, fruitVegetableIntake: action.payload });
    },
    onMedication: (state, action) => {
      return (state = { ...state, onMedication: action.payload });
    },
    pregnant: (state, action) => {
      return (state = { ...state, pregnant: action.payload });
    },
    ketoMotivation: (state, action) => {
      return (state = { ...state, ketoMotivation: action.payload });
    },
    ketoMotivation2: (state, action) => {
      return (state = { ...state, ketoMotivation2: action.payload });
    },

    previousPrograms: (state, action) => {
      return (state = { ...state, previousPrograms: action.payload });
    },
    weightMotivation: (state, action) => {
      return (state = { ...state, weightMotivation: action.payload });
    },
    weightMotivation2: (state, action) => {
      return (state = { ...state, weightMotivation2: action.payload });
    },
    setWeightLossPace: (state, action) => {
      return (state = { ...state, weightLossPace: action.payload });
    },
    snackingReason: (state, action) => {
      return (state = { ...state, snackingReason: action.payload });
    },
    uncertainity: (state, action) => {
      return (state = { ...state, uncertainity: action.payload });
    },
    weightLossMotivation: (state, action) => {
      return (state = { ...state, weightLossMotivation: action.payload });
    },
    motivationToReachTarget: (state, action) => {
      return (state = { ...state, motivationToReachTarget: action.payload });
    },
    setSleepRoutine: (state, action) => {
      return (state = { ...state, sleepRoutine: action.payload });
    },
    hungerLevels: (state, action) => {
      return (state = { ...state, hungerLevels: action.payload });
    },
    setGoals: (state, action) => {
      return (state = { ...state, goals: action.payload });
    },
    energyStatus: (state, action) => {
      return (state = { ...state, energyStatus: action.payload });
    },
    setStep: (state, action) => {
      return (state = { ...state, step: action.payload });
    },
    previousMethods: (state, action) => {
      return (state = { ...state, previousMethods: action.payload });
    },
    familiarityStep: (state, action) => {
      return (state = {
        ...state,
        familiar: action.payload,
      });
    },
    timeStep: (state, action) => {
      return (state = { ...state, time: action.payload });
    },
    activityStep: (state, action) => {
      return (state = { ...state, activity: action.payload });
    },
    willingnessStep: (state, action) => {
      return (state = { ...state, willingness: action.payload });
    },
    meatStep: (state, action) => {
      return (state = { ...state, ketoMeatList: action.payload });
    },
    meatListSelected: (state, action) => {
      return (state = { ...state, selectedMeatList: action.payload });
    },
    productListSelected: (state, action) => {
      return (state = { ...state, selectedProductList: action.payload });
    },

    vegetableSelectedIds: (state, action) => {
      return (state = { ...state, vegetableSelectedIds: action.payload });
    },
    meatSelectedIds: (state, action) => {
      return (state = { ...state, meatSelectedIds: action.payload });
    },
    productSelectedIds: (state, action) => {
      return (state = { ...state, productSelectedIds: action.payload });
    },
    notSelectedMeatString: (state, action) => {
      return (state = { ...state, notSelectedMeatString: action.payload });
    },
    notSelectedVegetableString: (state, action) => {
      return (state = { ...state, notSelectedVegetableString: action.payload });
    },
    notSelectedProductString: (state, action) => {
      return (state = { ...state, notSelectedProductString: action.payload });
    },
    productStep: (state, action) => {
      return (state = { ...state, ketoProductList: action.payload });
    },
    FeelOfTarget: (state, action) => {
      return (state = { ...state, FeelOfTarget: action.payload });
    },

    SaveMeatList: (state, action) => {
      return (state = { ...state, apiMeatList: action.payload });
    },
    SaveVegetableList: (state, action) => {
      return (state = { ...state, apiVegetableList: action.payload });
    },

    SaveProductList: (state, action) => {
      return (state = { ...state, apiProductList: action.payload });
    },

    medicalStep: (state, action) => {
      return (state = { ...state, medicalList: action.payload });
    },

    StepGoal: (state, action) => {
      return (state = { ...state, goal: action.payload });
    },

    ratingStatement1: (state, action) => {
      return (state = { ...state, ratingStatement1: action.payload });
    },
    ratingStatement2: (state, action) => {
      return (state = { ...state, ratingStatement2: action.payload });
    },
    ratingStatement3: (state, action) => {
      return (state = { ...state, ratingStatement3: action.payload });
    },
    ratingStatement4: (state, action) => {
      return (state = { ...state, ratingStatement4: action.payload });
    },
    ratingStatement5: (state, action) => {
      return (state = { ...state, ratingStatement5: action.payload });
    },

    StepIdentify: (state, action) => {
      return (state = { ...state, identifyGender: action.payload });
    },

    StepGender: (state, action) => {
      return (state = { ...state, gender: action.payload });
    },

    StepAge: (state, action) => {
      return (state = { ...state, age: action.payload });
    },

    unit: (state, action) => {
      return (state = {
        ...state,
        measurements: {
          ...state.measurements,
          unitType: action.payload,
        },
      });
    },

    lbft: (state, action) => {
      return (state = {
        ...state,
        measurements: {
          ...state.measurements,
          age: action.payload.age,
          weight: action.payload.weight,
          height: action.payload.height,
          inch: action.payload.inch,
          targetWeight: action.payload.targetWeight,
        },
      });
    },

    kgcm: (state, action) => {
      return (state = {
        ...state,
        measurements: {
          ...state.measurements,
          age: action.payload.age,
          weight: action.payload.weight,
          height: action.payload.height,
          targetWeight: action.payload.targetWeight,
        },
      });
    },

    reachUnit: (state, action) => {
      return (state = { ...state, reachWeight: { unitType: action.payload } });
    },

    reach_kgcm: (state, action) => {
      return (state = {
        ...state,
        reachWeight: { ...state.reachWeight, weight: action.payload.weight },
      });
    },

    reach_lbft: (state, action) => {
      return (state = {
        ...state,
        reachWeight: { ...state.reachWeight, weight: action.payload.weight },
      });
    },

    setBodytype: (state, action) => {
      return (state = { ...state, actualBodyType: action.payload });
    },
    bodytype: (state, action) => {
      return (state = { ...state, bodyType: action.payload });
    },
    setTargetBodyType: (state, action) => {
      return (state = { ...state, targetBodyType: action.payload });
    },

    setTargetZones: (state, action) => {
      return (state = { ...state, targetZones: action.payload });
    },

    StepLastTimeContent: (state, action) => {
      return (state = { ...state, lastTimeContent: action.payload });
    },

    eventComing: (state, action) => {
      return (state = { ...state, eventComing: action.payload });
    },

    eventDate: (state, action) => {
      return (state = { ...state, eventDate: action.payload });
    },

    typicalDay: (state, action) => {
      return (state = { ...state, typicalDay: action.payload });
    },
    currentHealthInfo: (state, action) => {
      return (state = { ...state, currentHealthInfo: action.payload });
    },

    backIssue: (state, action) => {
      return (state = { ...state, backIssue: action.payload });
    },

    stomachDiscomfort: (state, action) => {
      return (state = { ...state, stomachDiscomfort: action.payload });
    },

    tendToDo: (state, action) => {
      return (state = { ...state, tendToDo: action.payload });
    },

    emotionalComfort: (state, action) => {
      return (state = { ...state, emotionalComfort: action.payload });
    },

    oldHabit: (state, action) => {
      return (state = { ...state, oldHabit: action.payload });
    },

    multitasking: (state, action) => {
      return (state = { ...state, multitasking: action.payload });
    },

    clearPlate: (state, action) => {
      return (state = { ...state, clearPlate: action.payload });
    },

    workout: (state, action) => {
      return (state = { ...state, workout: action.payload });
    },

    consumeAlcohol: (state, action) => {
      return (state = { ...state, consumeAlcohol: action.payload });
    },

    howFeeling: (state, action) => {
      return (state = { ...state, howFeeling: action.payload });
    },

    sleepInfo: (state, action) => {
      return (state = { ...state, sleepInfo: action.payload });
    },

    waterInfo: (state, action) => {
      return (state = { ...state, waterInfo: action.payload });
    },
    driveToLoseWeight: (state, action) => {
      return (state = { ...state, driveToLoseWeight: action.payload });
    },

    mealPrepareTime: (state, action) => {
      return (state = { ...state, mealPrepareTime: action.payload });
    },

    kitchenAppliance: (state, action) => {
      return (state = { ...state, kitchenAppliance: action.payload });
    },

    vegetable: (state, action) => {
      return (state = {
        ...state,
        vegetable: action.payload.vegetable,
        vegetableId: action.payload.vegetableId,
        notSelectedVegetableId: action.payload.notSelectedVegetableId,
      });
    },

    products: (state, action) => {
      return (state = {
        ...state,
        products: action.payload.products,
        productsId: action.payload.productsId,
        notSelectedProductsId: action.payload.notSelectedProductsId,
      });
    },

    meat: (state, action) => {
      return (state = {
        ...state,
        meat: action.payload.meat,
        meatId: action.payload.meatId,
        notSelectedMeatId: action.payload.notSelectedMeatId,
      });
    },

    plan: (state, action) => {
      return (state = {
        ...state,
        plan: action.payload.id,
        discount: action.payload.discount,
      });
    },

    planDatas: (state, action) => {
      return (state = { ...state, planData: {...action.payload} });
    },
    // Keto Dispatch
    dietType: (state, action) => {
      return (state = {
        ...state,
        dietType: action.payload.startStep,
        source: action.payload.source,
      });
    },

    familiar: (state, action) => {
      return (state = { ...state, familiar: action.payload });
    },
    skinIssues: (state, action) => {
      return (state = { ...state, skinIssues: action.payload });
    },

    preparingMeal: (state, action) => {
      return (state = { ...state, preparingMeal: action.payload });
    },

    meats: (state, action) => {
      return (state = { ...state, meats: action.payload });
    },

    ingredients: (state, action) => {
      return (state = { ...state, ingredients: action.payload });
    },

    vegetables: (state, action) => {
      return (state = { ...state, vegetables: action.payload });
    },

    physicalActivity: (state, action) => {
      return (state = { ...state, physicalActivity: action.payload });
    },

    motivated: (state, action) => {
      return (state = { ...state, motivated: action.payload });
    },
    eatingDisorder: (state, action) => {
      return (state = { ...state, eatingDisorder: action.payload });
    },

    conditions: (state, action) => {
      return (state = { ...state, conditions: action.payload });
    },

    age: (state, action) => {
      return (state = { ...state, age: action.payload });
    },

    ketoboost: (state, action) => {
      return (state = { ...state, ketoboost: action.payload });
    },
    coaching: (state, action) => {
      return (state = { ...state, coaching: action.payload });
    },
    variants: (state, action) => {
      return (state = { ...state, variant: action.payload });
    },
    email: (state, action) => {
      return (state = { ...state, email: action.payload });
    },

    ingredientList: (state, action) => {
      return (state = {
        ...state,
        vegetableList: action.payload.vegetableArr,
        productList: action.payload.productArr,
        meatList: action.payload.meatArr,
      });
    },

    KnowKetos: (state, action) => {
      return (state = {
        ...state,
        knowKeto: action.payload,
      });
    },

    KindOfMeal: (state, action) => {
      return (state = {
        ...state,
        kindOfMeal: action.payload,
      });
    },

    HowActive: (state, action) => {
      return (state = {
        ...state,
        howActive: action.payload,
      });
    },

    WeightLossTargets: (state, action) => {
      return (state = {
        ...state,
        weightLossTarget: action.payload,
      });
    },

    MedicalConditions: (state, action) => {
      return (state = {
        ...state,
        medicalConditions: action.payload,
      });
    },

    targetGoalTime: (state, action) => {
      return (state = {
        ...state,
        targetTime: {
          month: action.payload.month,
          year: action.payload.year,
        },
      });
    },

    UnitUpdate: (state, action) => {
      return (state = {
        ...state,
        unitNew: action.payload,
        weight: "",
        height: "",
        inch: "",
        targetWeight: "",
        age: "",
      });
    },

    MeasurementUpdate: (state, action) => {
      return (state = {
        ...state,
        weight: action.payload.weight,
        height: action.payload.height,
        inch: action.payload.inch,
        targetWeight: action.payload.targetWeight,
        age: action.payload.age,
      });
    },

    name: (state, action) => {
      return (state = { ...state, name: action.payload });
    },
    updateCardData: (state, action) => {
      const newCard = action.payload;
      return (state = { ...state, cardData: { ...newCard, failed: 0 } });
    },
    updatePaymentMethod: (state, action) => {
      return (state = { ...state, paymentMethod: action.payload });
    },
    cardFailed: (state, action) => {
      const { cardData } = state;
      return (state = {
        ...state,
        cardData: { ...cardData, failed: cardData.failed + 1 },
      });
    },
    target_date: (state, action) => {
      return (state = { ...state, target_date: action.payload });
    },
    funnel_Types: (state, action) => {
      return (state = { ...state, funnel_Types: action.payload });
    },
    setNumberOfMeals: (state, action) => {
      return (state = { ...state, numberOfMeals: action.payload });
    },
    harmonalIssue: (state, action) => {
      return (state = { ...state, harmonalIssue: action.payload });
    },
    setCarbFoodFrequency: (state, action) => {
      return (state = { ...state, carbFoodFrequency: action.payload });
    },
    setHabits: (state, action) => {
      return (state = { ...state, habits: action.payload });
    },
    setDietaryPattern: (state, action) => {
      return (state = { ...state, dietaryPattern: action.payload });
    },
    setImproveSleep: (state, action) => {
      return (state = { ...state, improveSleepList: action.payload });
    },
    setLifeStyle: (state, action) => {
      return (state = { ...state, lifeStyle: action.payload });
    },
    setChildrenInfo: (state, action) => {
      return (state = { ...state, childrenInfo: action.payload });
    },
    logoutAction: (state) => {
      return (state = {
        ...state,
        snackingReason: "",
        weightLossPace: "",
        childrenInfo: "",
        notSelectedMeatString: "",
        notSelectedVegetableString: "",
        notSelectedProductString: "",
        ketoMeatList: [],
        ketoProductList: [],
        apiMeatList: [],
        apiVegetableList: [],
        apiProductList: [],
        weightMotivation2: [],
        selectedMeatList: [],
        selectedVegetableList: [],
        selectedProductList: [],
        skinIssues: [],
        medicalList: [],
        willingness: "",
        familiar: "",
        source: "",
        vegetableSelectedIds: "",
        onMedication: "",
        productSelectedIds: "",
        activity: "",
        cardData: null,
        FeelOfTarget: "",
        paymentMethod: 'paypal',
        lossWeight: null,
        goal: "",
        identifyGender: "",
        gender: "",
        age: "",
        targetTime: {
          month: "",
          year: "",
        },
        measurements: {
          unitType: "LbFt",
          weight: "",
          height: "",
          inch: "",
          targetWeight: "",
        },
        reachWeight: {
          unitType: "LbFt",
          weight: 0,
        },
        bodyType: "",
        previousMethods: [],
        actualBodyType: "",
        driveToLoseWeight: "",
        targetZones: [],
        targetBodyType: "",
        lastTimeContent: "",
        eatingDisorder: "",
        eventComing: "",
        eventDate: "",
        typicalDay: "",
        backIssue: "",
        stomachDiscomfort: "",
        tendToDo: [],
        emotionalComfort: null,
        oldHabit: null,
        multitasking: null,
        clearPlate: null,
        workout: "",
        consumeAlcohol: "",
        howFeeling: "",
        sleepInfo: "",
        sleepRoutine: "",
        waterInfo: "",
        hungerLevels: "",
        mealPrepareTime: "",
        kitchenAppliance: [],
        vegetable: [],
        products: [],
        meat: [],
        vegetableId: [],
        productsId: [],
        meatId: [],
        goals: "",
        notSelectedVegetableId: [],
        notSelectedProductsId: [],
        notSelectedMeatId: [],
        plan: 15,
        discount: true,
        // Keto Initial State
        dietType: "",
        preparingMeal: "",
        motivated: "",
        physicalActivity: "",
        ingredients: [],
        vegetables: [],
        conditions: [],
        currentHealthInfo: "",
        meats: [],
        // ketoboos+
        ketoboost: {},
        coaching: {},
        email: "",
        vegetableList: [],
        productList: [],
        meatList: [],
        planDatas: {},
        knowKetos: "",
        pregnant: "",
        kindOfMeal: "",
        howActive: "",
        weightLossTarget: "",
        medicalConditions: [],
        unitNew: "LbFt",
        weight: 0,
        height: 0,
        inch: 0,
        targetWeight: 0,
        name: "",
        target_date: "",
        funnel_Types: "",
        numberOfMeals: 2,
        carbFoodFrequency: "",
        previousPrograms: [],
        habits: [],
        dietaryPattern: [],
        improveSleepList: [],
        lifeStyle: "",
        weightLossMotivation: "",
        motivationToReachTarget: "",
        uncertainity: [],
        snackTime: "",
        harmonalIssue: "",
        weightMotivation: [],
        ketoMotivation: [],
        ketoMotivation2: [],
        ratingStatement1: 0,
        ratingStatement2: 0,
        ratingStatement3: 0,
        ratingStatement4: 0,
        ratingStatement5: 0,
        energyStatus: "",
        fruitVegetableIntake: "",
        meatSelectedIds: "",
      });
    },
  },
});
export const {
  productSelectedIds,
  meatSelectedIds,
  energyStatus,
  fruitVegetableIntake,
  currentHealthInfo,
  ratingStatement1,
  ratingStatement2,
  ratingStatement3,
  ratingStatement4,
  ratingStatement5,
  hungerLevels,
  ketoMotivation,
  ketoMotivation2,
  eatingDisorder,
  pregnant,
  harmonalIssue,
  weightMotivation,
  weightMotivation2,
  driveToLoseWeight,
  onMedication,
  snackTime,
  skinIssues,
  snackingReason,
  uncertainity,
  motivationToReachTarget,
  weightLossMotivation,
  FeelOfTarget,
  setSleepRoutine,
  setChildrenInfo,
  setLifeStyle,
  setImproveSleep,
  setDietaryPattern,
  setNumberOfMeals,
  setCarbFoodFrequency,
  previousMethods,
  previousPrograms,
  setHabits,
  setStep,
  timeStep,
  familiarityStep,
  willingnessStep,
  medicalStep,
  meatStep,
  notSelectedProductString,
  notSelectedMeatString,
  notSelectedVegetableString,
  SaveMeatList,
  SaveProductList,
  SaveVegetableList,
  meatListSelected,
  vegetableSelectedIds,
  productListSelected,
  productStep,
  activityStep,
  logoutAction,
  updateUserDataAction,
  StartStep,
  StepGoal,
  StepIdentify,
  setWeightLossPace,
  setGoals,
  StepGender,
  StepAge,
  targetGoalTime,
  unit,
  lbft,
  kgcm,
  reachUnit,
  reach_kgcm,
  reach_lbft,
  setBodytype,
  setTargetBodyType,
  setTargetZones,
  StepLastTimeContent,
  eventComing,
  eventDate,
  typicalDay,
  bodytype,
  backIssue,
  stomachDiscomfort,
  tendToDo,
  emotionalComfort,
  oldHabit,
  multitasking,
  clearPlate,
  workout,
  consumeAlcohol,
  howFeeling,
  sleepInfo,
  waterInfo,
  mealPrepareTime,
  kitchenAppliance,
  vegetable,
  products,
  meat,
  plan,
  planDatas,
  dietType,
  familiar,
  preparingMeal,
  meats,
  ingredients,
  vegetables,
  physicalActivity,
  motivated,
  conditions,
  age,
  ketoboost,
  coaching,
  variants,
  email,
  ingredientList,
  KnowKetos,
  KindOfMeal,
  HowActive,
  WeightLossTargets,
  MedicalConditions,
  UnitUpdate,
  MeasurementUpdate,
  name,
  target_date,
  funnel_Types,
  updateCardData,
  cardFailed,
  updatePaymentMethod
} = quizSlice.actions;

export const selectUserData = (state) => state.quizNew;

export default quizSlice.reducer;
