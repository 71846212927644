import Footer from "./Footer";

const AppContainer = ({className, children, showFooter=false}) => {
  return (
    <>
      <div className={`app-container ${className || ''}`}>
        {children}
      </div>
      {showFooter ? <Footer /> : null}
    </>
  );
}

export default AppContainer;